import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import { Cascader, Tooltip, Tour } from "antd";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  UserOutlined,
  AirplaneOutlined,
} from "@ant-design/icons";
import { FaHourglassEnd, FaQuestionCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
} from "../funciones_utiles";
import {
  validarFecha,
  validarSelector,
  validarSelectorPais,
  validarSelectorAeropuerto,
} from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { MapaVuelos } from "./mapaVuelos";
const MySwal = withReactContent(Swal);

const URI_VUELOS =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/tipos_vuelos";
const URI_AEROPUERTOS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/aeropuertos";
const URI_PAISES_ISO =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/paises_iso";
const URI_MOTIVOS_VIAJES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/motivos_viajes";

const MemoizedMap = React.memo(MapaVuelos);
export const CompCalculadoraVuelos = ({ sesion, titulo }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesAeropuertos, setOpcionesAeropuertos] = useState([]);
  const [opcionesVuelos, setOpcionesVuelos] = useState([]);
  const [opcionesMotivos, setOpcionesMotivos] = useState([]);
  const [aeropuertoOrigen, setAeropuertoOrigen] = useState({});
  const [aeropuertoDestino, setAeropuertoDestino] = useState({});
  const [kmRecorridos, setKmRecorridos] = useState({});
  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculo, setTempDatosCalculo] = useState({});
  const [tempDatosCalculoDos, setTempDatosCalculoDos] = useState({});
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const URI_CONSUMOS_VUELOS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=vuelos&id=" +
    idEmpresa +
    "&ids=" +
    idSede;

  const refMotivo = useRef(null);
  const refFecha = useRef(null);
  const refClase = useRef(null);
  const refPasajeros = useRef(null);
  const refOrigen = useRef(null);
  const refDestino = useRef(null);
  const refBtnGuardar = useRef(null);
  const refGrafico = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);

  const propsNext = {
    children: (
      <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsFin = {
    children: (
      <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsPrev = {
    children: <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />,
    style: {
      height: 31,
    },
  };

  const pasos = [
    {
      title: t("calculadoras.pasos.titulo11"),
      description: t("calculadoras.pasos.subtitulo11"),
      target: () => refFecha.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo7"),
      description: t("calculadoras.pasos.subtitulo7"),
      target: () => refMotivo.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo23"),
      description: t("calculadoras.pasos.subtitulo23"),
      target: () => refClase.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo22"),
      description: t("calculadoras.pasos.subtitulo22"),
      target: () => refPasajeros.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo24"),
      description: t("calculadoras.pasos.subtitulo24"),
      target: () => refOrigen.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo25"),
      description: t("calculadoras.pasos.subtitulo25"),
      target: () => refDestino.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo5"),
      description: t("calculadoras.pasos.subtitulo5"),
      target: () => refGrafico.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    },
  ];

  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_VUELOS_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=flights&unique_id=" +
          idUnico +
          "&id=" +
          idEmpresa +
          "&ids=" +
          idSede;
        const datosVuelos = await traerDatosObjeto(URI_CONSUMOS_VUELOS_UNICOS);
        setTempDatosCalculo({
          fecha: datosVuelos.date,
          vuelo: datosVuelos.flight,
          cantidad_pasajeros: datosVuelos.passangers_amount,
          pais_origen: datosVuelos.origin_country,
          ciudad_origen: datosVuelos.origin_city,
          pais_destino: datosVuelos.destination_country,
          ciudad_destino: datosVuelos.destination_city,
          motivo: datosVuelos.reason,
        });
      })();
    } else {
      setTempDatosCalculo({
        fecha: "",
        vuelo: "",
        cantidad_pasajeros: "",
        pais_origen: "",
        ciudad_origen: "",
        pais_destino: "",
        ciudad_destino: "",
        motivo: "",
      });
    }
  }, [idUnico, idEmpresa, idSede, sesion.empresaActiva, sesion.sedeActiva]);

  var [tooltipVisible, setTooltipVisible] = useState({
    fecha: false,
    vuelo: false,
    cantidad_pasajeros: false,
    pais_origen: false,
    ciudad_origen: false,
    pais_destino: false,
    ciudad_destino: false,
    motivo: false,
  });

  const [errores, setErrores] = useState({
    fecha: "",
    vuelo: "",
    cantidad_pasajeros: "",
    pais_origen: "",
    ciudad_origen: "",
    pais_destino: "",
    ciudad_destino: "",
    motivo: "",
  });

  const campos = [
    { name: "fecha", validator: validarFecha },
    { name: "vuelo", validator: validarSelector },
    { name: "cantidad_pasajeros", validator: validarSelector },
    { name: "pais_origen", validator: validarSelectorPais },
    { name: "ciudad_origen", validator: validarSelectorAeropuerto },
    { name: "pais_destino", validator: validarSelectorPais },
    { name: "ciudad_destino", validator: validarSelectorAeropuerto },
    { name: "motivo", validator: validarSelector },
  ];

  const [show, setShow] = useState("");
  const [fetchDatos, setFetchDatos] = useState([]);
  const [opcionesCascader, setOpcionesCascader] = useState([]);
  const calcularDistancia = async (lat1, lon1, lat2, lon2) => {
    const radioTierra = 6371; // Radio de la tierra en kilómetros
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distancia = parseFloat(radioTierra * c).toFixed(2);
    return distancia;
  };

  const armarOpcionesCascader = async (opcionesPaises, opcionesAeropuertos) => {
    const opcionesCascaderInternas = [];
    opcionesPaises.map((pais) => {
      const aeropuertosDelPais = opcionesAeropuertos.filter(
        (aeropuerto) => aeropuerto.codigo_iso === pais.alfa2
      );

      if (aeropuertosDelPais.length > 0) {
        const opcionPais = {
          value: pais.id,
          label: pais.name,
          children: [],
        };

        aeropuertosDelPais.map((aeropuerto) => {
          opcionPais.children.push({
            value: aeropuerto.id,
            label: aeropuerto.nombre,
          });
        });

        opcionesCascaderInternas.push(opcionPais);
      }
    });
    return opcionesCascaderInternas;
  };

  useEffect(() => {
    (async () => {
      setOpcionesMotivos(await traerDatos(URI_MOTIVOS_VIAJES));
      setOpcionesPaises(await traerDatos(URI_PAISES_ISO));
      setOpcionesAeropuertos(await traerDatos(URI_AEROPUERTOS));
      setOpcionesVuelos(await traerDatos(URI_VUELOS));
      setFetchDatos(await traerDatos(URI_CONSUMOS_VUELOS));
    })();
  }, [show, URI_CONSUMOS_VUELOS]);

  useEffect(() => {
    (async () => {
      setOpcionesCascader(
        await armarOpcionesCascader(opcionesPaises, opcionesAeropuertos)
      );
    })();
  }, [opcionesPaises, opcionesAeropuertos]);

  useEffect(() => {
    setAeropuertoDestino(
      opcionesAeropuertos.find(
        (opcion) => opcion.id === tempDatosCalculo.ciudad_destino
      )
    );
    setAeropuertoOrigen(
      opcionesAeropuertos.find(
        (opcion) => opcion.id === tempDatosCalculo.ciudad_origen
      )
    );

    if (aeropuertoDestino && aeropuertoOrigen) {
      (async () => {
        setKmRecorridos(
          await calcularDistancia(
            aeropuertoOrigen.latitud,
            aeropuertoOrigen.longitud,
            aeropuertoDestino.latitud,
            aeropuertoDestino.longitud
          )
        );
      })();
    }
  }, [
    tempDatosCalculo,
    opcionesAeropuertos,
    aeropuertoDestino,
    aeropuertoOrigen,
  ]);

  let URI_NUEVO_CALCULO;
  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.motivo")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => a.motivo.localeCompare(b.motivo),
      render: (a) => (
        <div style={{ fontSize: "0.8em" }}>
          {currentLanguage === "es" ? a.motivo : a.motivo_en}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.periodo")}</span>
        </div>
      ),
      key: "fecha",
      align: "center",
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      render: (a) => (
        <div style={{ fontSize: "0.8em" }}>
          {moment(`${a.fecha}`, "YYYY-MM-DD").format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {" "}
            {t("tablas.kilometrosRecorridos")}
          </span>
        </div>
      ),
      key: "km_recorridos",
      align: "right",
      sorter: (a, b) => a.km_recorridos - b.km_recorridos,
      render: (a) => (
        <div style={{ fontSize: "0.8em" }}>{fNum(a.km_recorridos)}</div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladas") }}
          />
        </div>
      ),
      key: "kgcodose_emitido",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
      render: (a) => (
        <div style={{ fontSize: "0.8em" }}>{fNum(a.kgcodose_emitido)}</div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {dato.compensado === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrar(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.compensado === 2 && (
              <span>
                <b> {t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.compensado === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];
  const expandableData = {
    expandedRowRender: (dato) => (
      <div className="row">
        <div className="origen-destino col-3 text-center text-white pt-1">
          <EnvironmentOutlined />
          <h3 className="h6 mt-0 mb-2" style={{ fontSize: "0.8em" }}>
            {t("tablas.origen")}
          </h3>
        </div>
        <div className="color-paises col-9 text-center text-white">
          <h2 className="h4 mb-0 mt-2" style={{ fontSize: "0.8em" }}>
            {dato.paisO}
          </h2>
          <h3 className="h6 mt-1 mb-2" style={{ fontSize: "0.8em" }}>
            {dato.ciudadO}
          </h3>
        </div>
        <div className="fila-media col-3 text-center pt-1">
          <h2 className="texto-pasajeros h6 mt-0 mb-2">
            <b>{dato.cantidad_pasajeros} </b>
            <UserOutlined />
          </h2>
        </div>
        <div className="fila-media col-9 text-center text-grey"></div>
        <div className=" origen-destino col-3 text-center  text-white pt-1">
          <EnvironmentOutlined />
          <h3 className="h6 mt-0 mb-2" style={{ fontSize: "0.8em" }}>
            {t("tablas.destino")}
          </h3>
        </div>
        <div className="color-paises col-9 text-center  text-white">
          <h2 className="h4 mb-0 mt-2" style={{ fontSize: "0.8em" }}>
            {dato.paisD}
          </h2>
          <h3 className="h6 mt-1 mb-2" style={{ fontSize: "0.8em" }}>
            {dato.ciudadD}
          </h3>
        </div>
      </div>
    ),
  };
  //

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setTempDatosCalculo({
      ...tempDatosCalculo,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };
  const handleChangeOrigen = (e) => {
    if (e === undefined) {
      setTempDatosCalculo({
        ...tempDatosCalculo,
        pais_origen: "",
        ciudad_origen: "",
      });
    } else {
      setTempDatosCalculo({
        ...tempDatosCalculo,
        pais_origen: e[0],
        ciudad_origen: e[1],
      });
    }
  };
  const handleChangeDestino = (e) => {
    if (e === undefined) {
      setTempDatosCalculo({
        ...tempDatosCalculo,
        pais_destino: "",
        ciudad_destino: "",
      });
    } else {
      setTempDatosCalculo({
        ...tempDatosCalculo,
        pais_destino: e[0],
        ciudad_destino: e[1],
      });
    }
  };

  ///
  // const handleChangeAnio = (e) => {
  //     const { value } = e.target;
  //     // console.log(value)
  //     setYearRange(value)
  // }
  const guardar = async (e) => {
    e.preventDefault();

    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculo[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);
      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      if (idUnico) {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_vuelos?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=" +
          idUnico;
      } else {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_vuelos?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=none";
      }

      const registro = await axios.post(
        URI_NUEVO_CALCULO,
        {
          date: tempDatosCalculo.fecha,
          flight: tempDatosCalculo.vuelo,
          passangers_amount: tempDatosCalculo.cantidad_pasajeros,
          quantity_kilometers: kmRecorridos,
          origin_country: tempDatosCalculo.pais_origen,
          origin_city: tempDatosCalculo.ciudad_origen,
          destination_country: tempDatosCalculo.pais_destino,
          destination_city: tempDatosCalculo.ciudad_destino,
          reason: tempDatosCalculo.motivo,
        },
        HeadersAuth
      );

      if (registro.data.errors) {
        const errores = registro.data.errors;
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es"
            ? error.msg.ES
            : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/borrar.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                setAeropuertoDestino({});
                setAeropuertoOrigen({});
              }
            });
            setKmRecorridos();
            setTempDatosCalculo({
              fecha: "",
              vuelo: "",
              cantidad_pasajeros: "",
              pais_origen: "",
              ciudad_origen: "",
              pais_destino: "",
              ciudad_destino: "",
              motivo: "",
            });

            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            if (idUnico) {
              setIdUnico("");
            }
          }
        }
      }
    }
  };

  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIdUnico(value);
  };

  const borrar = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    // const { value } = e.target;
    // console.log(value)
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=flights&id=" +
      idEmpresa +
      "&ids=" +
      idSede +
      "&unique_id=" +
      value;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
        }
      }
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-4 me-4 mb-4">
        <div className="row row-form px-2">
          <div className="col-12 col-md-5 mb-4">
            <div className="contenedor-form-vuelos">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras">
                  {titulo}
                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                    <>
                      <Tooltip title={t("tooltip.nuevoCalculo")}>
                        <PlusOutlined
                          className="btnnuevocalculo p-1 mb-1"
                          onClick={(e) => setIdUnico("")}
                        />
                      </Tooltip>{" "}
                      <br></br>
                    </>
                  ) : (
                    <></>
                  )}
                </h2>
                <form onSubmit={guardar}>
                  
                  <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                    <div
                      ref={refFecha}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista  text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {t("tablas.fecha")}
                      </label> </div>
                      <Tooltip
                        title={!!errores.fecha && <span>{errores.fecha}</span>}
                        open={tooltipVisible.fecha}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <input
                            className="form-control w-100"
                            type="date"
                            min="2010-01-01"
                            max={`${new Date().getFullYear()}-${(
                              "0" +
                              (new Date().getMonth() + 1)
                            ).slice(-2)}-${("0" + new Date().getDate()).slice(
                              -2
                            )}`}
                            id="fecha"
                            value={tempDatosCalculo.fecha || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      ref={refMotivo}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.motivoViaje")}
                        </label>{" "}
                      </div>
                      <Tooltip
                        title={
                          !!errores.motivo && <span>{errores.motivo}</span>
                        }
                        open={tooltipVisible.motivo}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="motivo"
                          onChange={handleChange}
                          value={tempDatosCalculo.motivo || ""}
                        >
                          <option value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesMotivos.map((option) => (
                            <option key={option.id} value={option.id}>
                              {currentLanguage === "es"
                                ? option.motivo
                                : option.motivo_en}
                            </option>
                          ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div
                      ref={refClase}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.clases")}
                        </label>{" "}
                      </div>
                      <Tooltip
                        title={!!errores.vuelo && <span>{errores.vuelo}</span>}
                        open={tooltipVisible.vuelo}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="vuelo"
                          onChange={handleChange}
                          value={tempDatosCalculo.vuelo || ""}
                        >
                          <option value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesVuelos.map((option) => (
                            <option key={option.id} value={option.id}>
                              {currentLanguage === "es"
                                ? option.vuelo
                                : option.vuelo_en}
                            </option>
                          ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div
                      ref={refPasajeros}
                      className="col-md-6 col-12  col-input-calculadoras"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista  text-left mt-2"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.pasajeros")}
                        </label>
                      </div>
                      <Tooltip
                        title={
                          !!errores.cantidad_pasajeros && (
                            <span>{errores.cantidad_pasajeros}</span>
                          )
                        }
                        open={tooltipVisible.cantidad_pasajeros}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="cantidad_pasajeros"
                          onChange={handleChange}
                          value={tempDatosCalculo.cantidad_pasajeros || ""}
                        >
                          <option value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          <option key={1} value={1}>
                            1
                          </option>
                          <option key={2} value={2}>
                            2
                          </option>
                          <option key={3} value={3}>
                            3
                          </option>
                          <option key={4} value={4}>
                            4
                          </option>
                          <option key={5} value={5}>
                            5
                          </option>
                          <option key={6} value={6}>
                            6
                          </option>
                          <option key={7} value={7}>
                            7
                          </option>
                          <option key={8} value={8}>
                            8
                          </option>
                          <option key={9} value={9}>
                            9
                          </option>
                          <option key={10} value={10}>
                            10
                          </option>
                        </select>
                      </Tooltip>
                    </div>
                    <div
                      ref={refOrigen}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.origen")}
                        </label>
                     
                   
                        <Tooltip
                          title={
                            !!errores.ciudad_origen &&
                            !!errores.pais_origen && (
                              <span>
                                {errores.pais_origen} <br />{" "}
                                {errores.ciudad_origen}
                              </span>
                            )
                          }
                          open={
                            tooltipVisible.ciudad_origen ||
                            tooltipVisible.pais_origen
                          }
                          color="#1CA6AF"
                        >
                          <Cascader
                          className="w-100"
                            id="origen"
                            options={opcionesCascader}
                            value={[
                              tempDatosCalculo.pais_origen || "",
                              tempDatosCalculo.ciudad_origen || "",
                            ]}
                            onChange={handleChangeOrigen}
                          />
                        </Tooltip>
                        </div>
                    </div>
                    <div
                      ref={refDestino}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label className="select_lista text-left">
                          {t("tablas.destino")}
                        </label>
                     
                  
                        <Tooltip
                          title={
                            !!errores.ciudad_destino &&
                            !!errores.pais_destino && (
                              <span>
                                {errores.pais_destino} <br />{" "}
                                {errores.ciudad_destino}
                              </span>
                            )
                          }
                          open={
                            tooltipVisible.ciudad_destino ||
                            tooltipVisible.pais_destino
                          }
                          color="#1CA6AF"
                        >
                          <Cascader
                          className="w-100"
                            id="destino"
                            options={opcionesCascader}
                            value={[
                              tempDatosCalculo.pais_destino || "",
                              tempDatosCalculo.ciudad_destino || "",
                            ]}
                            onChange={handleChangeDestino}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      ref={refBtnGuardar}
                      className="col-md-6 col-12 mt-4 d-flex justify-content-end w-100"
                    >
                      <BtnPrincipal name={t("botones.calcular")} />
                    </div>
                  </div>

                  <br></br>
                </form>
              </div>{" "}
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="contenedor-form-trayecto">
              <div
                ref={refGrafico}
                className="col-6 col-input-calculadoras"
                style={{ width: "100%", gridColumn: "span 4" }}
              >
                <label
                  className="select_lista"
                  style={{ paddingBottom: "0.5em" }}
                >
                  {t("tablas.trayecto")}
                </label>
                <MemoizedMap
                  aeropuertoDestino={aeropuertoDestino}
                  aeropuertoOrigen={aeropuertoOrigen}
                />
              </div>{" "}
            </div>{" "}
          </div>

          <div className="col-12 col-md-12">
            <div className="contenedor-tabla-vuelos">
              <div
                ref={refTabla}
                className="12 col-md-12 "
                style={{
                  width: "100%",
                  gridColumn: "span 6",
                  flexDirection: "column",
                }}
              >
                <TablaCompleta
                  fetchDatos={fetchDatos}
                  Columns={Columns}
                  elScroll={elScroll}
                  expandableData={expandableData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
