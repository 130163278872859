import { Tooltip, Tabs } from "antd";
import axios from "axios";
import moment from "moment";
import { CheckOutlined, CloseOutlined ,FilePdfOutlined } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import withReactContent from "sweetalert2-react-content";
import { HeadersAuth, CheckExpiracion, fNum } from "../funciones_utiles";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

export const CompDatosProyectosCompensaciones = ({ sesion, id_empresa }) => {
  const [t] = useTranslation("global");
  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(id_empresa)
  );

  const [listaTab, setListaTab] = useState([]);
  const [contenidoTab] = useState({});
  const [activeTabKey2, setActiveTabKey2] = useState();
  const [datosMacro, setDatosMacro] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);

  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.fechaPedido")}</span>
        </div>
      ),
      key: "fecha_pedido",
      align: "center",
      sorter: (a, b) => a.fecha_pedido.localeCompare(b.fecha_pedido),
      render: (a) =>
        moment(`${a.fecha_pedido}`, "YYYY-MM-DD").format("DD/MM/YYYY"),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("empresa.empresa")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => -a.razon_social.localeCompare(b.razon_social),
      render: (a) => a.razon_social,
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
            <span dangerouslySetInnerHTML={{ __html: t('tablas.toneladas') }} />
        </div>
      ),
      align: "right",
      sorter: (a, b) => a.cantidad - b.cantidad,
      render: (a) => fNum(a.cantidad),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("planes.año2")} </span>
        </div>
      ),
      align: "right",
      sorter: (a, b) => a.anio - b.anio,
      render: (a) => a.anio,
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.estado")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => -a.estado_final.localeCompare(b.estado_final),
      render: (a) =>
        a.estado_final +
        ", " +
        moment(`${a.fecha_pedido}`, "YYYY-MM-DD").format("DD/MM/YYYY"),
    },
    {
      title: "",
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center  d-flex justify-content-center">
            {dato.estado === 2 && (
              <>
                <Tooltip title={t("tooltip.aceptarCompensacion")}>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => aceptarCompensacion(e, dato.id)}

                >
                    <CheckOutlined />
                </button>
                </Tooltip>
                <Tooltip title={t("tooltip.cancelarCompensacion")}>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => cancelarCompensacion(e,dato.id)}

                >
                     <CloseOutlined />
                </button>
                </Tooltip>
                {loaderBtn ? (
                  <Tooltip title={t("tooltip.descargarPDF")}>
                  <button
                    className="boton-vehiculos-nuevo justify-content-center"
                    value={dato.id}
                    disabled
                  >
                     <FilePdfOutlined />
                  </button></Tooltip>
                ) : (
                  <Tooltip title={t("tooltip.descargarPDF")}>
                  <button
                    className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => generarPDFDataEmpresa(e,dato.id)}

                  >
                    <FilePdfOutlined />
                  </button></Tooltip>
                )}
              </>
            )}
            {dato.estado === 3 && (
              <>
                {loaderBtn ? ( 
                <Tooltip title={t("tooltip.descargarPDF")}>
                  <button
                    className="boton-vehiculos-nuevo justify-content-center"
                    value={dato.id}
                    disabled
                  >
                     <FilePdfOutlined />
                  </button></Tooltip>
                ) : (
                  <Tooltip title={t("tooltip.descargarPDF")}>
                  <button
                    className="boton-vehiculos-nuevo justify-content-center"
                    value={dato.id}
                    onClick={generarPDFDataEmpresa}
                  >
                    <FilePdfOutlined />
                  </button></Tooltip>
                )}
              </>
            )}
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      const URI_DATOS_MACRO =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/pedidos_compensacion_listado?id=" +
        id_empresa;
      const losDatosMacro = await axios.post(URI_DATOS_MACRO, HeadersAuth);
      setDatosMacro(losDatosMacro.data);
      // console.log(await axios.post(URI_DATOS_MACRO, HeadersAuth));
    })();
  }, [id_empresa, trigger]);
  const onTab2Change = (key) => {
    setActiveTabKey2(key);
  };
  useEffect(() => {
    const listaTab = [];

    // console.log(datosMacro)
    if (datosMacro.datos_proyectos) {
      // console.log("los reportes: " + JSON.stringify(datosMacro));
      // console.log(datosMacro.datos_proyectos);
      datosMacro.datos_proyectos.forEach((reporte) => {
        const nombre = reporte.nombre;
        const datos = reporte.datos;
        const id_proyecto = reporte.id_proyecto;
        if (datos && datos.length > 0) {
          const key = id_proyecto;
          listaTab.push({
            key: key,
            label: (
              <Tooltip placement="left" title={nombre} className="tooltip-head">
                <b className="subtitulo-grafico" >{nombre.substring(0, 15)}</b>
              </Tooltip>
            ),
            children: (
              <>
                <h2
                  style={{
                    fontSize: "1rem",
                    fontWeight: "1000",
                    color: "#1CA6AF",
                  }}
                >
                  {nombre}
                </h2>
                <TablaCompleta
                className={"table-responsive table-scrollbar-y"}
                  fetchDatos={datos}
                  Columns={Columns}
                  elScroll={elScroll}
                />
              </>
            ),
            disabled: false,
          });
          // contenidoTab[key] = ;
        }
      });
    }
    setActiveTabKey2(listaTab.length > 0 ? listaTab[0].key : "");
    // console.log(listaTab.length > 0 ? listaTab[0].key : "NO EXISTO")
    setListaTab(listaTab);
    // setContenidoTab(contenidoTab);
  }, [datosMacro]);
  const listaTabFiltrada = listaTab.filter((tab) => !tab.disabled);
  const cancelarCompensacion = async (e,value) => {
    e.preventDefault();
   // const { value } = e.target;
    const id_compensacion = value;
    console.log("id compensacion", id_compensacion)
    let validarCompensar = MySwal.fire({
      title: t("mensajes.mensaje17"),
      text: ``,
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });
    if ((await validarCompensar).isConfirmed) {
      const URI_CANCELAR_COMPENSAR =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/cancelar_compensacion_compensador?id=" +
        id_empresa +
        "&idc=" +
        id_compensacion;
      const compensar = await axios.post(URI_CANCELAR_COMPENSAR, HeadersAuth);
      if (compensar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else if (compensar.data.code === 401) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (compensar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (compensar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje18"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
          }
          trigger ? setTrigger(false) : setTrigger(true);
        }
      }
    }
  };
  const generarPDFDataEmpresa = (e,value) => {
    e.preventDefault();
   // const { value } = e.target;
    const URI_REGENERAR_PDF =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/datos_compensacion_empresa?id=" +
      id_empresa +
      "&idc=" +
      value;
    // var loading = false
    setLoaderBtn(true);
    axios
      .get(URI_REGENERAR_PDF, {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        // console.log(response.data.pdf)
        if (parseInt(response.data.code) === 200) {
          const nombreHash = Math.imul(31, value);
          // console.log(url)
          setLoaderBtn(false);
          // const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.data).buffer]));
          const link = document.createElement("a");
          link.href =
            "data:application/octet-stream;base64," + response.data.pdf;
          link.setAttribute(
            "download",
            "Informacion_compensacion_" + nombreHash + ".pdf"
          );
          document.body.appendChild(link);
          link.click();
          trigger ? setTrigger(false) : setTrigger(true);
        } else if (
          parseInt(response.data.code) === 401 &&
          parseInt(response.data.code) === 409 &&
          parseInt(response.data.code) === 601
        ) {
          MySwal.fire({
            text: t("mensajes.mensaje12"),
            imageUrl: "img/lechuza-triste.png",
            imageHeight: 55,
            confirmButtonText: "Ok",
          });
          setLoaderBtn(false);
        }
      })
      .catch((error) => console.log(error));
  };
  const aceptarCompensacion = async (e,value) => {
    e.preventDefault();
    // const { value } = e.target;
    const id_compensacion = value;
    
    const validarCompensar = await MySwal.fire({
      title: t("mensajes.mensaje33"),
      input: "number",
      inputLabel: t("compensacion.informacion.ingresarMonto"),
      inputPlaceholder: "0.00",
      inputAttributes: {
        step: "any"
      },
      text: ``,
      imageUrl: "img/img_activada.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });
  
    if (validarCompensar.isConfirmed) {
      const monto = parseFloat(validarCompensar.value);
      if (monto > 0) {
        const URI_ACEPTAR_COMPENSAR =
          `${process.env.REACT_APP_DOMINIO}${process.env.REACT_APP_PUERTO}/aceptar_compensacion_compensador?id=${id_empresa}&idc=${id_compensacion}`;
        try {
          const compensar = await axios.post(URI_ACEPTAR_COMPENSAR, { monto: monto.toFixed(2) }, HeadersAuth);
  
          if (compensar.data.code === 200) {
            await MySwal.fire({
              text: t("mensajes.mensaje19"),
              imageUrl: "img/imagen1.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
          } else if ([401, 402].includes(compensar.data.code)) {
            await MySwal.fire({
              text: t("mensajes.mensaje12"),
              imageUrl: "img/lechuza-triste.png",
              imageHeight: 55,
              confirmButtonText: "Ok",
            });
          } else if (compensar.data.code === 409) {
            CheckExpiracion();
          }
  
          setTrigger(prev => !prev); // Actualiza el estado
        } catch (error) {
          console.error("Error al aceptar la compensación:", error);
        }
      } else {
        await MySwal.fire({
          text: t("compensacion.informacion.ingresarMonto2"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      }
    }
  };
  
  return (
    <>
      <div className="panel-empresa row g-3 d-flex justify-content-center ms-3 me-3">
        <Tabs
          style={{
            width: "100%",
          }}
          tabPosition={window.innerWidth > 992 ? "left" : "top"} 
          items={listaTabFiltrada}
          onChange={onTab2Change}
          className="tab-compensador"
        >
          {contenidoTab[activeTabKey2]}
        </Tabs>
      </div>
    </>
  );
};
