import axios from "axios";
import { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Menu, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const KEY_CAPTCHA = process.env.REACT_APP_CLAVE_RECAPTCHA;
const URI_LOGIN =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/login";

const MySwal = withReactContent(Swal);

export const CompLoginMini = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("preferredLanguage", lng);
  };

  const [emailLogin, setEmailLogin] = useState("");
  const [passLogin, setPassLogin] = useState("");
  const navigate = useNavigate();

  const recaptchaRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const logear = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const logearse = await axios.post(
      URI_LOGIN,
      {
        email: emailLogin,
        pass: passLogin,
        token: captchaToken,
      },
      {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    if (logearse.data.errors) {
      const errores = logearse.data.errors;
      const msgError = errores.map((error) => {
        return (
          "<pre>" +
          (currentLanguage === "es" ? error.msg.ES : error.msg.EN) +
          "</pre>"
        );
      });
      MySwal.fire({
        html: `${msgError}`,
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 50,
        confirmButtonText: "Ok",
      });
    } else {
      if (logearse.data.code === 200) {
        MySwal.fire({
          text: t("mensajes.mensaje1"),
          imageUrl: "img/registro-exitoso.svg",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
        const now = new Date();
        const expiration = new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000);
        localStorage.setItem("valid_session", logearse.data.token, expiration);
        navigate("/");
      }
      if (logearse.data.code === 302) {
        MySwal.fire({
          text: t("mensajes.mensaje2"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 50,
          confirmButtonText: "Ok",
        });
      }
      if (logearse.data.code === 407) {
        MySwal.fire({
          text: t("mensajes.mensaje3"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 50,
          confirmButtonText: "Ok",
        });
      }
      if (logearse.data.code === 301) {
        MySwal.fire({
          text: t("mensajes.mensaje4"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 50,
          confirmButtonText: "Ok",
        });
      }
      if (logearse.data.code === 401) {
        MySwal.fire({
          text: t("mensajes.mensaje5"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 50,
          confirmButtonText: "Ok",
        });
      }
      if (logearse.data.code === 403) {
        MySwal.fire({
          text: t("mensajes.mensaje83"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 50,
          confirmButtonText: "Ok",
        });
      }
    }
  };

  return (
    <div className="regsim body_calculadora">
      <div className="row menu-wrapper justify-content-between align-items-center">
        <div className="col-md-4  col-6 text-left">
          <img
            src="./img/logo-menu.png"
            alt=""
            className="img_logo"
            style={{ width: "10em" }}
          />
        </div>

        <div className="col-md-4 text-center d-none d-md-block ">
          <ul className="ul-nuevo mt-2">
            <li className="li-nuevo">
              <a href="https://owl-track.com/" rel="noreferrer" target="_blank">
                {t("botones.inicio")}
              </a>
            </li>
            <li className="li-nuevo">
              <a
                href="https://owl-track.com/blog/"
                rel="noreferrer"
                target="_blank"
              >
                {t("botones.noticias")}
              </a>
            </li>
            <li className="li-nuevo">
              <a
                href="https://owl-track.com/contacto/"
                rel="noreferrer"
                target="_blank"
              >
                {t("botones.contacto")}
              </a>
            </li>
            <li className="li-nuevo">
              <a href="/signup" rel="noreferrer" target="_blank">
                {t("botones.calculadora")}
              </a>
            </li>
          </ul>
        </div>

        <div className="col-md-4 text-right d-none d-md-block ">
          <div
            className="contenendor-idiomas"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
              <div
              className="bandera"
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
            
              }}
              onClick={() => changeLanguage("en")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/en.png"
                alt="English"
                className="bandera"
              />
              <span style={{ fontWeight: "700" }}>EN</span>
            </div>
            <div
              className="bandera "
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
      
              }}
              onClick={() => changeLanguage("es")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/esp.png"
                alt="Español"
                className="bandera"
              />
              <span style={{ fontWeight: "700" }}>ES</span>
            </div>
          </div>
        </div>

        <div className="col-6 d-block d-md-none text-right ">
          <Button
            className="btn-burguer-menu"
            type="primary"
            onClick={showDrawer}
            icon={<MenuOutlined />}
          />
        </div>
      </div>

      <Drawer
        title="Owl Track"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <Menu mode="vertical">
          <Menu.Item key="1">
            <a href="https://owl-track.com/" rel="noreferrer" target="_blank">
              {t("botones.inicio")}
            </a>
          </Menu.Item>
          <Menu.Item key="2">
            <a
              href="https://owl-track.com/blog/"
              rel="noreferrer"
              target="_blank"
            >
              {t("botones.noticias")}
            </a>
          </Menu.Item>
          <Menu.Item key="3">
            <a
              href="https://owl-track.com/contacto/"
              rel="noreferrer"
              target="_blank"
            >
              {t("botones.contacto")}
            </a>
          </Menu.Item>
          <Menu.Item key="4">
            <a href="/signup" rel="noreferrer" target="_blank">
              {t("botones.calculadora")}
            </a>
          </Menu.Item>
          <Menu.Item key="5">
            <div
              className="bandera"
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: "1em",
              }}
              onClick={() => changeLanguage("en")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/en.png"
                alt="English"
                className="bandera"
              />
              <span style={{ fontWeight: "700" }}>EN</span>
            </div>
          </Menu.Item>
          <Menu.Item key="6">
            <div
              className="bandera "
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: "1em",
              }}
              onClick={() => changeLanguage("es")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/esp.png"
                alt="Español"
                className="bandera"
              />
              <span style={{ fontWeight: "700" }}>ES</span>
            </div>
          </Menu.Item>
        </Menu>
      </Drawer>

      <div className="container-login-nuevo text-center p-0">
        <div className="row p-0 m-0">
          <div className="col-md-6 col-login-form justify-content-center align-items-center">
            <div className="content">
              <h1 className="titulo-registro">{t("home.titulo")}</h1>
              <h3 className="subtitulo-registro"> {t("home.subtitulo")}</h3>
              <br></br>

              <p className="text-login p-0">{t("home.texto1")} </p>
              <p className="text-login">
                {" "}
                {t("home.texto2")}
                <br></br>
                {t("home.texto3")}
              </p>
            </div>
          </div>

          <div className="col-md-6 col-login-form mt-5 mb-5 justify-content-center align-items-center ">
            <div className="form-container">
              <div className="form-btn-login">
                <span className="txt-login">{t("home.iniciarSesion")}</span>
              </div>
              <form className="form-login" id="LoginForm" onSubmit={logear}>
                <input
                  type="email"
                  placeholder={t("home.email")}
                  id="emailLogin"
                  className="input-login"
                  onChange={(e) => setEmailLogin(e.target.value)}
                  value={emailLogin}
                  required
                ></input>

                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={t("home.contra")}
                  id="passLogin"
                  className="input-login"
                  onChange={(e) => setPassLogin(e.target.value)}
                  value={passLogin}
                  required
                ></input>
                <span className="eye-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FaEye onClick={togglePasswordVisibility} />
                  ) : (
                    <FaEyeSlash onClick={togglePasswordVisibility} />
                  )}
                </span>

                <Link to="/recuperar" className="titulo-contraseña">
                  {t("home.olvidoContrasenia")}
                </Link>
                <Link to="/signup" className="titulo-contraseña">
                  {t("home.noEstaReg")}
                </Link>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={KEY_CAPTCHA}
                  size="invisible"
                />
                <button type="submit" value="Registrarme" className="btn-login">
                  {t("home.botonISesion")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
