import moment from "moment";
import { Avatar, List, Input, Tooltip } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { SearchOutlined, CheckOutlined,CloseOutlined   } from "@ant-design/icons";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import {
  traerDatos,
  CheckExpiracion,
  HeadersAuth,
} from "../../funciones_utiles";
import Swal from "sweetalert2";
import { RiSeedlingLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const { Search } = Input;

export const CompConfigEmpresasAsesor = ({ sesion }) => {
  const [t] = useTranslation("global");
  const [inputValue, setInputValue] = useState("");
  const [filteredList, setFilteredList] = useState();
  const [infoEmpresa, setInfoEmpresa] = useState([]);
  const URI_EMPRESAS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/empresas_por_usuario_asesor";
  const [inputValueI, setInputValueI] = useState("");
  const [filteredListI, setFilteredListI] = useState();
  const [infoInvitaciones, setInfoInvitaciones] = useState([]);
  const URI_INVITACIONES =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_invitaciones_asesor";

  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(sesion.empresaPrincipal)
  );
  const buscoInfo = async () => {
    setInfoEmpresa(await traerDatos(URI_EMPRESAS));
    setInfoInvitaciones(await traerDatos(URI_INVITACIONES));
  };
  useEffect(() => {
    buscoInfo();
  }, []);
  //Buscador
  useEffect(() => {
    // setFilteredList(infoVehiculos)
    if (inputValue) {
      setFilteredList(
        filteredList.filter(
          (item) =>
            item.razon_social
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredList(infoEmpresa);
    }
  }, [inputValue, infoEmpresa, filteredList]);
  useEffect(() => {
    // setFilteredList(infoVehiculos)
    if (inputValueI) {
      setFilteredListI(
        filteredListI.filter(
          (item) =>
            item.razon_social
              .toString()
              .toLowerCase()
              .indexOf(inputValueI.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredListI(infoInvitaciones);
    }
  }, [inputValueI, infoInvitaciones, filteredListI]);
  function onSearch(e) {
    setInputValue(e.target.value);
  }
  function onSearchI(e) {
    setInputValueI(e.target.value);
  }

  /*Cuando supera el limite de empresas */

  const aceptarInvite = async (e) => {
    e.preventDefault();
    let validarBorrar = MySwal.fire({
      text: t("mensajes.mensaje37"),
      text: "",
      imageUrl: "img/img_activada.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });

    if ((await validarBorrar).isConfirmed) {
      window.scrollTo(0, 0);
      const { id } = e.target;
      const URI_ACEPTAR_INVITACION =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/aceptar_invitacion_asesor?id=" +
        id;
      const borrar = await axios.post(URI_ACEPTAR_INVITACION, HeadersAuth);
      if (borrar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else if (borrar.data.code === 401) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje38"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            buscoInfo();
          }
        }
      }
    }
  };
  const cancelarInvite = async (e) => {
    e.preventDefault();
    let validarBorrar = MySwal.fire({
      title: t("mensajes.mensaje39"),
      text: t("mensajes.mensaje40"),
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });

    if ((await validarBorrar).isConfirmed) {
      window.scrollTo(0, 0);
      const { id } = e.target;
      const URI_BORRAR_SEDE =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/cancelar_invitacion_asesor?id=" +
        id;
      const borrar = await axios.post(URI_BORRAR_SEDE, HeadersAuth);
      if (borrar.data.code === 401 || borrar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje10"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            buscoInfo();
          }
        }
      }
    }
  };
  const borrarEmpresa = async (e, id_empresa) => {
    let validarBorrar = MySwal.fire({
      title: t("mensajes.mensaje39"),
      text: t("mensajes.mensaje40"),
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });

    if ((await validarBorrar).isConfirmed) {
      window.scrollTo(0, 0);
      const URI_BORRAR_SEDE =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/desactivar_empresa?id=" +
        id_empresa;
      const borrar = await axios.post(URI_BORRAR_SEDE, HeadersAuth);
      if (borrar.data.code === 401 || borrar.data.code === 402) {
        MySwal.fire({
          title: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else if (borrar.data.code === 403) {
        MySwal.fire({
          text: t("mensajes.mensaje41"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              title: t("mensajes.mensaje10"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            buscoInfo();
          }
        }
      }
    }
  };

  const limiteEmpresas = (e) => {
    e.preventDefault();
    Swal.fire({
      title: t("mensajes.mensaje42"),
      text: t("mensajes.mensaje43"),
      imageUrl: "img/lechuza-triste.png",
      imageHeight: 50,
      confirmButtonText: "Ok",
    });
  };

  return (
    <>
      <div className="row  m-0 ">
        <div className="col-md-6 col-input-calculadoras">
          <div className="contenedor-empresa row g-3 d-flex align-items-center">
            <div className=" mt-4 " style={{ justifyContent: "space-between" }}>
              {" "}
              <div className="contenedor-btn-empresa">
                {infoEmpresa.length < parseInt(plan.max_empresas) ? (
                  <Link to={"/empresa/nueva"}>
                    <button
                      className="boton-agregar-empresa"
                      style={{ marginBottom: "1em" }}
                    >
                     {t("botones.agregarEmpresa")}
                    </button>
                  </Link>
                ) : (
                  <button
                    className="boton-agregar-empresa justify-content-start"
                    onClick={limiteEmpresas}
                  >
                     {t("botones.agregarEmpresa")}
                  </button>
                )}
                <Search
                  className="search-empresa "
                  placeholder=  {t("tablas.buscar")}
                  onChange={(e) => onSearch(e)}
                  enterButton
                  style={{ width: "12em" }}
                />
              </div>
            </div>
            <List
              itemLayout="horizontal"
              dataSource={filteredList}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    parseInt(item.duenio) === 1 ? (
                      <Link to={"/empresa/" + item.id_empresa}>
                        <Tooltip
                          placement="topLeft"
                          title={t("tooltip.editarEmpresa")}
                          className="tooltip-head"
                        >
                          <button
                            className="boton-agregar-empresa"
                            style={{ marginBottom: "1em" }}
                          >
                            Editar
                          </button>
                        </Tooltip>
                      </Link>
                    ) : (
                      ""
                    ),
                    <Tooltip
                      placement="topLeft"
                      title={t("tooltip.borrarEmpresa")}
                      onClick={(e) => borrarEmpresa(e, item.id_empresa)}
                      className="tooltip-head"
                    >
                      <button
                        className="boton-agregar-empresa"
                        style={{ marginBottom: "1em" }}
                      >
                       {t("botones.borrar")}
                      </button>
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item.logo || "./img/egreen.png"} />
                    }
                    title={item.razon_social}
                    description={""}
                  />
                </List.Item>
              )}
            />
            {/* <h1>{console.log(empresaUnica)}</h1> */}
          </div>
        </div>
        <div className="col-md-6 col-input-calculadoras">
          <div
            className="d-flex col-invitaciones"
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <h3
              className="subtitulo-empresas-search"
              style={{ marginTop: "0.5em" }}
            >
              <RiSeedlingLine
                className="icono-hojitas"
                style={{ color: "#1CA6AF" }}
              />
              {t("empresa.datosUsuarios.invitacionesPendientes")}
            </h3>
            <Input
              className="dominio"
              placeholder="Email"
              onChange={(e) => onSearchI(e)}
              style={{
                width: 200,
                height: 38,
              }}
              suffix={<SearchOutlined />}
            />
          </div>
          <List
            itemLayout="horizontal"
            dataSource={filteredListI}
            pagination={{
              size: "small",
              pageSize: 5,
            }}
            size="small"
            renderItem={(item) => (
              <List.Item
                actions={[
                  infoEmpresa.length < parseInt(plan.max_empresas) ? (
                    <button
                      className="boton-vehiculos justify-content-end"
                      onClick={aceptarInvite}
                      id={item.id}
                    >
                      <CheckOutlined />
                    </button>
                  ) : (
                    <button
                      className="boton-vehiculos justify-content-end"
                      onClick={limiteEmpresas}
                      id={item.id}
                    >
                     <CheckOutlined />
                    </button>
                  ),

                  <button
                    className="boton-vehiculos justify-content-end"
                    onClick={cancelarInvite}
                    id={item.id}
                  >
                       <CloseOutlined />
                  </button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src=".././img/user.png" />}
                  title={
                    item.razon_social +
                    ", " +
                    moment(`${item.fecha}`, "DD-YYYY-MM").format("DD/MM/YYYY")
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </>
  );
};
