import { useTranslation } from "react-i18next";
export const LoadMePlease = ({ cargando }) => {
	const [t] = useTranslation("global");
	return (
		<div>
			<div className="d-flex justify-content-center" style={{paddingTop: "7rem"}}>

      </div>
			<div className="d-flex justify-content-center">
				<div className="spinner"></div>
			</div>
			<div className="d-flex justify-content-center mt-2">
				<p className="cargando-texto">
					<b>{t("mensajes.mensaje69")}</b>
				</p>
			</div>
		</div>
	);
};
