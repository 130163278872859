import { Avatar, List, Input, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import { TablaCompleta } from "../../tablas/tabla_simple/tabla_simple";
import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
	traerDatos,
	HeadersAuth,
	traerDatosObjeto,
	CheckExpiracion,
} from "../../funciones_utiles";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

export const CompDatosPagos = ({ sesion, id_empresa }) => {
	const [t] = useTranslation("global");
	const URI_PAGOS =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/pagos_por_empresa?id=" +
		id_empresa;
	const URI_HISTORIAL_SUBSCRIPCIONES =
		process.env.REACT_APP_DOMINIO +
		process.env.REACT_APP_PUERTO +
		"/historial_subscripciones?id=" +
		id_empresa;
	const [pagosRealizados, SetPagosRealizados] = useState([]);
	const [historialSubscripciones, setHistorialSubscripciones] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				SetPagosRealizados(await traerDatos(URI_PAGOS));
				setHistorialSubscripciones(
					await traerDatos(URI_HISTORIAL_SUBSCRIPCIONES)
				);
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, [id_empresa, URI_PAGOS]);
	const elScroll = { x: 0 };
	const Columns = [
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span>{t("planes.fechaPago")}
					</span>
				</div>
			),
			key: "fecha",
			align: "center",
			sorter: (a, b) => a.fecha_pago.localeCompare(b.fecha_pago),
			render: (a) =>
				moment(`${a.fecha_pago}`, "YYYY-MM-DD").format("DD/MM/YYYY"),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span>{t("compensacion.tabla.estado")} </span>
				</div>
			),
			align: "left",
			sorter: (a, b) => -a.pago_rechazado.localeCompare(b.pago_rechazado),
			render: (a) =>
				parseInt(a.pago_rechazado) === 1 ? t("compensacion.tabla.rechazado") : t("compensacion.tabla.aceptado"),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span>{t("planes.numeroPago")}</span>
				</div>
			),
			align: "right",
			sorter: (a, b) => -a.numero.localeCompare(b.numero),
			render: (a) => a.numero,
		},
	];

	const ColumnsHSubs = [
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span>{t("empresa.suscripcion")}</span>
				</div>
			),
			align: "left",
			sorter: (a, b) => -a.tipo_subscripcion.localeCompare(b.pago_rechazado),
			render: (a) => a.tipo_subscripcion,
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span>{t("empresa.suscripcion1.fechaInicio")}</span>
				</div>
			),
			key: "fecha_inicio",
			align: "center",
			sorter: (a, b) => a.fecha_inicio.localeCompare(b.fecha_inicio),
			render: (a) =>
				moment(`${a.fecha_inicio}`, "YYYY-MM-DD").format("DD/MM/YYYY"),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span>{t("empresa.suscripcion1.fechaFin")}</span>
				</div>
			),
			key: "fecha_fin",
			align: "center",
			sorter: (a, b) => a.fecha_fin.localeCompare(b.fecha_fin),
			render: (a) =>
				moment(`${a.fecha_fin}`, "YYYY-MM-DD").format("DD/MM/YYYY"),
		},
		{
			title: () => (
				<div style={{ textAlign: "center" }}>
					<span>{t("planes.numeroPago")}</span>
				</div>
			),
			align: "right",
			sorter: (a, b) => -a.id_pago.localeCompare(b.id_pago),
			render: (a) => a.id_pago,
		},
	];

	return (
		<>
		
			<div className="row justify-content-center"  style={{ marginBottom: "2rem" }}>
			
					{pagosRealizados.length > 0 ? (
						<>
							<div className="col-md-5 col-input-calculadoras contenedor-form-pagos px-2 mx-2">
							<div className="row p">
								<h2
									style={{
										fontSize: "1rem",
										fontWeight: "1000",
										color: "#1CA6AF",
									}}>
									{t("empresa.historial1.pagos")}
								</h2>
							</div>
							<TablaCompleta
								fetchDatos={pagosRealizados}
								Columns={Columns}
								elScroll={elScroll}
							/></div>
						</>
					) : (
						<h3 className="texto-apy"> {t("empresa.historial1.texto1")}</h3>
					)}
			
				<div className="col-md-5 col-input-calculadoras contenedor-tabla-pagos px-2 mx-2">

					{historialSubscripciones.length > 0 ? (
						<>
							<div className="row p-3">
								<h2
									style={{
										fontSize: "1rem",
										fontWeight: "1000",
										color: "#1CA6AF",
									}}>
									{t("empresa.historial1.historialSuscripciones")}
								</h2>
							</div>
							<TablaCompleta
								fetchDatos={historialSubscripciones}
								Columns={ColumnsHSubs}
								elScroll={elScroll}
							/>
						</>
					) : (
						<>
							<div className="row p-3 text-center justify-content-center">
								<h2
									style={{
										fontSize: "1rem",
										fontWeight: "1000",
										color: "#1CA6AF",
										marginBottom:"1em"
									}}>
									{t("empresa.historial1.historialSuscripciones")}
								</h2>
								<img src="img/sin-datos.svg" className="img-historial" style={{width:"40%"}}></img>
							</div>
						
						</>
					)}
				</div>	</div>
		
		</>
	);
};
