import { Tooltip, Typography, Modal } from "antd";
import axios from "axios";
import { useState, useMemo, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import {
  traerDatos,
  HeadersAuth,
  traerDatosObjeto,
  CheckExpiracion,
} from "../../funciones_utiles";
import { validarTextoNumerico } from "../../validaciones";
import { FaCheckCircle } from "react-icons/fa";
import { Tabs } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { FaQuestionCircle } from "react-icons/fa";
const { Text } = Typography;
const MySwal = withReactContent(Swal);

const URI_PAISES = `${process.env.REACT_APP_DOMINIO}${process.env.REACT_APP_PUERTO}/paises`;
const URI_PROVINCIAS = `${process.env.REACT_APP_DOMINIO}${process.env.REACT_APP_PUERTO}/provincias`;
const URI_RUBROS = `${process.env.REACT_APP_DOMINIO}${process.env.REACT_APP_PUERTO}/rubros`;
const URI_API_DOC = `${process.env.REACT_APP_URL_DOCUMENTACION_API}`

export const CompDatosEmpresa = ({ id_empresa, rol }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesProvincias, setOpcionesProvincias] = useState([]);
  const [opcionesRubros, setOpcionesRubros] = useState([]);
  const [tempDatosEmpresa, setTempDatosEmpresa] = useState({});
  const [previewLogo, setPreviewLogo] = useState(null);
  const inputFileRef = useRef(null);
  const [dataApi, setDataApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiUpdated, setApiUpdated] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState({
    razon_social: false,
    logo: false,
  });
  const [errores, setErrores] = useState({
    razon_social: "",
    logo: "",
  });
  const campos = [{ name: "razon_social", validator: validarTextoNumerico }];
  const URI_UPDATE_EMPRESA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/update_empresa?id=" +
    id_empresa;
  const URI_DATOS_EMPRESA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_empresa?id=" +
    id_empresa;
  const URI_DATOS_API =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/data_api_key?id=" +
    id_empresa;
  const URI_GESTION_API =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/gestionar_api_key?id=" +
    id_empresa;

  useMemo(() => {
    (async () => {
      setOpcionesPaises(await traerDatos(URI_PAISES));
      setOpcionesRubros(await traerDatos(URI_RUBROS));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setTempDatosEmpresa(await traerDatosObjeto(URI_DATOS_EMPRESA));
      const apiData = await traerDatos(URI_DATOS_API);
      setDataApi(apiData);
      setLoading(false);
    })();
  }, [URI_DATOS_EMPRESA, apiUpdated]);

  useEffect(() => {
    (async () => {
      setPreviewLogo(tempDatosEmpresa.logo);
    })();
  }, [tempDatosEmpresa.logo]);

  useEffect(() => {
    (async () => {
      setOpcionesProvincias(
        await traerDatos(`${URI_PROVINCIAS}?id=${tempDatosEmpresa.pais}`)
      );
    })();
  }, [tempDatosEmpresa.pais]);

  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  const maxFileSize = 20 * 1024 * 1024; // 20 MB

  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      setErrores({
        ...errores,
        logo: t("mensajes.mensaje50"),
      });
      setTooltipVisible({ ...tooltipVisible, logo: true });
      return;
    }

    if (file.size > maxFileSize) {
      setErrores({ ...errores, logo: t("mensajes.mensaje51") });
      setTooltipVisible({ ...tooltipVisible, logo: true });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        logo: reader.result,
      });
      setPreviewLogo(reader.result);
      setErrores({ ...errores, logo: "" });
      setTooltipVisible({ ...tooltipVisible, logo: false });
    };
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === "pais") {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        provincia_estado: "",
        [id]: value,
      });
    } else {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        [id]: value,
      });
    }
  };

  const gestionAPI = async (e) => {
    e.preventDefault();

    const gestionApiEndpoint = await axios.get(URI_GESTION_API, HeadersAuth);

    if (gestionApiEndpoint.data.code === 409) {
      CheckExpiracion();
    } else if (gestionApiEndpoint.data.code === 401) {
      MySwal.fire({
        html: t("mensajes.mensaje82"),
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else if (gestionApiEndpoint.data.code === 403) {
      MySwal.fire({
        html: t("mensajes.mensaje82"),
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else if (gestionApiEndpoint.data.code === 200) {
      MySwal.fire({
        text:
          gestionApiEndpoint.data.message === "api_key_generada"
            ? t("mensajes.mensaje79") + gestionApiEndpoint.data.api_key
            : gestionApiEndpoint.data.message === "api_key_activada"
            ? t("mensajes.mensaje80")
            : t("mensajes.mensaje81"),
        imageUrl: "img/guardado-exitoso.svg",
        imageHeight: 65,
        confirmButtonText: "Ok",
      });
    }
    setApiUpdated((prev) => !prev);
  };

  const guardar = async (e) => {
    e.preventDefault();

    let hayErrores = false;
    const erroresTemp = {};

    for (const campo of campos) {
      const valorCampo = String(tempDatosEmpresa[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: true,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      const registro = await axios.post(
        URI_UPDATE_EMPRESA,
        tempDatosEmpresa,
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        const msgError = errores.map((error) => {
          return (
            "<pre>" +
            (currentLanguage === "es" ? error.msg.ES : error.msg.EN) +
            "</pre>"
          );
        });
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje7"),
              imageUrl: "img/guardado-exitoso.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
          }
        }
      }
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [showApiKey, setShowApiKey] = useState(false);

  const toggleShowApiKey = (e) => {
    e.preventDefault();
    setShowApiKey(!showApiKey);
  };

  const handleCopyApiKey = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(dataApi.api_data?.api_key).then(() => {
      MySwal.fire({
        text: "API key copiada",
        icon: "success",
        confirmButtonText: "Ok",
      });
    });
  };

  return (
    <>
      <form onSubmit={guardar}>
        <div
          className="row container-fluid justify-content-center align-items-center"
          style={{ marginBottom: "2rem" }}
        >
          <div
            className="card-empresa p-3"
            style={{ height: "auto", width: "40em", maxWidth: "100%" }}
          >
            <div className="row">
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  marginBottom: "0.5em",
                }}
              >
                <h5 className="texto-suscripcion">
                  {t("empresa.datosEmpresa.rubro")}
                </h5>
                <h5
                  className="texto-suscripcion"
                  style={{ marginRight: "0.4em" }}
                >
                  {currentLanguage === "es"
                    ? tempDatosEmpresa.nombre_rubro
                    : tempDatosEmpresa.nombre_rubro_en}
                </h5>
              </div>
            </div>
            <div className="row">
              {/* Columna del nombre de la empresa */}
              <div className="col-12 col-md-6 mb-3">
                <label className="select_lista">
                  {t("empresa.datosEmpresa.razonSocial")}
                </label>
                <Tooltip
                  title={
                    !!errores.razon_social && (
                      <span>{errores.razon_social}</span>
                    )
                  }
                  open={tooltipVisible.razon_social}
                  color="#1CA6AF"
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("registro.nombre")}
                    id="razon_social"
                    value={tempDatosEmpresa.razon_social || ""}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Tooltip>
              </div>

              {/* Columna del logo */}
              <div className="col-12 col-md-6 d-flex flex-column align-items-center">
                <label className="select_lista text-start">
                  {t("empresa.datosEmpresa.logo")}
                </label>
                <div className="mt-0">
                  <Tooltip title={t("tooltip.cambiarLogo")}>
                    <label
                      htmlFor="archivo"
                      className="boton-imagen card p-1 d-flex position-relative"
                      style={{ width: "12em" }}
                    >
                      <img
                        className="imagen-empresa"
                        src={previewLogo || ".././img/empresa_nuevo.png"}
                        alt="imagen preview de empresa"
                        style={{ maxWidth: "100%" }}
                      />
                      <input
                        id="archivo"
                        ref={inputFileRef}
                        type="file"
                        name="photo"
                        accept=".jpg,.jpeg,.png,.gif"
                        onChange={handleLogoChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-ms-12 text-start contenedor-btn-empresa">
              {/* Botón de guardar empresa */}
              <button
                className="btn-checkout "
                type="submit"
                style={{
                  border: "none",
                  borderRadius: "0.5em",
                  padding: "0.3em",
                }}
              >
                {t("botones.guardarEmpresa")}
              </button>
            </div>
          </div>
        </div>
        <div
          className="row container-fluid justify-content-center align-items-center container-apy text-center text-sm-start"
          style={{ marginBottom: "2rem" }}
        >
          {loading ? (
            "Loading..."
          ) : dataApi ? (
            dataApi.api_data?.habilitada ? (
              <div
                className="card-empresa p-3"
                style={{ height: "auto", width: "40em", maxWidth: "100%" }}
              >
                {" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h3 className="texto-apy" style={{ fontSize: "1em" }}>
                    {t("empresa.datosEmpresa.integracionApy")}
                  </h3>
                  <FaQuestionCircle
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      color: "red",
                      fontSize: "1em",
                      marginBottom: "5px",
                    }}
                    onClick={showModal}
                  />
                </div>
                <Modal
                  className="modal-apy"
                  title={t("empresa.datosEmpresa.apy")}
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText={t("botones.aceptar")}
                  cancelText={t("botones.cancelar")}
                  cancelButtonProps={{ className: "custom-cancel-button" }}
                >
                  <div className="row">{t("empresa.datosEmpresa.infoApy")}</div>
                  <a
                    href={URI_API_DOC}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{fontSize:"1.2em", color:"#176585", fontWeight:"600"}}
                  >
                    {t("empresa.datosEmpresa.textoLink")}
                  </a>
                </Modal>
                {dataApi.api_data?.api_key === "not_created" ? (
                  <h3 className="texto-apy">
                    {t("empresa.datosEmpresa.apyKey")}
                  </h3>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3 className="texto-apy">
                      {dataApi.api_data?.api_key === "not_active" ? (
                        t("mensajes.mensaje81")
                      ) : (
                        <>
                          <span
                            className="icono-eye"
                            style={{ fontSize: "0.8em" }}
                          >
                            {showApiKey
                              ? dataApi.api_data?.api_key
                              : "• • • • • • • • • • • • • • • • • • • • • • • •"}
                          </span>
                          <button
                            onClick={toggleShowApiKey}
                            style={{
                              border: "none",
                              background: "none",
                              marginLeft: "0.5em",
                              cursor: "pointer",
                            }}
                          >
                            {showApiKey ? (
                              <EyeInvisibleOutlined className="icono-eye" />
                            ) : (
                              <EyeOutlined className="icono-eye" />
                            )}
                          </button>
                        </>
                      )}
                    </h3>
                    {dataApi.api_data?.active && (
                      <button
                        onClick={handleCopyApiKey}
                        style={{
                          border: "none",
                          background: "none",
                          marginLeft: "1em",
                          cursor: "pointer",
                          marginBottom: "0.5em",
                        }}
                      >
                        <CopyOutlined className="icono-eye" />
                      </button>
                    )}
                  </div>
                )}
                <button
                  className="btn-checkout"
                  type="submit"
                  onClick={(e) => gestionAPI(e)}
                  style={{
                    border: "none",
                    borderRadius: "0.5em",
                    padding: "0.3em",
                  }}
                >
                  {dataApi.api_data?.api_key === "not_created"
                    ? t("botones.generarApy")
                    : dataApi.api_data?.active
                    ? t("botones.desactivarApy")
                    : t("botones.activarApy")}
                </button>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </form>
    </>
  );
};
