import React from "react";
import { useState } from "react";
import { List, Row, Switch, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import { HeadersAuth } from "../../funciones_utiles";
import { useTranslation } from "react-i18next";
export const CalculadorasModalCard = ({
	listaCalculadora,
	titulo,
	alcance,
	rolUsuario,
	empresaActiva,
	switcheado,
	setSwitcheado,
}) => {
	const [t, i18n] = useTranslation("global");
	const currentLanguage = i18n.language;

	const [nombreFiltro, setNombreFiltro] = useState("");

	const handleNombreChange = (e) => {
		setNombreFiltro(e.target.value);
	};

	const filteredCalculadoras = listaCalculadora
		.filter((option) => option.alcance === alcance)
		.filter((option) =>
			nombreFiltro === ""
				? true
				: option.nombre.toLowerCase().includes(nombreFiltro.toLowerCase())
		);
	const switchear = async () => {
		if (switcheado === "") {
			setSwitcheado("switcheado");
		} else {
			setSwitcheado("");
		}
	};
	const onChangeSwitch = async (id, checked) => {
		let type_action;
		if (checked) {
			type_action = "crear";
		} else {
			type_action = "borrar";
		}
		const URI_ACTIVAR_CALCULADORA =
			process.env.REACT_APP_DOMINIO +
			process.env.REACT_APP_PUERTO +
			"/activo_calculadoras?id=" +
			empresaActiva;
		const datosAction = {
			id_calculadora: id,
			type_action: type_action,
		};
		const activar = await axios.post(
			URI_ACTIVAR_CALCULADORA,
			datosAction,
			HeadersAuth
		);
		switchear();
		// console.log("data: " + activar.data.code);
	};

	return (
		<div className="row justify-content-left" style={{ marginLeft: "0.1em" }}>
			<div className="perfil row g-1 justify-content-between ms-2 me-2">
				<div className="col-md-6 col-input-calculadoras">
					<h4>
						<b>{titulo}</b>
					</h4>
				</div>
				<Input
					addonAfter={<SearchOutlined />}
					className="px-2"
					placeholder={t("panelCalculadoras.buscarPorNombre")}
					onChange={handleNombreChange}
					style={{ width: 200 }}
				/>
			</div>
			<List
				itemLayout="horizontal"
				dataSource={filteredCalculadoras}
				renderItem={(item) => (
					<List.Item
						style={
							item.id_empresa === null ? { backgroundColor: "#f7f3cf", paddingLeft: "1rem" } : {paddingLeft: "1rem" }
						}
						actions={[
							<Row className="d-flex justify-content-center px-3">
							<Switch
								size="small"
								id={item.id}
								onChange={(checked) => onChangeSwitch(item.id, checked)}
								defaultChecked={item.id_empresa !== null}
								className={
									item.id_empresa !== null
										? "active-switch"
										: "inactive-switch"
								}></Switch>
							{item.id_empresa !== null && (
								<img
									className="active-image"
									src="./img/activo.png"
									alt="Activado"
								/>
							)}
							{item.id_empresa === null && (
								<img
									className="inactive-image"
									src="./img/inactivo.png"
									alt="Desactivado"
								/>
							)}
						</Row>
						]}>
						<List.Item.Meta
							title={
								<div className="d-flex justify-content-start">
									{currentLanguage ===  "es" ? item.nombre : item.nombre_en}
								</div>
							}
							description={parseInt(rolUsuario) === 2
								? (currentLanguage ===  "es" ? item.mensaje : item.mensaje_en)
								: (currentLanguage ===  "es" ? item.mensaje_particular : item.mensaje_particular_en)}
						/>
					</List.Item>
				)}
			/>
			
		</div>
	);
};
