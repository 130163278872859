import './App.css';
import './styles.scss';
import { CompRutas } from './navegacion/rutas';
import axios from "axios"
import { CheckExpiracion } from './funciones_utiles';
import {useEffect} from 'react'

function App() {


axios.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('valid_session')}`;
          return config;
      },
      error => {
          return Promise.reject(error);
      }
)
    return ( 
      <div className="App">           
        <CompRutas />    
      </div>
  );
}

export default App;
