import React from "react";
import { Doughnut } from "react-chartjs-2";
import randomColor from "randomcolor";
import { Chart, registerables } from "chart.js";
import { useTranslation } from "react-i18next";

Chart.register(...registerables);

export const ChartCompensacion = React.memo(({ data, anio }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const datasets = {
    compensado: {
      label: currentLanguage === 'es' ? 'Compensado' : 'Compensated',
      data: data.map(item => item.porcentaje_compensado),
      backgroundColor: randomColor({ hue: "#508991", luminosity: "dark", format: "hex" }),
    },
    sin_compensar: {
      label: currentLanguage === 'es' ? 'Sin compensar' : 'Uncompensated',
      data: data.map(item => item.porcentaje_sin_compensar),
      backgroundColor: randomColor({ hue: "#D79824", luminosity: "bright", format: "hex" }),
    },
  };

  function obtenerPorcentajesPorAnio(anio) {
    const resultado = data.find(dato => dato.anio === anio);
    if (resultado) {
      return {
        porcentaje_compensado: resultado.porcentaje_compensado,
        porcentaje_sin_compensar: resultado.porcentaje_sin_compensar
      };
    } else {
      return { porcentaje_compensado: 0, porcentaje_sin_compensar: 0 };
    }
  }
  const porcentajes = obtenerPorcentajesPorAnio(anio);

  const chartData = {
    labels: [currentLanguage === 'es' ? 'Compensado' : 'Compensated', 
             currentLanguage === 'es' ? 'Sin compensar' : 'Uncompensated'],
    datasets: [{
      data: [porcentajes.porcentaje_compensado, porcentajes.porcentaje_sin_compensar],
      backgroundColor: [
        datasets.compensado.backgroundColor,
        datasets.sin_compensar.backgroundColor,
      ],
    }],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: currentLanguage === 'es' ? "Compensaciones de CO2" : "CO2 Offsets",
        font: {
          size: 16,
        },
      },
    },
  };

  return (
    <Doughnut   style={{
      height: "300px", 
      width: "300px", 
    }} data={chartData} options={chartOptions} />
  );
});


