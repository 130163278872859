import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const PagoCheckFinal = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <div className="main regsim">
        <div className="row menu-wrapper justify-content-center align-items-center">
          <div className="col-xl-4 col-md-12  logo-container text-left">
            <img
              src="./img/logo-menu.png"
              alt=""
              className="img_logo img-fluid"
              style={{ width: "10em" }}
            />
          </div>

          <div className="col-xl-4 col-md-12 menu-container text-center">
            <ul className="ul-nuevo">
              <li className="li-nuevo">
                <a
                  href="https://owl-track.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                   {t("botones.inicio")}
                </a>
              </li>
              <li className="li-nuevo">
                <a
                  href="https://owl-track.com/blog/"
                  rel="noreferrer"
                  target="_blank"
                >
                 {t("botones.noticias")}
                </a>
              </li>
              <li className="li-nuevo">
                <a
                  href="https://owl-track.com/contacto/"
                  rel="noreferrer"
                  target="_blank"
                >
                {t("botones.contacto")}
                </a>
              </li>
              <li className="li-nuevo">
                <a href="/signup" rel="noreferrer" target="_blank">
                {t("botones.calculadora")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-4 col-transparente d-none d-sm-block"></div>
        </div>
        <div className="contenedor-error mt-5 text-center position-relative">
          <div className="d-flex flex-column align-items-center justify-content-center">
            {/* Pseudo-elemento para el círculo */}
            <div className="circulo">
              <img
                src="./img/registro-exitoso.svg"
                alt="Descripción"
                className="imagen-circulo"
              />
            </div>
            <h1 className="titulo-error">
            {t("registro.texto2")}
            </h1>
            <Link
              to="/signin"
              className="btn-checkout boton-select d-flex align-items-center justify-content-center mt-3"
              style={{
                padding: "0.5rem",
                borderRadius: "0.8em",
                paddingLeft: "1em",
                paddingRight: "1em",
              }}
            >
             {t("botones.IrAlLogin")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
