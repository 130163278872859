export const BtnFlotante = () => {
  return (
    <button
      onClick={() => window.open("/faq", "_blank")}
      className="btnflotante"
    >
      <div className="image-container">
        <img src="" alt="My icon of a questiong sing" />
      </div>
    </button>
  );
};
