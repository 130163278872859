import { BtnAtras } from "../navegacion/btn_atras";
import { Card, List, Table, Checkbox, Input, Avatar } from "antd";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { Link } from "react-router-dom";
import { ChartCompensacion } from "../charts/ChartCompensacion";
import { ChartCompensadasPendientes } from "../charts/ChartCompensacionPendiente";
import { ChartEmisionesCompensadasPorProyecto } from "../charts/ChartEmisionesPorProyecto";
import { ChartEmisionesCertificado } from "../charts/chartEmisionesCertificadas";
import { NotFound404 } from "../navegacion/404_not_found";
import { HeadersAuth } from "../funciones_utiles";
import { ModalProyecto } from "./modalProyecto";
import { fNum, traerDatos, CheckExpiracion } from "../funciones_utiles";
import { Tooltip, Modal } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React, { useState, useEffect } from "react";
import { useSesion } from "../navegacion/sesionContexto";
import {
  HourglassOutlined,
  CheckOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FaQuestionCircle, FaEye, FaLeaf } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { LoadMePlease } from "../navegacion/pantalla_carga";
const MySwal = withReactContent(Swal);

const MemoizedChartAnios = React.memo(ChartCompensacion);

export const CompPanelCompensar = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const { sesion } = useSesion();
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(sesion.empresaActiva)
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const URI_DATOS_REPORTE =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_reporte";
  const URI_DATOS_REPORTE_MACRO =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_reporte_macro";
  const URI_PROYECTOS =
    process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/proyectos";

  var [year, setYear] = useState("");

  const [dataReportes, setDataReportes] = useState({});
  const [dataCompensaciones, setDataCompensaciones] = useState([]);
  // const [dataPdfs, setDataPdfs] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [elejirProyecto, setElejirProyecto] = useState();
  const [trigger, setTrigger] = useState(false);
  const [losProyectos, setLosProyectos] = useState();
  const [hayDatos, setHayDatos] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [filteredList, setFilteredList] = useState();
  const [optionsYears, setOptionYears] = useState([]);
  const [modalId, setModalId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cantidadProyectos, setCantidadProyectos] = useState(0);

  useEffect(() => {
    const handleStart = () => setIsLoading(true);
    const handleComplete = () => setIsLoading(false);
    let timeout;
    handleStart();

    // Verifica si 'datos_reportes_mes' tiene datos
    if (year && dataReportes?.datos_reportes_mes?.length > 0) {
      // handleComplete();
      timeout = setTimeout(handleComplete, 500); // Simula un tiempo de carga
    }
    return () => clearTimeout(timeout);
  }, [year, dataReportes]);

  // const [seccion, setSeccion] = useState("");
  const handleChangeAnio = (value) => {
    setYear(value);
  };
  const [selectedYear, setSelectedYear] = useState(2024);

  // Función para manejar el cambio del select
  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };
  const añosDisponibles = Array.from(
    new Set(dataChart.map((item) => item.anio))
  );
  useEffect(() => {
    (async () => {
      try {
        const datosMacro = await axios.post(
          URI_DATOS_REPORTE_MACRO,
          {
            id: idEmpresa,
            id_sede: idSede,
          },
          HeadersAuth
        );

        // Verifica si hay datos de emisiones para el año
        if (datosMacro.data.emisiones_anio.length > 0) {
          setHayDatos(true); // Si hay datos, lo marcamos como verdadero
          setOptionYears(datosMacro.data.emisiones_anio); // Establece los años disponibles

          const chartData = datosMacro.data.compensaciones.map((item) => ({
            anio: item.anio,
            compensado: item.compensado,
            sin_compensar: item.sin_compensar,
            porcentaje_compensado: item.porcentaje_compensado,
            porcentaje_sin_compensar: item.porcentaje_sin_compensar,
          }));

          setDataChart(chartData);

          // Si el año 2024 no está en los datos disponibles, selecciona el primer año de los datos como predeterminado
          if (!chartData.some((item) => item.anio === 2024)) {
            setSelectedYear(chartData[0].anio);
          }
        } else {
          setHayDatos(false); // Si no hay datos, lo marcamos como falso
        }
      } catch (error) {}
    })();
  }, [idEmpresa, URI_DATOS_REPORTE_MACRO, idSede]);

  useEffect(() => {}, [dataChart]);
  useEffect(() => {
    (async () => {
      const reportes = await axios.post(
        URI_DATOS_REPORTE,
        {
          anio: year,
          id: idEmpresa,
          id_sede: idSede,
        },
        HeadersAuth
      );

      const URI_COMPENSADAS =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/datos_compensaciones_realizadas?id=" +
        idEmpresa +
        "&ids=" +
        idSede +
        "&anio=" +
        year;

      setDataCompensaciones(await traerDatos(URI_COMPENSADAS));
      setDataReportes(reportes.data);

      const proyectos = await traerDatos(URI_PROYECTOS);
      setLosProyectos(proyectos);
      setCantidadProyectos(proyectos.length);
    })();
  }, [
    year,
    idEmpresa,
    elejirProyecto,
    trigger,
    URI_DATOS_REPORTE,
    URI_PROYECTOS,
    idSede,
  ]);

  useEffect(() => {
    setFilteredList((prevFilteredList) => {
      if (inputValue) {
        return prevFilteredList.filter(
          (item) =>
            item.nombre
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        );
      } else {
        return losProyectos;
      }
    });
  }, [inputValue, losProyectos]);

  function onSearch(e) {
    setInputValue(e.target.value);
  }

  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.mes")}</span>
        </div>
      ),
      key: "mes",
      align: "center",
      sorter: (a, b) => a.mes - b.mes,
      render: (a) => {
        if (currentLanguage === "es") {
          moment.locale("es"); // Configurar idioma español
        } else {
          moment.locale("en"); // Configurar idioma inglés
        }
        return moment(`${a.mes}`, "MM").format("MMM");
      },
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.sinCompensar")}</span>
        </div>
      ),
      key: "sin_compensar",
      align: "right",
      sorter: (a, b) => a.tcodose_no_compensadas - b.tcodose_no_compensadas,
      render: (a) => (
        <>
          {fNum(a.tcodose_no_compensadas)} tCO<sub>2eq</sub>
        </>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.estados.pendiente")}</span>
        </div>
      ),
      key: "pendiente",
      align: "right",
      sorter: (a, b) => a.tcodose_pendiente - b.tcodose_pendiente,
      render: (a) => (
        <>
          {fNum(a.tcodose_pendiente)} tCO<sub>2eq</sub>
        </>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.estados.compensado")} </span>
        </div>
      ),
      key: "compensado",
      align: "right",
      sorter: (a, b) => a.tcodose_compensadas - b.tcodose_compensadas,
      render: (a) => (
        <>
          {fNum(a.tcodose_compensadas)} tCO<sub>2eq</sub>
        </>
      ),
    },
  ];

  const ColumnsCompensaciones = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {t("compensacion.tabla.mes")}
          </span>
        </div>
      ),
      key: "mes",
      align: "center",
      sorter: (a, b) => a.mes - b.mes,
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {moment(`${a.mes}`, "MM").format("MMM")}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.9em" }}>
            {t("compensacion.tabla.proveedor")}
          </span>
        </div>
      ),
      key: "proveedor",
      align: "left",
      sorter: (a, b) => a.compensadora.localeCompare(b.compensadora),
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>{a.compensadora}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.9em" }}>
            {t("compensacion.tabla.proyecto")}
          </span>
        </div>
      ),
      key: "proyecto",
      align: "left",
      sorter: (a, b) => a.nombre_proyecto.localeCompare(b.nombre_proyecto),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>{a.nombre_proyecto}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.cantidad")}</span>
        </div>
      ),
      key: "cantidad",
      align: "right",
      sorter: (a, b) => a.cantidad - b.cantidad,
      render: (a) => (
        <span
          style={{ fontSize: "0.9em" }}
          dangerouslySetInnerHTML={{
            __html: fNum(a.cantidad) + " tCO<sub>2eq</sub>",
          }}
        />
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.certificado")}</span>
        </div>
      ),
      key: "tipo_certificado",
      align: "left",
      sorter: (a, b) => a.tipo_certificado.localeCompare(b.tipo_certificado),
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {a.tipo_certificado || "Sin certificado"}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.importe")}</span>
        </div>
      ),
      key: "importe",
      align: "right",
      sorter: (a, b) => a.importe - b.importe,
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>{`${fNum(a.importe)} USD`}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.paisDestino")}</span>
        </div>
      ),
      key: "pais",
      align: "left",
      sorter: (a, b) => a.pais.localeCompare(b.pais),
      render: (a) => <span style={{ fontSize: "0.9em" }}>{a.pais}</span>,
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {t("compensacion.tabla.estado")}
          </span>
        </div>
      ),
      key: "estado",
      align: "left",
      sorter: (a, b) => a.estado.localeCompare(b.estado),
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {currentLanguage === "es" ? a.estado : a.estado_en}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.fecha")}</span>
        </div>
      ),
      key: "fecha",
      align: "left",
      sorter: (a, b) =>
        moment(a.fecha_pedido, "YYYY-MM-DD") -
        moment(b.fecha_pedido, "YYYY-MM-DD"),
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {parseInt(a.estado_num) !== 3
            ? moment(a.fecha_pedido, "YYYY-MM-DD").format("DD/MM/YYYY")
            : moment(a.fecha_compensacion, "YYYY-MM-DD").format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: "",
      key: "botones",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {parseInt(dato.estado_num) === 2 ? (
              <button
                className="boton-vehiculos-nuevo justify-content-center"
                value={dato.id}
                onClick={cancelarCompensacion}
              >
                <HourglassOutlined />
              </button>
            ) : (
              <CheckOutlined />
            )}
          </div>
        </div>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filasSeleccionadas, setFilasSeleccionadas] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sumaTcodoseNoCompensadas, setSumaTcodoseNoCompensadas] = useState();

  const toggleSelectAll = () => {
    const nuevasFilasSeleccionadas =
      selectedRowKeys.length === filteredData.length
        ? []
        : filteredData.map((r) => r.mes);
    setSelectedRowKeys(nuevasFilasSeleccionadas);
    const objetosFilasSeleccionadas = filteredData.filter((r) =>
      nuevasFilasSeleccionadas.includes(r.mes)
    );
    setFilasSeleccionadas(objetosFilasSeleccionadas);
  };
  const headerCheckbox = (
    <>
      <Checkbox
        id="switchAll"
        size="small"
        checked={selectedRowKeys.length}
        indeterminate={
          selectedRowKeys.length > 0 &&
          selectedRowKeys.length < filteredData.length
        }
        onChange={toggleSelectAll}
      ></Checkbox>
    </>
  );
  const rowSelection = {
    columnTitle: headerCheckbox,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setFilasSeleccionadas(selectedRows);
    },
  };
  useEffect(() => {
    if (dataReportes.datos_reportes_mes) {
      setFilteredData(
        dataReportes.datos_reportes_mes.length > 0
          ? dataReportes.datos_reportes_mes.filter(
              (record) => parseFloat(record.tcodose_no_compensadas) !== 0
            )
          : []
      );
    }
  }, [dataReportes.datos_reportes_mes]);

  // const [isLoading, setIsLoading] = useState(true);

  const cancelarCompensacion = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    const objetoCancelar = {
      id_compensacion: value,
    };
    let validarCompensar = MySwal.fire({
      title: t("mensajes.mensaje17"),
      text: ``,
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });
    if ((await validarCompensar).isConfirmed) {
      const URI_CANCELAR_COMPENSAR =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/cancelar_compensacion_mensual?id=" +
        idEmpresa +
        "&ids=" +
        idSede;
      const compensar = await axios.post(
        URI_CANCELAR_COMPENSAR,
        objetoCancelar,
        HeadersAuth
      );
      if (compensar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else if (compensar.data.code === 401) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (compensar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (compensar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje18"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
          }
          trigger ? setTrigger(false) : setTrigger(true);
        }
      }
    }
  };
  useEffect(() => {
    setSumaTcodoseNoCompensadas(
      filasSeleccionadas.reduce((acumulador, filasSeleccionadas) => {
        return acumulador + filasSeleccionadas.tcodose_no_compensadas;
      }, 0)
    );
  }, [filasSeleccionadas]);

  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    const listaTab = [];

    if (dataReportes.datos_reportes) {
      dataReportes.datos_reportes.forEach((reporte) => {
        const nombre = reporte.nombre;
        const nombre_en = reporte.nombre_en;
        const datos = reporte.datos;

        if (datos && datos.length > 0) {
          const key = `grafico_${nombre.toLowerCase().replace(/\s/g, "_")}`;

          listaTab.push({
            key: key,
            tab: (
              <b className="subtitulo-grafico">
                {currentLanguage === "es" ? nombre : nombre_en}{" "}
                <img
                  src="./img/img_activada.png"
                  alt=""
                  className="img-fluid img-tabla"
                />
              </b>
            ),
            disabled: false,
          });

          <ChartCompensacion dataCompensaciones={datos} year={year} />;
        }
      });
    }
  }, [dataReportes, year, sesion.empresaActiva, sesion.sedeActiva]);

  const compensarUno = (e) => {
    e.preventDefault();
    if (!sesion.checkPerfil || !sesion.checkSede) {
      let mensajePerfil;
      let mensajeSede;

      if (sesion.checkPerfil) {
        mensajePerfil = "";
      } else {
        mensajePerfil = t("mensajes.mensaje74");
      }
      if (sesion.checkSede) {
        mensajeSede = "";
      } else {
        if (sesion.duenio) {
          mensajeSede = t("mensajes.mensaje75");
        } else {
          mensajeSede = t("mensajes.mensaje76");
        }
      }

      MySwal.fire({
        html: `
                  ${mensajePerfil}</br>${mensajeSede}
                  
                  `,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      setElejirProyecto(true);
    }
  };
  const atrasCompensar = (e) => {
    e.preventDefault();
    setElejirProyecto();
  };
  const verProyecto = (e, id) => {
    e.preventDefault();
    setModalId(id);
    setIsModalVisible(true);
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const compensarFinal = async (e, id_proyecto) => {
    e.preventDefault();
    if (!sesion.checkPerfil || !sesion.checkSede) {
      let mensajePerfil;
      let mensajeSede;

      if (sesion.checkPerfil) {
        mensajePerfil = "";
      } else {
        mensajePerfil = t("mensajes.mensaje74");
      }
      if (sesion.checkSede) {
        mensajeSede = "";
      } else {
        if (sesion.duenio) {
          mensajeSede = t("mensajes.mensaje75");
        } else {
          mensajeSede = t("mensajes.mensaje76");
        }
      }

      MySwal.fire({
        html: `
                  ${mensajePerfil}</br>${mensajeSede}
                  
                  `,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      let objetoCompensar = {
        listaMeses: filasSeleccionadas,
        sumaTotalToneladas: sumaTcodoseNoCompensadas,
        id_proyecto: id_proyecto,
        anio: year,
      };
      let validarCompensar = MySwal.fire({
        title: t("mensajes.mensaje32", {
          cantidad: fNum(sumaTcodoseNoCompensadas),
          unidad: t("tablas.toneladas2"),
        }),
        text: ``,
        imageUrl: "img/mensajes/resultado.svg",
        imageHeight: 65,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("botones.aceptar"),
        cancelButtonText: t("botones.cancelar"),
      });

      if ((await validarCompensar).isConfirmed) {
        const URI_COMPENSAR =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/nueva_compensacion?id=" +
          idEmpresa +
          "&ids=" +
          idSede;
        const compensar = await axios.post(
          URI_COMPENSAR,
          objetoCompensar,
          HeadersAuth
        );
        if (compensar.data.code === 402) {
          MySwal.fire({
            text: t("mensajes.mensaje12"),
            imageUrl: "img/lechuza-triste.png",
            imageHeight: 55,
            confirmButtonText: "Ok",
          });
        } else if (compensar.data.code === 401) {
          MySwal.fire({
            text: t("mensajes.mensaje12"),
            imageUrl: "img/lechuza-triste.png",
            imageHeight: 55,
            confirmButtonText: "Ok",
          });
        } else {
          if (compensar.data.code === 409) {
            CheckExpiracion();
          } else {
            if (compensar.data.code === 200) {
              MySwal.fire({
                text: t("mensajes.mensaje34"),
                imageUrl: "img/mensajes/resultado.svg",
                imageHeight: 50,
                confirmButtonText: "Ok",
              });
            }
            setElejirProyecto();
            setSumaTcodoseNoCompensadas();
            setFilasSeleccionadas([]);
            trigger ? setTrigger(false) : setTrigger(true);
          }
        }
      }
    }
  };
  return (
    <>
      {year === "" ? (
        !hayDatos ? (
          <BtnAtras titulo={"Upss!"} />
        ) : (
          <BtnAtras titulo={t("compensacion.general.compensaciones")} />
        )
      ) : (
        <BtnAtras
          titulo={`${t("compensacion.general.compensaciones")} ${year}`}
        />
      )}
      {plan ? (
        hayDatos ? (
          // optionsYears.length > 0 ?
          <div className="perfil row d-flex justify-content-center align-items-center  mt-2 panel-reporte mb-5">
            <div className="row justify-content-center">
              {year === "" ? (
                <>
                  <div className="col-md-5 col-input-calculadoras">
                    <Card
                      bordered={true}
                      hoverable={true}
                      style={{ height: "25em", marginBottom: "1em" }}
                    >
                      <h2
                        style={{
                          fontSize: "1rem",
                          fontWeight: "1000",
                          color: "#1CA6AF",
                        }}
                      >
                        {t("reportes.general.elejirAño")}
                      </h2>
                      <List
                        pagination={{
                          size: "small",
                          pageSize: 3,
                          position: "top",
                          align: "end",
                        }}
                        itemLayout="horizontal"
                        dataSource={optionsYears}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <Tooltip
                                placement="left"
                                title={t("reportes.general.verReporte")}
                                className="tooltip-head"
                              >
                                <button
                                  style={{ border: "0", background: "none" }}
                                  onClick={() => handleChangeAnio(item.anio)}
                                >
                                  <EyeOutlined />
                                </button>
                              </Tooltip>,
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <b style={{ fontSize: "1.2rem" }}>
                                  {item.anio}
                                </b>
                              }
                              description={
                                <b>
                                  {parseFloat(item.total_codose).toFixed(2) +
                                    " tCO"}
                                  <sub>2eq</sub> {t("reportes.general.emitido")}
                                </b>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Card>
                  </div>
                  <div className="col-md-7 col-input-calculadoras">
                    <Card bordered={true} hoverable={true}>
                      <div>
                        <select
                          className="form-select-grafico"
                          value={selectedYear}
                          onChange={handleYearChange}
                          style={{ width: "100%" }}
                        >
                          {añosDisponibles.map((año) => (
                            <option key={año} value={año}>
                              {año}
                            </option>
                          ))}
                        </select>

                        <div>
                          <MemoizedChartAnios
                            data={dataChart}
                            anio={selectedYear}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </>
              ) : !isLoading ? (
                <>
                  <div className="col-md-12 ">
                    <section id="compensar" className="section ">
                      <div
                        style={{ display: "flex" }}
                      >
                        <button
                          className="btnprincipal p-1"
                          onClick={() => setYear("")}
                          style={{
                            margin: "0",
                            width: "9em",
                            height: "2.5em",
                            marginBottom: "1em",
                          }}
                        >
                          {t("reportes.general.cambiarAño")}
                        </button>
                      </div>

                      {sesion.duenio ? (
                        <>
                          <Card bordered={true} hoverable={true}>
                            <div className="row justify-content-center align-items-center">
                              <div className="col-md-12 justify-content-between">
                                <h2
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    color: "#1CA6AF",
                                  }}
                                >
                                  {t("compensacion.informacion.titulo")} {year}
                                  <Tooltip
                                    title={t("tooltip.ayuda")}
                                    placement="top"
                                  >
                                    <FaQuestionCircle
                                      style={{
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                        color: "red",
                                        fontSize: "1em",
                                        marginBottom: "5px",
                                      }}
                                      onClick={showModal}
                                    />
                                  </Tooltip>
                                </h2>
                                <Modal
                                  className="custom-modal"
                                  title={t("compensacion.general.compensacion")}
                                  open={isModalOpen}
                                  onOk={handleOk}
                                  onCancel={handleCancel}
                                  okText={t("botones.aceptar")}
                                  cancelText={t("botones.cancelar")}
                                  cancelButtonProps={{
                                    className: "custom-cancel-button",
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p style={{ marginLeft: "1em" }}>
                                        {" "}
                                        1 - {t("compensacion.ayuda.ayuda1")}
                                      </p>

                                      <img
                                        alt=""
                                        src="../img/ayuda5.png"
                                        className="img-ayuda"
                                      ></img>
                                    </div>
                                    <div className="col-md-6">
                                      <p style={{ marginLeft: "1em" }}>
                                        2 -{" "}
                                        {t("compensacion.ayuda.ayuda2parte1")}
                                        <FaEye className="mx-2" />
                                        {t("compensacion.ayuda.ayuda2parte2")}
                                        <FaLeaf className="mx-2" />
                                        {t("compensacion.ayuda.ayuda2parte3")}
                                      </p>
                                      <img
                                        alt=""
                                        src="../img/ayuda6.png"
                                        className="img-ayuda"
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p style={{ marginLeft: "1em" }}>
                                        {" "}
                                        3 - {t("compensacion.ayuda.ayuda3")}
                                      </p>

                                      <img
                                        alt=""
                                        src="../img/ayuda7.png"
                                        className="img-ayuda"
                                      ></img>
                                    </div>
                                    <div className="col-md-6">
                                      <p style={{ marginLeft: "1em" }}>
                                        {" "}
                                        4 - {t("compensacion.ayuda.ayuda4")}
                                      </p>
                                      <img
                                        alt=""
                                        src="../img/ayuda8.png"
                                        className="img-ayuda"
                                      ></img>
                                    </div>
                                  </div>
                                </Modal>
                                {filteredData.length > 0 ? (
                                  !elejirProyecto ? (
                                    <>
                                      {" "}
                                      <h2 className="texto-explicacion">
                                        {" "}
                                        {t(
                                          "compensacion.informacion.subtitulo"
                                        )}
                                      </h2>
                                      <Table
                                        rowKey="mes"
                                        rowSelection={{
                                          type: "checkbox",
                                          ...rowSelection,
                                        }}
                                        dataSource={filteredData}
                                        columns={Columns}
                                        scroll={elScroll}
                                        pagination={{
                                          position: ["bottomRight"],
                                          pageSize: 5,
                                        }}
                                        size="small"
                                      />
                                      <div className="justify-content-end text-end ml-auto">
                                        {filasSeleccionadas.length === 0 ? (
                                          <button
                                            className="btnprincipal p-2 mt-2"
                                            type="button"
                                            disabled
                                          >
                                            {t(
                                              "compensacion.general.compensar"
                                            )}
                                          </button>
                                        ) : (
                                          <button
                                            className="btnprincipal p-2 mt-2"
                                            onClick={compensarUno}
                                            type="button"
                                          >
                                            {t("botones.compensar")}{" "}
                                            {fNum(sumaTcodoseNoCompensadas)} t
                                          </button>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="d-flex justify-content-between"
                                        style={{ marginTop: "0.8em" }}
                                      >
                                        <h3 className="subtitulo-empresas">
                                          {" "}
                                          {cantidadProyectos}
                                          {t(
                                            "compensacion.informacion.titulo4"
                                          )}{" "}
                                        </h3>
                                        <div className="search-wrapper">
                                          <Input
                                            className=""
                                            placeholder={t("tooltip.nombre")}
                                            onChange={(e) => onSearch(e)}
                                            style={{
                                              width: 300,
                                              backgroundSize: "10%",
                                            }}
                                          />
                                        </div>
                                      </div>{" "}
                                      <List
                                        itemLayout="horizontal"
                                        dataSource={filteredList}
                                        className="lista-sedes"
                                        pagination={{
                                          size: "small",
                                          pageSize: 5,
                                        }}
                                        size="small"
                                        renderItem={(item) => (
                                          <List.Item
                                            actions={[
                                              <Tooltip
                                                placement="left"
                                                title={t(
                                                  "tooltip.visualizarProyecto"
                                                )}
                                                className="tooltip-head"
                                              >
                                                <button
                                                  className="boton-vehiculos"
                                                  onClick={(e) =>
                                                    verProyecto(e, item.id)
                                                  }
                                                >
                                                  <FaEye
                                                    style={{
                                                      fontSize: "1.4em",
                                                    }}
                                                  />
                                                </button>
                                              </Tooltip>,
                                              <Tooltip
                                                placement="left"
                                                title={t("botones.compensar")}
                                                className="tooltip-head"
                                              >
                                                <button
                                                  className="boton-vehiculos justify-content-end"
                                                  onClick={(e) =>
                                                    compensarFinal(e, item.id)
                                                  }
                                                >
                                                  <FaLeaf />
                                                </button>
                                              </Tooltip>,
                                            ]}
                                          >
                                            <List.Item.Meta
                                              avatar={
                                                <Avatar src=".././img/sedes.png" />
                                              }
                                              title={item.nombre}
                                              description={
                                                item.tipo +
                                                ", " +
                                                item.potencial +
                                                ", " +
                                                item.precio +
                                                "USD"
                                              }
                                            />
                                          </List.Item>
                                        )}
                                      />
                                      <button
                                        className="boton-resultado btn-atras-compensar"
                                        onClick={atrasCompensar}
                                        type="button"
                                      >
                                        {t("botones.atras")}
                                      </button>
                                      <ModalProyecto
                                        id={modalId}
                                        visible={isModalVisible}
                                        onClose={handleModalClose}
                                      />
                                    </>
                                  )
                                ) : (
                                  <div className="col-md-8 ">
                                    <h5>
                                      {t(
                                        "compensacion.informacion.emisionesSinCompensar"
                                      )}
                                    </h5>
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                          </Card>
                          <div className="col-xl-12 pt-4">
                            <Card bordered={true} hoverable={true}>
                              <div className="col-md-12 d-flex justify-content-between">
                                <h2
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    color: "#1CA6AF",
                                  }}
                                >
                                  {t("compensacion.general.compensaciones")}{" "}
                                  {year}
                                </h2>
                              </div>

                              {/* Mostrar tabla y gráficos solo si hay data */}
                              {dataCompensaciones.length > 0 ? (
                                <>
                                  <div className="row">
                                    <div className="col-md-9">
                                      <Card
                                        bordered={true}
                                        hoverable={true}
                                        style={{
                                          marginBottom: "0.8em",
                                        }}
                                      >
                                        <Table
                                          rowKey="id"
                                          dataSource={dataCompensaciones}
                                          columns={ColumnsCompensaciones}
                                          scroll={elScroll}
                                          pagination={{
                                            position: ["bottomRight"],
                                            pageSize: 5,
                                          }}
                                          size="small"
                                        />
                                      </Card>
                                    </div>

                                    <div className="col-md-3">
                                      <Card
                                        bordered={true}
                                        hoverable={true}
                                        style={{
                                          marginBottom: "0.8em",
                                        }}
                                      >
                                        <p>
                                          <ChartCompensadasPendientes
                                            data={dataCompensaciones}
                                          />
                                        </p>
                                      </Card>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-9">
                                      <Card
                                        bordered={true}
                                        hoverable={true}
                                        style={{
                                          flex: "0 0 30%",
                                          marginBottom: "1em",
                                        }}
                                      >
                                        <p>
                                          <ChartEmisionesCompensadasPorProyecto
                                            data={dataCompensaciones}
                                          />
                                        </p>
                                      </Card>
                                    </div>

                                    <div className="col-md-3">
                                      <Card
                                        bordered={true}
                                        hoverable={true}
                                        style={{
                                          flex: "0 0 30%",
                                          marginBottom: "1em",
                                        }}
                                      >
                                        <p>
                                          <ChartEmisionesCertificado
                                            data={dataCompensaciones}
                                          />
                                        </p>
                                      </Card>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <h5>
                                  {t(
                                    "compensacion.informacion.emisionesSinCompensar"
                                  )}
                                </h5>
                              )}
                            </Card>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </section>
                  </div>
                </>
              ) : (
                <LoadMePlease cargando={t("mensajes.mensaje69")} />
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="perfil row pb-3 g-3 justify-content-center ms-4 me-4">
              <img
                alt=""
                className="img-lechuza-triste"
                src="./img/lechuza_triste.png"
              />
            </div>
            <div className="perfil row g-3 justify-content-center ms-4 me-4">
              <h2
                className="d-flex justify-content-center"
                style={{
                  fontSize: "2rem",
                  fontWeight: "1000",
                  color: "#1CA6AF",
                }}
              >
                Usted no ha realizado ninguna medición aún!
              </h2>
              <Link
                to={"/calculadoras"}
                className="d-flex justify-content-center"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "1000",
                  color: "#1CA6AF",
                }}
              >
                Ir a calcular
              </Link>
            </div>
          </>
        )
      ) : (
        <NotFound404 />
      )}
    </>
  );
};
