import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navbar } from "../navegacion/Navbar";
import { Badge } from "antd";
import { SlUser, SlBell } from "react-icons/sl";
import { FaMapMarkerAlt } from "react-icons/fa";
import { UserOutlined, KeyOutlined, LogoutOutlined } from "@ant-design/icons";
import { CompConfigEmpresa } from "../gestion_usuarios/componentes_empresa/configuracionEmpresa";

import {
  RiLightbulbLine,
  RiLineChartFill,
  RiCalculatorLine,
  RiHomeHeartLine,
  RiBuilding4Line,
} from "react-icons/ri";
import { FaSeedling } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
const MySwal = withReactContent(Swal);

const URI =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/logout";

export const CompHead = ({ sesion }) => {
  const [t, i18n] = useTranslation("global");


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("preferredLanguage", lng);
  };
  const navigate = useNavigate();
  let objeto_notificacion;
  if (sesion.rol === 1 || sesion.rol === 4 || sesion.rol === 5) {
    objeto_notificacion = {
      perfil: sesion.checkPerfil,
    };
  } else {
    objeto_notificacion = {
      empresa: sesion.checkSede,
      perfil: sesion.checkPerfil,
      dias: sesion.checkDias,
    };
  }
  // console.log(sesion.infoSubscripcion)
  const valores = Object.values(objeto_notificacion);
  let notificacion;
  if (valores.includes(false)) {
    notificacion = false;
  } else {
    notificacion = true;
  }
  let contador_notificaciones = 0;
  // let extra_contador = 0;
  for (const propiedad in objeto_notificacion) {
    if (objeto_notificacion[propiedad] === false) {
      contador_notificaciones++;
    }
  }

  const logout = async (e) => {
    e.preventDefault();
    const logearse = await axios.get(URI, {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    if (logearse.data.code === 200) {
      MySwal.fire({
        text: t("mensajes.mensaje6"),
        imageUrl: "img/registro-exitoso.svg",
        imageHeight: 65,
        confirmButtonText: "Ok",
      });
      localStorage.removeItem("valid_session");
      navigate("/signin");
    }
  };

  return (
    <div className="container-header container-fluid ">
      <div className="row p-0 m-0">
        <Navbar
          rol={sesion.rol}
          compensador={sesion.compensador}
          sesion={sesion}
        ></Navbar>
        <div className="navbar-nuevo justify-content-center align-items-center text-center">
          <div className=" col-4 btn-group  justify-content-left ">
            {sesion.rol > 0 ? (
              <>
                <Tooltip placement="topLeft" title={t("tooltip.inicio")}>
                  <Link className="btn-header" to="/">
                    <RiHomeHeartLine className="iconos_header " />
                  </Link>
                </Tooltip>
                {sesion.rol === 3 ? (
                  <Tooltip
                    placement="topLeft"
                    title={t("tooltip.empresa")}
                    className="tooltip-head"
                  >
                    <Link className="btn-header" to="/empresa">
                      <RiBuilding4Line className="iconos_header " />
                    </Link>
                  </Tooltip>
                ) : sesion.rol === 2 && sesion.duenio ? (
                  <Tooltip
                    placement="topLeft"
                    title={t("tooltip.empresa")}
                    className="tooltip-head"
                  >
                    <Link
                      className="btn-header"
                      to={"/empresa/" + sesion.empresaActiva}
                    >
                      <RiBuilding4Line className="iconos_header " />
                    </Link>
                  </Tooltip>
                ) : (
                  <></>
                )}
                <Tooltip
                  placement="topLeft"
                  title={t("tooltip.calculadora")}
                  className="tooltip-head"
                >
                  <Link className="btn-header" to="/calculadoras">
                    <RiCalculatorLine className="iconos_header " />
                  </Link>
                </Tooltip>
                <Tooltip
                  placement="topLeft"
                  title={t("tooltip.reporte")}
                  className="tooltip-head"
                >
                  <Link className="btn-header" to="/reportes">
                    <RiLineChartFill className="iconos_header " />
                  </Link>
                </Tooltip>
                <Tooltip
                  placement="topLeft"
                  title={t("tooltip.compensar")}
                  className="tooltip-head"
                >
                  <Link className="btn-header" to="/compensar">
                    <FaSeedling className="iconos_header " />
                  </Link>
                </Tooltip>
                {sesion.rol === 1 ? (
                  <Tooltip
                    placement="topLeft"
                    title={t("tooltip.medidasMitigadoras")}
                    className="tooltip-head"
                  >
                    <Link
                      className="btn-header"
                      to="/medidasMitigadorasPersonal"
                    >
                      <RiLightbulbLine className="iconos_header" />
                    </Link>
                  </Tooltip>
                ) : sesion.rol === 2 ? (
                  <Tooltip
                    placement="topLeft"
                    title={t("tooltip.medidasMitigadoras")}
                    className="tooltip-head"
                  >
                    <Link className="btn-header" to="/medidasMitigadoras">
                      <RiLightbulbLine className="iconos_header" />
                    </Link>
                  </Tooltip>
                ) : null}
                {sesion.compensador ? (
                  <Tooltip
                    placement="topLeft"
                    title={t("tooltip.compensaciones")}
                    className="tooltip-head"
                  >
                    <Link
                      className="btn-header iconos_header"
                      to="/compensador"
                      style={{ fontSize: "0.9em" }}
                    >
                      <i
                        className="fa-solid fa-leaf"
                        style={{
                          fontSize: "1em",
                          marginRight: "0.5em",
                          textTransform: "none",
                        }}
                      ></i>{" "}
                      {t("tooltip.compensaciones")}
                    </Link>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="col-4  d-none d-xl-block d-md-block text-center">
            <span>
              <img
                src="./img/logo-menu.png"
                alt=""
                className="img-fluid img_logo_navbar"
              />
            </span>
          </div>
          <div
            className="col-4 text-end d-none d-xl-block d-md-block"
            style={{ alignItems: "center", justifyContent: "space-between"}}
          >
            <div
              className="bandera"
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              
              }}
              onClick={() => changeLanguage("en")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/en.png"
                alt="English"
                className="bandera"
              />
              <span className="texto-bandera" style={{ fontWeight: "700" }}>EN</span>
            </div>
            <div
              className="bandera "
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
               
              }}
              onClick={() => changeLanguage("es")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/esp.png"
                alt="Español"
                className="bandera"
              />
              <span className="texto-bandera" style={{ fontWeight: "700" }}>ES</span>
            </div>
            {!notificacion && sesion.rol > 0 ? (
              <div className="btn-group">
                <Link
                  type="button"
                  className="btn-header "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Badge count={contador_notificaciones}>
                    <SlBell className="iconos_header " />
                  </Badge>
                </Link>

                <ul className="dropdown-menu dropdown-menu-end">
                  {sesion.rol > 0 ? (
                    <>
                      {!sesion.checkPerfil ? (
                        <li>
                          <Link
                            className="dropdown-item d-flex justify-content-between pt-1"
                            to="/perfil"
                          >
                            {t("mensajes.completarPerfil")}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {sesion.rol !== 1 &&
                      sesion.rol !== 4 &&
                      sesion.rol !== 5 ? (
                        !sesion.checkDias ? (
                          <li>
                            <Link
                              className="dropdown-item d-flex justify-content-between pt-1"
                              to={
                                "/empresa/" +
                                sesion.empresaActiva +
                                "/subscripciones"
                              }
                            >
                              {t("mensajes.suscripcionTermina")}
                            </Link>
                          </li>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      {sesion.rol !== 1 &&
                      sesion.rol !== 4 &&
                      sesion.rol !== 5 ? (
                        !sesion.checkSede ? (
                          <li>
                            <Link
                              className="dropdown-item d-flex justify-content-between pt-1"
                              to={"/empresa/" + sesion.empresaActiva + "/sede"}
                            >
                              {t("mensajes.completarDatos")}
                            </Link>
                          </li>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            ) : (
              <></>
            )}
            <div className="btn-group">
              <Link
                type="button"
                className="btn-header  "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Tooltip placement="topLeft" title={t("tooltip.perfil")}>
                  <SlUser className="iconos_header" />
                </Tooltip>
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                {sesion.rol > 0 ? (
                  <>
                    <li>
                      <Link
                        className="dropdown-item d-flex justify-content-between "
                        to="/perfil"
                      >
                        {t("tooltip.perfil")}
                        <UserOutlined className="ic-dropdown" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex justify-content-between "
                        to="/credenciales"
                      >
                        {t("home.contra")}{" "}
                        <KeyOutlined className="ic-dropdown pt-1" />{" "}
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </>
                ) : (
                  <></>
                )}
                <li>
                  <Link
                    className="dropdown-item d-flex justify-content-between pt-1 "
                    onClick={logout}
                  >
                    Logout <LogoutOutlined className="ic-dropdown pt-1" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center m-0 w-100 mt-2 row-sedes-2">
          {sesion.rol === 1 ? (
            ""
          ) : (
            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-center justify-content-md-start w-100">
              <div className="d-flex align-items-center flex-row">
                <FaMapMarkerAlt
                  style={{ marginBottom: "0.8em" }}
                  className="icono-sedes-pin"
                />
                <span className="texto-explicacion-sedes mx-2">
                  {sesion.datosSede.nombre}
                </span>{" "}
                -
              </div>
              <div
                className="d-flex justify-content-center flex-row mx-2"
                style={{ marginBottom: "0.8em" }}
              >
                <CompConfigEmpresa sesion={sesion} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
