import { Input } from "antd";
import { useState, useEffect } from "react";
import { cambioValor } from "../funciones_utiles";

export const InputPrecio = ({
  idCampo,
  elValue,
  elPlaceholder,
  required,
  elSufijo,
  onChange,
}) => {
  const [value, setValue] = useState(elValue || "0,00");

  useEffect(() => {
    setValue(elValue || "0,00");
  }, [elValue]);

  const formatNumber = (numberString) => {
    // Añadir ceros a la izquierda si es necesario
    while (numberString.length < 3) {
      numberString = "0" + numberString;
    }

    // Insertar la coma
    const parteEntera = numberString.slice(0, -2);
    const parteDecimal = numberString.slice(-2);
    let numeroBonito = `${parseInt(parteEntera, 10)},${parteDecimal}`;

    // Añadir separación de miles
    numeroBonito = numeroBonito.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return numeroBonito;
  };

  const handleInputChange = (e) => {
    const { id, value: inputValue } = e.target;

    // Eliminar cualquier carácter no numérico
    let rawValue = inputValue.replace(/\D/g, "");

    // Si el valor es vacío, establecer a "0,00"
    if (!rawValue) {
      setValue("0,00");
      onChange({ target: { value: "0,00", id: id } });
      return;
    }

    // Formatear el valor
    const numeroBonito = formatNumber(rawValue);
    setValue(numeroBonito);
    onChange({ target: { value: numeroBonito, id: id } });

    // Mantener el cursor a la derecha
    setTimeout(() => {
      e.target.selectionStart = e.target.value.length;
      e.target.selectionEnd = e.target.value.length;
    }, 0);
  };

  return (
    <Input
      className="input-formulario-calculadora"
      autoComplete="none"
      id={idCampo}
      type="text"
      placeholder={elPlaceholder}
      onChange={handleInputChange}
      value={value}
      suffix={elSufijo}
    />
  );
};