import React from "react";
import { Card, Typography, Button } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
const { TabPane } = Tabs;

export const CardPlanesInternos = ({
  funcion,
  nombreImagen,
  arrPlanes,
  planActual,
  diasFaltantes,
}) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  // console.log(arrPlanes);
  return (
    <div className="row justify-content-center align-items-center">
      {arrPlanes.map((plan) => (
        <Card
		className="card-planes-internos"
          key={plan.id}
          title={currentLanguage === "es" ? plan.tipo_subscripcion : plan.tipo_subscripcion_en}
          hoverable
		  headStyle={{ borderBottom: 0 }} 
          style={{
			
            width: "30em",
            height: "18em",
			margin:"1em",
   
			marginBottom:"0.5em",
            backgroundColor: "#FFFFFF",
            textAlign: "left",
            fontWeight: "400",
			padding:"0 !important"
          }}
        >
       
            <div
              key={1}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",	
				marginBottom:"0.5em",
         
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em", marginTop:"0.2em" }}
              />
              <Text>{t("planes.cantidadEmpresas")} {plan.max_empresas}</Text>
            </div>
            <div
              key={2}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
				marginBottom:"0.5em",
             
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em" , marginTop:"0.2em"}}
              />
              <Text>{t("planes.cantidadSedes")}{plan.max_sedes}</Text>
            </div>
            <div
              key={3}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginBottom:"0.5em",
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em", marginTop:"0.2em" }}
              />
              <Text>{t("planes.cantidadUsuarios")} {plan.max_usuarios}</Text>
            </div>
            <div
              key={4}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
         
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em", marginTop:"0.2em" }}
              />
              <Text>{t("planes.apiIntegradora")} {currentLanguage === "es" ? (parseInt(plan.habilita_api) === 1 ? " Si": " No") : (parseInt(plan.habilita_api) === 1 ? " Yes": " No") } </Text>
            </div>
        
         <hr ></hr>
          <div
            key={5}
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
		
            }}
          >
            {/* { diasFaltantes > 346 ? (
							<h2 className="valor-cards">
								USD {(parseFloat(plan.precio_dolar) * 0.2).toFixed(2)} {t("planes.año")}
							</h2>
						) : diasFaltantes > 270 ? (
							<h2 className="valor-cards">
								USD {(parseFloat(plan.precio_dolar) * 0.5).toFixed(2)} {t("planes.año")}
							</h2>
						) : diasFaltantes > 220 ? (
							<h2 className="valor-cards">
								USD {(parseFloat(plan.precio_dolar) * 0.7).toFixed(2)} {t("planes.año")}
							</h2>
						) : ( */}
            <h2 className="valor-cards">
            { 
              isNaN(parseFloat(plan.precio_dolar)) || parseFloat(plan.precio_dolar) === 0 
              ? "Free!" 
              : `USD ${parseFloat(plan.precio_dolar).toFixed(2)} ${t("planes.año")}`
            }
                        
            </h2>
            {/* )} */}
			<div style={{ marginLeft: "auto" }}>
          {diasFaltantes < 30 ||
          parseInt(plan.orden_numerico) != parseInt(planActual) ? (
         

            
            <Button
              onClick={(e) =>
                funcion(
                  plan.rol_general,
                  plan.id,
                  plan.precio_dolar,
                  plan.tipo_subscripcion,
                  e
                )
              }
              type="primary"
              className="boton-card "
			
             
            >
              {diasFaltantes < 30 ? t("botones.renovar")  : t("botones.seleccionar")}
            </Button>
            //  <Button
              
            //   type="primary"
            //   className="boton-card "
			
             
            // >
            //   {diasFaltantes < 30 ? t("botones.proximamente") : t("botones.proximamente")}
            // </Button>
          ) : ( 
            <>  {t("planes.planActual")} </>
          )} </div></div>
        </Card>
      ))}
    </div>
  );
};
