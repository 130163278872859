import validator from 'validator';
import { sacaComas } from './funciones_utiles';



export const validarEmail = (email, t) => {
  return new Promise((resolve, reject) => {
    if (validator.isEmpty(email)) {
      resolve({ valido: false, mensaje: t("validaciones.validacion1") });
    } else if (!validator.isEmail(email)) {
      resolve({ valido: false, mensaje: t("validaciones.validacion2") });
    } else {
      resolve({ valido: true });
    }
  });
};
export const validarSoloTexto = (texto, t) => {
  return new Promise((resolve, reject) => {
    if (validator.isEmpty(texto)) {
      resolve({ valido: false, mensaje: t("validaciones.validacion3") });
    } else if (!validator.isAlpha(texto, ['es-ES'], { ignore: " .'´`" })) {
      resolve({ valido: false, mensaje: t("validaciones.validacion4") });
    } else {
      resolve({ valido: true, mensaje: '' });
    }
  });
};

export const validarTextoNumerico = async (texto, t) => {
  const regex = /^[A-Za-záéíóúÁÉÍÓÚñÑ\s.,-_'´`]+$/;
  if (validator.isEmpty(texto)) {
    return { valido: false, mensaje:t("validaciones.validacion3")  };
  } else if (!regex.test(texto)) {
    return { valido: false, mensaje: t("validaciones.validacion5") };
  } else {
    return { valido: true, mensaje: '' };
  }
};
export const validarPassword = async (password,t) => {
  if (validator.isEmpty(password)) {
    return { valido: false, mensaje: t("validaciones.validacion3") };
  } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&.,;:]{8,}$/.test(password)) {
    return { valido: false, mensaje: t("validaciones.validacion6") };
  } else {
    return { valido: true, mensaje:'' };
  }
};

export const validarNumerico = async (numerico,t) => {
  if (validator.isEmpty(numerico)) {
    return { valido: false, mensaje: t("validaciones.validacion3")};
  } else if (!validator.isNumeric(numerico, { no_symbols: true })) {
    return { valido: false, mensaje: t("validaciones.validacion7") };
  } else if (parseInt(numerico) >= 1000000000000) {
    return { valido: false, mensaje: t("validaciones.validacion8") };
  } else if (parseInt(numerico) <= 0) {
    return { valido: false, mensaje: t("validaciones.validacion9")};
  } else if (numerico[0] === '0') {
    return { valido: false, mensaje: t("validaciones.validacion10") };
  } else {
    return { valido: true, mensaje:'' };
  }
};

export const validarNumericoDecimal = async (numerico,t) => {
  
  if (numerico === "" || numerico === 0) {
    return { valido: false, mensaje: t("validaciones.validacion3") };
  }

  // Expresión regular modificada
  const regexDecimal = /^(\d{1,3}(\.\d{3})*(,\d{1,2})?|\d+(\.\d{1,2})?)$/;

  if (!regexDecimal.test(numerico)) {
    return { valido: false, mensaje: t("validaciones.validacion11") };
  }
  // Resto del código permanece igual
  const valorNumerico = parseFloat(await sacaComas(numerico));

  if (isNaN(valorNumerico)) {
    return { valido: false, mensaje: t("validaciones.validacion3") };
  } else if (valorNumerico >= 1000000000) {
    return { valido: false, mensaje: t("validaciones.validacion29") };
  } else if (valorNumerico <= 0) {
    return { valido: false, mensaje: t("validaciones.validacion13")  };
  } else {
    return { valido: true, mensaje: '' };
  }
};

export const validarCodigoNumerico = async (numerico,t) => {
  if (validator.isEmpty(numerico)) {
    return { valido: false, mensaje: t("validaciones.validacion3")  };
  } else if (!validator.isNumeric(numerico, { no_symbols: true })) {
    return { valido: false, mensaje: t("validaciones.validacion7") };
  } else if (parseInt(numerico) >= 999999) {
    return { valido: false, mensaje: t("validaciones.validacion30")  };
  } else if (parseInt(numerico) <= 0) {
    return { valido: false, mensaje: t("validaciones.validacion9")  };
  } else {
    return { valido: true, mensaje:'' };
  }
}

export const validarNumericoID = async (numerico,t) => {
    if (validator.isEmpty(numerico)) {
      return { valido: false, mensaje: t("validaciones.validacion3") };
    } else if (!validator.isNumeric(numerico, { no_symbols: true })) {
      return { valido: false, mensaje: t("validaciones.validacion7")  };
    } else if (parseInt(numerico) >= 9999999999999) {
      return { valido: false, mensaje: t("validaciones.validacion14")  };
    } else if (parseInt(numerico) <= 0) {
      return { valido: false, mensaje: t("validaciones.validacion15") };
    } else if(numerico[0] === '0') {
      return { valido: false, mensaje: t("validaciones.validacion10")  };
    } else {
      return { valido: true, mensaje:'' };
    }
}
export const validarTelefono = async (telefono,t) => {
    if (validator.isEmpty(telefono)) {
      return { valido: false, mensaje: t("validaciones.validacion16")  };
    } else if (!validator.isNumeric(telefono, { no_symbols: true })) {
        return { valido: false, mensaje: t("validaciones.validacion7") };
    } else if (telefono[0] === '0') {
      return { valido: false, mensaje: t("validaciones.validacion17") };
    } else {
      return { valido: true, mensaje:'' };
    }
}

export const validarCPostal = async (cPostal,t) => {
    if (validator.isEmpty(cPostal)) {
      return { valido: false, mensaje: t("validaciones.validacion18") };
    } else if (!validator.isAlphanumeric(cPostal)) {
      return { valido: false, mensaje: t("validaciones.validacion19") };
    } else if (cPostal.length > 6) {
      return { valido: false, mensaje: t("validaciones.validacion20")  };
    } else {
      return { valido: true, mensaje:'' };
    }
}

export const validarFecha = async (fecha,t) => {
    if (validator.isEmpty(fecha)) {
      return { valido: false, mensaje: t("validaciones.validacion21") };
    } else if (!validator.isDate(new Date(fecha), { format: 'YYYY-MM-DD' })) {
      return { valido: false, mensaje: t("validaciones.validacion22") };
    } else {
      const hoy = new Date();
      const fechaIngresada = new Date(fecha);
      const fechaInicio = new Date('2010-01-01');
      if (fechaIngresada > hoy) {
        return { valido: false, mensaje: t("validaciones.validacion23")  };
      } else if (fechaIngresada < fechaInicio) {
        return { valido: false, mensaje: t("validaciones.validacion24")  };
      } else {
        return { valido: true, mensaje:'' };
      }
    }
}

export const validarFechaPeriodo = async (fecha,t) => {
    if (!fecha) {
      return { valido: false, mensaje: t("validaciones.validacion3")  };
    }
    const fechaHoy = new Date();
    const fechaMinima = new Date('2010-01-01');
    fechaHoy.setDate(1); // Ajustamos la fecha para comparar solo año y mes
  
    if (new Date(fecha) > fechaHoy) {
      return { valido: false, mensaje:  t("validaciones.validacion25")};
    } else if (new Date(fecha) < fechaMinima) {
      return { valido: false, mensaje:  t("validaciones.validacion24") };
    } else {
      return { valido: true, mensaje:'' };
    }
}
  

export const validarSelector = async (valor,t) => {
    if (valor === '' || valor === '0' || valor === 'null') {
      return { valido: false, mensaje:  t("validaciones.validacion26") };
    } else {
      return { valido: true, mensaje:'' };
    }
}
export const validarSelectorPais = async (valor,t) => {
  if (valor === '' || valor === '0' || valor === 'null') {
    return { valido: false, mensaje:  t("validaciones.validacion27") };
  } else {
    return { valido: true, mensaje:'' };
  }
}
export const validarSelectorAeropuerto = async (valor,t) => {
  if (valor === '' || valor === '0' || valor === 'null') {
    return { valido: false, mensaje:  t("validaciones.validacion28") };
  } else {
    return { valido: true, mensaje:'' };
  }
}