import axios from "axios";
import { useNavigate, useLocation, useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const URI_REGISTRO =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/register";
export const PagoCheck = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const { estado, estado_dos, estado_pago, id_preregistro } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const payment_id = queryParams.get("payment_id") || "";
  const status = queryParams.get("status") || "";
  const merchant_order_id = queryParams.get("merchant_order_id") || "";

  const [solicitudEnviada, setSolicitudEnviada] = useState(false);

  useEffect(() => {
    // Verificar si ya se ha enviado la solicitud
    if (
      !solicitudEnviada &&
      estado &&
      id_preregistro &&
      estado_dos &&
      estado_pago
    ) {
      axios
        .post(URI_REGISTRO, {
          payment_id: payment_id,
          status: status,
          merchant_order_id: merchant_order_id,
          datoEstadoDos: estado_dos,
          datoEstado: estado,
          estado_pago: estado_pago,
          id_preregistro: id_preregistro,
        })
        .then((registro) => {
          console.log(registro.data.code);

          if (registro.data.code === 406) {
            navigate("/signup/error");
          } else if (registro.data.code === 409) {
            navigate("/signup/error");
          } else if (registro.data.code === 200) {
            navigate("/signup/exito");
          }
          setSolicitudEnviada(true);
        })
        .catch((error) => {
          console.error(error);
          // Manejar el error, según sea necesario.
        });
    }
  }, [estado, estado_dos, estado_pago, id_preregistro, solicitudEnviada, merchant_order_id, navigate, payment_id, status]);
  return (
    <>
      <div className="main regsim">
        <div className="row menu-wrapper justify-content-center align-items-center">
          <div className="col-xl-4 col-md-12  logo-container text-left">
            <img
              src="./img/logo-menu.png"
              alt=""
              className="img_logo img-fluid"
              style={{ width: "10em" }}
            />
          </div>

          <div className="col-xl-4 col-md-12 menu-container text-center">
            <ul className="ul-nuevo">
              <li className="li-nuevo">
                <a
                  href="https://owl-track.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                     {t("botones.inicio")}
                </a>
              </li>
              <li className="li-nuevo">
                <a
                  href="https://owl-track.com/blog/"
                  rel="noreferrer"
                  target="_blank"
                >
                {t("botones.noticias")}
                </a>
              </li>
              <li className="li-nuevo">
                <a
                  href="https://owl-track.com/contacto/"
                  rel="noreferrer"
                  target="_blank"
                >
                {t("botones.contacto")}
                </a>
              </li>
              <li className="li-nuevo">
                <a href="/signup" rel="noreferrer" target="_blank">
                {t("botones.calculadora")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-4 col-transparente d-none d-sm-block"></div>
        </div>
        <div className="contenedor-error mt-5 text-center position-relative">
          <div className="d-flex flex-column align-items-center justify-content-center">
            {/* Pseudo-elemento para el círculo */}
            <div className="circulo">
              <img
                src="./img/registro-exitoso.svg"
                alt="Descripción"
                className="imagen-circulo"
              />
            </div>
            <h1 className="titulo-error">
            {t("registro.texto1")}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};
