import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export const CardNueva = ({
  title,
  imageSrc,
  mensaje,
  mensaje_particular,
  rol,
  link,
  color
}) => {
  const [showHelpBox, setShowHelpBox] = useState(false);
  const helpBoxRef = useRef(null);

  

  const activarInfo = () => {
    MySwal.fire({
      title: `${title}`,
      html: `${rol === 1 ? mensaje_particular : mensaje}`,
      confirmButtonText: "Ok",
    });
  }



  return (
    <>
     

      <div
        className="card"
        style={{
          width: "10rem",
          height: "12.6rem",
          position: "relative",
          margin: "1em",
        }}
      >
        <div 
          className="card-header"
          style={{
            backgroundColor: `${color}`
          }}
        >
          <Link to={link}>
            <h2 className="card-title">{title}</h2>
          </Link>

          <FaQuestionCircle
            className="help-button"
            onClick={() => activarInfo()}
          />
        </div>
        <Link to={link}>
          <div className="card-image">
            <img src={imageSrc} alt={title} />
          </div>
        </Link>
      </div>
    </>
  );
};
