import React from "react";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import BurguerButton from "../navegacion/BurguerButton";
import { FaChevronDown } from "react-icons/fa";
import { Badge } from "antd";
import { SlBell } from "react-icons/sl";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/logout";

export const Navbar = ({ rol, compensador, sesion }) => {
  const [t, i18n] = useTranslation("global");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("preferredLanguage", lng);
  };
  const [clicked, setClicked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Agregado

  const handleClick = () => {
    setClicked(!clicked);
  };
  const navigate = useNavigate();
  let objeto_notificacion;
  if (sesion.rol === 1 || sesion.rol === 4 || sesion.rol === 5) {
    objeto_notificacion = {
      perfil: sesion.checkPerfil,
    };
  } else {
    objeto_notificacion = {
      empresa: sesion.checkSede,
      perfil: sesion.checkPerfil,
      dias: sesion.checkDias,
    };
  }
  // console.log(sesion.infoSubscripcion)
  const valores = Object.values(objeto_notificacion);
  let notificacion;
  if (valores.includes(false)) {
    notificacion = false;
  } else {
    notificacion = true;
  }
  let contador_notificaciones = 0;
  // let extra_contador = 0;

  for (const propiedad in objeto_notificacion) {
    if (objeto_notificacion[propiedad] === false) {
      contador_notificaciones++;
    }
  }
  const logout = async (e) => {
    e.preventDefault();
    const logearse = await axios.get(URI, {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    if (logearse.data.code === 200) {
      MySwal.fire({
        text: t("mensajes.mensaje65"),
        imageUrl: "img/registro-exitoso.svg",
        imageHeight: 65,
        confirmButtonText: "Ok",
      });
      localStorage.removeItem("valid_session");
      navigate("/signin");
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      <NavContainer rol={sesion.rol}>
        <img src="./img/logo-menu.png" className="img-menu" alt="" />{" "}
        {!notificacion && sesion.rol > 0 ? (
          <div className="btn-group ">
            <Link
              type="button"
              className="btn-header "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Badge count={contador_notificaciones}>
                <SlBell
                  className="iconos_header_perfil "
                  style={{ fontSize: "2em" }}
                />
              </Badge>
            </Link>

            <ul className="dropdown-menu dropdown-menu-end">
              {sesion.rol > 0 ? (
                <>
                  {!sesion.checkPerfil ? (
                    <li>
                      <Link
                        className="dropdown-item d-flex justify-content-between pt-1"
                        to="/perfil"
                      >
                        {t("mensajes.mensaje66")}
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {sesion.rol !== 1 && sesion.rol !== 4 && sesion.rol !== 5 ? (
                    !sesion.checkDias ? (
                      <li>
                        <Link
                          className="dropdown-item d-flex justify-content-between pt-1"
                          to={
                            "/empresa/" +
                            sesion.empresaActiva +
                            "/subscripciones"
                          }
                        >
                          {t("mensajes.mensaje67")}
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  {sesion.rol !== 1 && sesion.rol !== 4 && sesion.rol !== 5 ? (
                    !sesion.checkSede ? (
                      <li>
                        <Link
                          className="dropdown-item d-flex justify-content-between pt-1"
                          to={"/empresa/" + sesion.empresaActiva + "/sede"}
                        >
                          Co{t("mensajes.mensaje68")}
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`links ${clicked ? "active" : ""}`}
          style={{ marginTop: "2em" }}
        >
          <div
            style={{
              display: "flex", 
              justifyContent: "left",
              alignItems: "center",
              marginLeft: "1em",

            }}
          >
            <div
              className="bandera"
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => changeLanguage("en")}
            >
              <img
                style={{ marginRight: "0.2em" }} 
                src="./img/en.png"
                alt="English"
                className="bandera-mobile"
              />
              <span className="texto-bandera" style={{ fontWeight: "700" }}>EN</span>
            </div>
            <div
              className="bandera"
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: "0", 
              }}
              onClick={() => changeLanguage("es")}
            >
              <img
                style={{ marginRight: "0.2em" }} 
                src="./img/esp.png"
                alt="Español"
                className="bandera-mobile"
              />
              <span className="texto-bandera" style={{ fontWeight: "700" }}>ES</span>
            </div>
          </div>

          <Link to="/">{t("botones.inicio")}</Link>
          {parseInt(rol) === 3 || parseInt(rol) === 2 ? (
            <Link to={"/empresa/" + sesion.empresaActiva}>
              {t("tooltip.empresa")}
            </Link>
          ) : null}

          <Link to="/calculadoras">{t("tooltip.calculadora")}</Link>
          <Link to="/reportes">{t("tooltip.reporte")}</Link>
          <Link to="/compensar">{t("tooltip.compensar")}</Link>
          {sesion.rol === 1 ? (
            <Link to="/medidasMitigadorasPersonal">
              {t("tooltip.medidasMitigadoras")}
            </Link>
          ) : sesion.rol === 2 ? (
            <Link to="/medidasMitigadoras">
              {t("tooltip.medidasMitigadoras")}
            </Link>
          ) : null}
          {compensador ? (
            <Link to="/compensador">{t("tooltip.compensaciones")}</Link>
          ) : (
            <></>
          )}

          <ul>
            <li className={`dropdown ${dropdownOpen ? "open" : ""}`}>
              <a className="text-menu-droptown" onClick={toggleDropdown}>
                {t("tooltip.perfil")}
                <FaChevronDown></FaChevronDown>
              </a>
              {dropdownOpen && ( // Agregado
                <ul className="dropdown-menu">
                  {parseInt(rol) === 0 ? (
                    <></>
                  ) : (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/perfil">
                          <i
                            className="ic-dropdown fa-solid fa-user"
                            style={{ color: "white", marginRight: "0.5em" }}
                          ></i>{" "}
                          {t("tooltip.perfil")}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/credenciales">
                          <i
                            className="ic-dropdown fa-solid fa-key"
                            style={{ color: "white", marginRight: "0.5em" }}
                          ></i>
                          {t("home.contra")}
                        </Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link className="dropdown-item" onClick={logout}>
                      <i
                        className="ic-dropdown fa-solid fa-sign-out-alt"
                        style={{ color: "white", marginRight: "0.5em" }}
                      ></i>{" "}
                      {t("home.salir")}
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
        <div className="burguer" style={{ backgroundColor: "transparent" }}>
          <BurguerButton clicked={clicked} handleClick={handleClick} />
        </div>
        <BgDiv className={`initial ${clicked ? " active" : ""}`}></BgDiv>
      </NavContainer>
    </>
  );
};

const NavContainer = styled.nav`
  @media (min-width: 1024px) {
    display: none;
  }

  h2 {
    color: #1ca6af;
    font-weight: 400;
    span {
      font-weight: bold;
    }
  }
  z-index: 1;
  padding: 0.4rem;
  background-color: #white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul {
    color: #1ca6af;
    text-decoration: none;
    margin-right: 1rem;
    z-index: 999;
  }

  .img-menu {
    width: 7em;
    margin-left: 0.5em;
  }
  .btn-header {
    display: flex !important;
    text-align: end !important;
  }

  .titulo-menu {
    color: #1ca6af;
    font-size: 2em;
    margin-top: 0.2em;
    font-weight: 500;
  }
  .links {
    position: absolute;
    top: -700px;
    left: -2000px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    transition: all 0.5s ease;
    a {
      color: #1ca6af;
      font-size: 1rem;
      display: block;
      z-index: 9999;
      font-weight: 600;
    }
  }
  .links.active {
    z-index: 1;
    width: 100%;
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 4%;
    left: 0;
    right: 0;
    text-align: left;
    a {
      margin-left: 1rem;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 1rem;
      color: #1ca6af;
    }
  }
  .ic-dropdown {
    color: #1ca5af !important;
  }
  .burguer {
    @media (min-width: 1024px) {
      display: none;
    }
  }
  .NavContainer {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .img-menu {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .titulo-menu {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .links {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .links ul li {
    display: inline-block;
    position: relative;
  }

  .links ul li a {
    display: block;
    text-decoration: none;
    color: #1ca6af;
  }

  /* Estilos del menú desplegable */
  .dropdown .dropdown-menu {
    display: block;
    position: absolute;
    text-align: center;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent !important;
    z-index: 999;
    border: none;
  }

  .dropdown.open .dropdown-menu {
    display: block;
  }

  .dropdown .dropdown-menu li {
    display: block;
  }

  .dropdown .dropdown-menu li a {
    display: block;

    padding: 5px 10px;
    text-decoration: none;
    color: #1ca6af;
    background-color: transparent;
    text-align: left;
    margin-left: 3.5rem;
  }
`;

const BgDiv = styled.div`
  background-color: #efefef;
  position: absolute;
  top: -1000px;
  left: -1000px;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.6s ease;

  &.active {
    border-radius: 0 0 80% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
