import { useParams} from 'react-router-dom'
import { CompCalculadoraConsumo } from './calculadoraConsumoElectrico';
import { CompCalculadoraVuelos } from './calculadoraVuelos';
import { CompCalculadoraViajes } from './calculadoraViajes'; 
import { CompCalculadoraViajesCombustibles } from './calculadoraPorCombustible';  
import { CompCalculadoraCombustion } from './calculadoraCombustion';
import { CompCalculadoraMateriales } from './calculadoraMateriales';
import { CompCalculadoraGases } from './calculadoraGases';
import { CompCalculadoraLogisticCombustibles } from './calculadoraLogisticaCombustible';
import { CompCalculadoraLogisticaPorKm } from './calculadoraLogisticaKmToneladas';
import { CompCalculadoraEstadiaHotel} from './calculadoraEstadiaHotel';
import { CompCalculadoraEnergiaImportada} from './calculadoraEnergiaImportada';
import { CompCalculadoraHomeOffice } from './calculadoraHomeOffice';
import { useTranslation } from 'react-i18next';


import { NotFound404 } from '../navegacion/404_not_found';

export const CompCalculadoras = ({sesion}) => {
    let { codigoCalculadora } = useParams();
	const { i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const existeRelacion = sesion.datosCalculadoras.find(item => item.codigo === codigoCalculadora);
    return(
    existeRelacion  
        ?  
    <>    
        {codigoCalculadora === "consumo_energia" && (    
            <CompCalculadoraConsumo sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}  />         
        )}  
        {codigoCalculadora === "vuelos" && ( 
            <CompCalculadoraVuelos sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en} />
        )} 
        {codigoCalculadora === "viajes" && (  
            <CompCalculadoraViajes sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en} />
        )} 
        {codigoCalculadora === "combustion_movil" && (  
            <CompCalculadoraViajesCombustibles sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en} />
        )} 
        {codigoCalculadora === "combustion_estacionaria" && (
            <CompCalculadoraCombustion sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}/>
        )}
        {codigoCalculadora === "combustion_materiales" && (
            <CompCalculadoraMateriales sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}/>
        )}  
        {codigoCalculadora === "logistica_combustible_a1" && (
            <CompCalculadoraLogisticCombustibles sesion={sesion} type={"fuel_logistics_report_a1"} type_tabla={"logistica_combustible_a1"} alcance={1} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}/>
        )} 
        {codigoCalculadora === "logistica_combustible_a3" && (
            <CompCalculadoraLogisticCombustibles sesion={sesion} type={"fuel_logistics_report_a3"} type_tabla={"logistica_combustible_a3"} alcance={3} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}/>
        )} 
        {codigoCalculadora === "logistica_km_tonelada_a1" && (
            <CompCalculadoraLogisticaPorKm sesion={sesion} type={"km_ton_logistics_report_a1"} type_tabla={"logistica_km_tonelada_a1"} alcance={1} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}/>
        )} 
        {codigoCalculadora === "logistica_km_tonelada_a3" && (
            <CompCalculadoraLogisticaPorKm sesion={sesion} type={"km_ton_logistics_report_a3"} type_tabla={"logistica_km_tonelada_a3"} alcance={3} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}/>
        )} 
        {codigoCalculadora === "gases_fugitivos" && (
            <CompCalculadoraGases sesion={sesion} calculo={"fugitivos"} type={"fugitive_gases_report"} type_tabla={"gases_fugitivos"} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}/>
        )} 
        {codigoCalculadora === "calculo_estadia_hotel" && (    
            <CompCalculadoraEstadiaHotel sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}  />         
        )}  
        {codigoCalculadora === "calculo_energia_importada" && (    
            <CompCalculadoraEnergiaImportada sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}  />         
        )} 
        {codigoCalculadora === "calculo_home_office" && (    
            <CompCalculadoraHomeOffice sesion={sesion} titulo={currentLanguage === "es" ? existeRelacion.nombre : existeRelacion.nombre_en}  />         
        )} 
    </>
        :
    <>
        <NotFound404 />
    </>
    )
}