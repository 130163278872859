import { Col } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  traerDatos,
  CheckExpiracion,
  HeadersAuth,
} from "../../funciones_utiles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_EMPRESAS =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/empresas_por_usuario";

export const CompConfigAsesor = ({ sesion }) => {
  const [inputValue] = useState("");
  const [t,i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [filteredList, setFilteredList] = useState();
  const [infoEmpresa, setInfoEmpresa] = useState([]);
  const [infoSedes, setInfoSedes] = useState([]);
  const [configActivos, setConfigActivos] = useState({
    empresaActiva: sesion.empresaActiva,
    sedeActiva: sesion.sedeActiva,
  });
  useEffect(() => {
    (async () => {
      setInfoEmpresa(await traerDatos(URI_EMPRESAS));
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setInfoSedes(
        await traerDatos(
          process.env.REACT_APP_DOMINIO +
            process.env.REACT_APP_PUERTO +
            "/sedes_por_empresa?id=" +
            configActivos.empresaActiva +
            "&limit=" +
            sesion.maxSedes
        )
      );
    })();
  }, [configActivos.empresaActiva, sesion.maxSedes]);
  //Buscador
  useEffect(() => {
    // setFilteredList(infoVehiculos)
    if (inputValue) {
      setFilteredList(
        filteredList.filter(
          (item) =>
            item.razon_social
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredList(infoEmpresa);
    }
  }, [inputValue, infoEmpresa, filteredList]);

  const handleChange = async (e) => {
    const { id, value } = e.target;
    const sedes = await traerDatos(
      process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/sedes_por_empresa?id=" +
        value +
        "&limit=" +
        sesion.maxSedes
    );
    if (sedes.length > 1) {
      setConfigActivos({
        ...configActivos,
        sedeActiva: "",
        [id]: value,
      });
    } else {
      setConfigActivos({
        ...configActivos,
        sedeActiva: infoSedes[0].id_sede,
        [id]: value,
      });
    }
  };
  const handleChangeSede = async (e) => {
    const { id, value } = e.target;
    setConfigActivos({
      ...configActivos,
      [id]: value,
    });
  };
  const guardar = async (e) => {
    e.preventDefault();
    // console.log(configActivos)
    if (configActivos.sedeActiva !== "") {
      const URI_UPDATE_ACTIVAS =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/update_activas";
      const registro = await axios.post(
        URI_UPDATE_ACTIVAS,
        configActivos,
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje35"),
              imageUrl: "img/img_activada.png",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
          }
        }
      }
    } else {
      MySwal.fire({
        text: t("mensajes.mensaje36"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 65,
        confirmButtonText: "Ok",
      });
    }
  };
  return (
    <>
      <div className="row  m-1">
        <div
          className="col-md-12"
          style={{ marginTop: "2rem", marginLeft: "1.5em" }}
        >
          <h1 className="subtitulo-empresas">
            <i className="boton-empresa-empresa tooltip-head"></i>
            Trabajar con:
          </h1>
        </div>

        <form onSubmit={guardar}>
          <div className="row align-items-center" style={{ marginLeft: "1em" }}>
            
            <Col md={4}>
              {infoEmpresa.length > 1 ? (
                <>
                  <label className="select_lista"></label>
                  <select
                    className="form-select"
                    id="empresaActiva"
                    onChange={handleChange}
                    value={configActivos.empresaActiva || ""}
                  >
                    {infoEmpresa.map((option) => (
                      <option key={option.id_empresa} value={option.id_empresa}>
                        {option.razon_social}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <>
                  <h2 className="select_lista">{t("empresas.empresa")}</h2>
                  <div className="form-select">
                    {infoEmpresa.length === 0
                      ? ""
                      : infoEmpresa[0].razon_social}
                  </div>
                </>
              )}
            </Col>

            <Col md={4}>
              {infoSedes.length > 1 ? (
                <>
                  <label className="select_lista">{t("empresa.sede")}</label>
                  <select
                    className="form-select"
                    id="sedeActiva"
                    onChange={handleChangeSede}
                    value={configActivos.sedeActiva || ""}
                  >
                    <option value="">{t("empresa.datosSede.elejirSede")}</option>
                    {infoSedes.map((option) => (
                      <option key={option.id_sede} value={option.id_sede}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <>
                  <label className="select_lista">{t("empresa.sede")}</label>
                  <div className="form-select">
                    {infoSedes.length === 0 ? "" : infoSedes[0].nombre}
                  </div>
                </>
              )}
            </Col>

            <Col md={4} className="col-empresa justify-content-start">
              {infoSedes.length > 1 || infoEmpresa.length > 1 ? (
                <button
                  className="boton-agregar-empresa mx-2"
                  style={{ marginTop: "1rem" }}
                >
                  {t("botones.cambiarSede")}
                </button>
              ) : (
                <></>
              )}
            </Col>

          </div>
        </form>
      </div>
    </>
  );
};
