import React, { useState } from "react";
import { Tabs, Button, Row, Col } from "antd";
import { TfiLightBulb } from "react-icons/tfi";
import { BsAirplaneEngines, BsFuelPump, BsTruck } from "react-icons/bs";
import { BtnAtras } from "../navegacion/btn_atras";
import { FaLeaf, FaLightbulb,FaSyncAlt, FaSeedling, FaClipboardCheck, FaRecycle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { BsEvFrontFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { HeadersAuth, CheckExpiracion } from "../funciones_utiles";

const MySwal = withReactContent(Swal);

export const CompMitigacion = ({ sesion }) => {
	const [t] = useTranslation("global");
  const [enviandoMensaje, setEnviandoMensaje] = useState(false);
  const URI_PARTNER =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/enviar_mensaje_partner";

  const enviarMailPaneles = async (e) => {
    e.preventDefault();
    setEnviandoMensaje(true);
    const registro = await axios.post(
      URI_PARTNER + "?id=" + sesion.empresaActiva + "&idp=" + 1,
      HeadersAuth
    );

    if (registro.data.code === 409) {
      setEnviandoMensaje(false);
      CheckExpiracion();
    } else if (registro.data.code === 406) {
      setEnviandoMensaje(false);
      MySwal.fire({
        text: t("mensajes.mensaje30"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else if (registro.data.code === 200) {
      setEnviandoMensaje(false);
      MySwal.fire({
        text: t("mensajes.mensaje31"),
        imageUrl: "img/guardado-exitoso.svg",
        imageHeight: 65,
        confirmButtonText: "Ok",
      });
    }
  };

  const onChange = (key) => {
    console.log(key);
  };

 const items = [
    {
      key: "1",
      label: t("medidas.label1"),
      children: (
        <div className="p-3">
          <Row>
            <h1 className="subtitulo-empresas mb-2">
            {t("medidas.titulo1")} {" "}
            </h1>
          </Row>
          <Row>
            <Col>
              <p>
                <FaLeaf className="icono-medidas" />   {t("medidas.subtitulo1")} 
              </p>
              <p>
                <FaLeaf className="icono-medidas" /> {t("medidas.subtitulo2")} 
              </p>
              <p>
                <FaLeaf className="icono-medidas" />{t("medidas.subtitulo3")} 
                </p>
              <p>
                <FaLeaf className="icono-medidas" /> {t("medidas.subtitulo4")} 
              </p>
            </Col>
          </Row>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><FaLightbulb className="icono-medidas"/>
          {t("medidas.transicionEnergetica.titulo")} 
          </h1>
          <h2 className="span-empresas-mitigadoras">
          {t("medidas.transicionEnergetica.subtitulo1")}
          </h2>
          <p>  {t("medidas.transicionEnergetica.parrafo1")}</p>
          <p>
          {t("medidas.transicionEnergetica.parrafo2")}
          </p>
          <p>  {t("medidas.transicionEnergetica.parrafo3")} </p>
          <p>
          {t("medidas.transicionEnergetica.parrafo4")}
          </p>
          <p>
          {t("medidas.transicionEnergetica.parrafo5")}{" "}
          </p>
          <p>
          {t("medidas.transicionEnergetica.parrafo6")}{" "}
          </p>
          <h2 className="span-empresas-mitigadoras">
          {t("medidas.transicionEnergetica.subtitulo2")}
          </h2>
          <p>
          {t("medidas.transicionEnergetica.parrafo7")}
          </p>
          <h2 className="span-empresas-mitigadoras">
          {t("medidas.transicionEnergetica.subtitulo3")}
          </h2>
          <p> {t("medidas.transicionEnergetica.parrafo8")}</p>
          <p>
          {t("medidas.transicionEnergetica.parrafo9")}
          </p>
          <p>
          {t("medidas.transicionEnergetica.parrafo10")}
          </p>
          <p>
          {t("medidas.transicionEnergetica.parrafo11")}
          </p>
          <h2 className="span-empresas-mitigadoras">
          {t("medidas.transicionEnergetica.subtitulo4")}
          </h2>
          <p>
          {t("medidas.transicionEnergetica.parrafo12")}
          </p>
          <h2 className="span-empresas-mitigadoras">
            {t("medidas.transicionEnergetica.subtitulo5")}
          </h2>
          <p>
            {" "}
            {t("medidas.transicionEnergetica.parrafo13")}
          </p>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><FaSyncAlt className="icono-medidas"/>
            {" "}
            {t("medidas.transicionProductiva.titulo")}
          </h1>
          <h2 className="span-empresas-mitigadoras">
            {" "}
            {t("medidas.transicionProductiva.subtitulo1")}
          </h2>
          <p> {t("medidas.transicionProductiva.parrafo1")}</p>
          <p>
            {" "}
            {t("medidas.transicionProductiva.parrafo2")}{" "}
          </p>
          <p>
            {" "}
            {t("medidas.transicionProductiva.parrafo3")}{" "}
          </p>
          <h2 className="span-empresas-mitigadoras">
            {" "}
            {t("medidas.transicionProductiva.subtitulo2")}{" "}
          </h2>
          <p>
            {" "}
            {t("medidas.transicionProductiva.parrafo4")}{" "}
          </p>
          <p>
            {" "}
            {t("medidas.transicionProductiva.parrafo5")}
          </p>
          <h2 className="span-empresas-mitigadoras"> {t("medidas.transicionProductiva.subtitulo3")}</h2>
          <p>
            {" "}
            {t("medidas.transicionProductiva.parrafo6")} <a href="https://www.ellenmacarthurfoundation.org/topics/circular-economy-introduction/overview" target="_blank">{t("medidas.transicionProductiva.link")}</a>
{" "}
          </p>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><BsEvFrontFill className="icono-medidas"/>
            {" "}
            {t("medidas.movilidadSostenible.titulo")}{" "}
          </h1>
          <h2 className="span-empresas-mitigadoras">
            {" "}
            {t("medidas.movilidadSostenible.subtitulo1")}{" "}
          </h2>
          <p>
            {" "}
            {t("medidas.movilidadSostenible.parrafo1")}
          </p>
          <p>
            {" "}
            {t("medidas.movilidadSostenible.parrafo2")}{" "}
          </p>
          <p>
            {" "}
            {t("medidas.movilidadSostenible.parrafo3")}
            </p>
            <p>
            {t("medidas.movilidadSostenible.parrafo4")}{" "}
          </p>
          <h2 className="span-empresas-mitigadoras">
            {" "}
            {t("medidas.movilidadSostenible.subtitulo2")}{" "}
          </h2>
          <p>  {t("medidas.movilidadSostenible.parrafo5")}</p>
          <p>
            {" "}
            {t("medidas.movilidadSostenible.parrafo6")}{" "}
          </p>
          <p>
            {" "}
            {t("medidas.movilidadSostenible.parrafo7")}
          </p>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><FaSeedling className="icono-medidas"/>{t("medidas.cadenaValor.titulo")} </h1>
          <p>
          {t("medidas.cadenaValor.parrafo1")}{" "}
          </p>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><FaClipboardCheck className="icono-medidas"/>
          {t("medidas.beneficios.titulo")}
          </h1>
          <p>
            {" "}
            {t("medidas.beneficios.parrafo1")}
          </p>
          <p> {t("medidas.beneficios.parrafo2")} </p>
          <p> {t("medidas.beneficios.parrafo3")}</p>
          <p> {t("medidas.beneficios.parrafo4")} </p>
          <p> {t("medidas.beneficios.parrafo5")}</p>
        </div>
      ),
    },
    {
      key: "2",
      label: t("medidas.label2"),
      children: (
        <div>
          <h1 className="subtitulo-empresas-mitigadoras"><FaLightbulb className="icono-medidas"/>
            {" "}
            {t("medidas2.energia.titulo")}{" "}
          </h1>
          <p>
            {" "}
            {t("medidas2.energia.parrafo1")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo2")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo3")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo4")}
          </p>
          <p>   {t("medidas2.energia.parrafo5")}</p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo6")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo7")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo8")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo9")}
          </p>
          <ul>
          {t("medidas2.energia.parrafo10")}</ul>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><BsEvFrontFill className="icono-medidas"/>
            {" "}
            {t("medidas2.transporte.titulo")}{" "}
          </h1>
          <p>
            {" "}
            {t("medidas2.energia.parrafo1")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo2")}
          </p>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><FaSyncAlt className="icono-medidas"/>
            {" "}
            {t("medidas2.tecnologia.titulo")}
          </h1>
          <p>
            {" "}
            {t("medidas2.energia.parrafo1")}
          </p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo2")}
          </p>
          <p> {t("medidas2.energia.parrafo3")}</p>
          <p>
            {" "}
            {t("medidas2.energia.parrafo4")}
            {enviandoMensaje ? (
                <button disabled className="btnprincipal  mx-4 p-1" >
                  <b>{t("medidas2.tecnologia.enviarConsulta")}</b>
                </button>
              ) : (
                <button onClick={enviarMailPaneles} className="btnprincipal mx-4 p-1">
                  <b>{t("medidas2.tecnologia.masInfo")}</b>
                </button>
              )}
          </p>
          <hr></hr>
          <h1 className="subtitulo-empresas-mitigadoras"><FaSeedling className="icono-medidas"/>   {t("medidas2.cadena.titulo")}</h1>
          <p>
            {" "}
            {t("medidas2.cadena.parrafo1")}
          </p>
        </div>
      ),
    },
  ];
  return (
    <div className="row g-3 d-flex justify-content-center ms-3 me-3 p-2">
      <BtnAtras titulo={t("tooltip.medidasMitigadoras")} />  
       <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      {/* <img className="" src="./img/mitigacion.png" alt=""
                  />*/}
      {/* El contenido adicional que tenías comentado puede ir aquí */}
      {/*<div className="col-md-11">
        <h1 className="titulo-mitigacion">
          {" "}
          Iniciativa para la reducción de los GEI y seguimiento del desempeño
          interno{" "}
        </h1>
        <hr></hr>
        <span className="span-empresas-mitigadoras">
          <FaLeaf
            style={{
              marginRight: "0.2em",
              color: "#1CA6AF",
              marginBottom: "0.3em",
            }}
          />
          La evaluación de la huella de carbono permite la identificación de
          puntos críticos de emisión (puntos de mayor emisión de gases efecto
          invernadero), con la finalidad de focalizar los recursos y esfuerzos
          en esas áreas específicas para reducir en parte las emisiones
          generadas en base a una estrategia de gestión de emisiones, la cual
          consiste en un grupo de medidas y acciones que tienen por finalidad
          evitar o disminuir la generación de emisiones de GEI a la atmósfera.{" "}
        </span>
        <br></br>
        <br></br>{" "}
        <span className="span-empresas-mitigadoras">
          <FaLeaf
            style={{
              marginRight: "0.2em",
              color: "#1CA6AF",
              marginBottom: "0.3em",
            }}
          />
          El concepto de reducción de emisiones se refiere a todas aquellas
          acciones y/o actividades realizadas dentro de la organización y sus
          unidades dependientes, materializadas en proyectos como por ejemplo
          aquellos de eficiencia energética, reducción en consumo de
          combustibles e insumos, mejoras en la logística, entre otras.{" "}
        </span>
        <br></br> <br></br>{" "}
        <span className="span-empresas-mitigadoras">
          <FaLeaf
            style={{
              marginRight: "0.2em",
              color: "#1CA6AF",
              marginBottom: "0.3em",
            }}
          />
          Reducir las emisiones implica idear una estrategia orientada a la
          optimización de los sistemas operativos, lo que se puede traducir en
          una reducción de los costos de operación.{" "}
        </span>
        <h1 className="titulo-mitigacion mt-4"> Medidas mitigadoras</h1>
        <div className="row justify-content-center align-items-start">
          {" "}
          <hr></hr>{" "}
          <h1 className="subtitulo-empresas-mitigadoras text-left">
            Uso eficiente de la energía y los recursos
          </h1>
          <ul>
            <li>
              {" "}
              Sensibilizar a la comunidad institucional ante la importancia de
              apagar las luces que no están siendo utilizadas, o bien cuando la
              luz natural proporciona una iluminación suficiente, durante y al
              finalizar el turno laboral.{" "}
            </li>
            <li>
              {" "}
              Revisar periódicamente las luminarias, desconectar aquellas que no
              sean necesarias.
            </li>
            <li>
              {" "}
              Se pueden instalar sistemas de control de ocupación –fotocélulas-,
              que permiten la conexión y desconexión de la iluminación en
              función de la existencia o no de usuarios en las áreas objeto de
              control, o de la luz diurna disponible.
            </li>
            <li>
              Imprimir lo que realmente sea necesario; aprovechar las
              herramientas de digitalización de los documentos para evitar
              impresiones.
            </li>
            <li> Promover la impresión doble faz.</li>
            <li>
              {" "}
              Optimizar el uso de los sistemas de calefacción y refrigeración.
            </li>
            <li>
              {" "}
              Aprovechar la luz natural: Se recomienda pintar las oficinas de
              colores claros, y limpiar las ventanas y claraboyas para
              garantizar una mejor entrada de la luz.
            </li>
            <li>
              {" "}
              En verano se recomienda utilizar persianas y toldos en ventanas
              que permitan administrar la incidencia de la radiación solar, y
              por lo tanto, ahorrar en climatización.
            </li>
            <li>
              Implementar mejoras en el aislamiento del edificio, que reduzcan
              la energía consumida en acondicionamiento durante invierno o
              verano:
              <ul>
                <li>Contratar energía procedente de fuentes renovables.</li>
                <li>
                  Cambiar los gases refrigerantes por otros con menor potencial
                  de calentamiento global.
                </li>
                <li>
                  Promover el trabajo flexible, para reducir el número de horas
                  de los empleados en las instalaciones.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <hr></hr>
        <div className="row justify-content-center align-items-start">
          <h1 className="subtitulo-empresas-mitigadoras text-left">
            Transporte
          </h1>
          <ul>
            <li>
              {" "}
              Promover e incrementar el uso del transporte público, carpool,
              traslados a pie y bicicletas (cero emisiones).
            </li>
            <li>
              {" "}
              Reducir o adaptar la movilidad de los trabajadores y el transporte
              de mercancías.
            </li>
            <li>
              {" "}
              Adaptación de la flota automotriz a combustibles que generan menos
              emisiones. Por ejemplo contemplar la posibilidad de instalar
              tanques de GNC en vehículos con motores nafteros, o renovar la
              flota con vehículos eléctricos.
            </li>
            <li>
              Optimizar la logística de transporte de las actividades que se
              llevan adelante, implementando rutas de traslados más eficientes.
            </li>
          </ul>
        </div>
        <hr></hr>
        <div className="row justify-content-center align-items-start">
          <h1 className="subtitulo-empresas-mitigadoras text-left">
            Reemplazo de tecnología
          </h1>
          <ul>
            <li>
              {" "}
              Lámparas de bajo consumo pueden generar un ahorro hasta un 75%.
            </li>
            <li>
              {" "}
              Lámparas led reducen emisiones y el consumo energético hasta un
              85%.
            </li>
            <li> Mejorar el aislamiento térmico de las instalaciones.</li>
            <li>
              Instalación de paneles solares u otras fuentes de energía
              alternativa.
              {enviandoMensaje ? (
                <button disabled className="btnprincipal  mx-4 p-1" >
                  <b>Enviando Consulta...</b>
                </button>
              ) : (
                <button onClick={enviarMailPaneles} className="btnprincipal mx-4 p-1">
                  <b>Más info</b>
                </button>
              )}
            </li>
          </ul>
        </div>
        <hr></hr>
        <div className="row justify-content-center align-items-start">
          <h1 className="subtitulo-empresas-mitigadoras text-left">
            Cadena de valor
          </h1>
          <ul>
            <li>
              Apostar por el abastecimiento sostenible: contar con proveedores o
              intermediarios que también tengan registrada la huella de carbono.
            </li>
          </ul>
        </div>
      </div>*/}
    </div>
  );
};

export default CompMitigacion;
