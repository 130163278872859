import { BtnAtras } from '../navegacion/btn_atras'
import { Tabs } from 'antd'
import {useState, useEffect} from 'react'
import { CompDatosEmpresa } from './componentes_empresa/datosEmpresa'
import { CompDatosSede } from './componentes_empresa/datosSede'
import { CompDatosSedes } from './componentes_empresa/datosSedes'
import { CompConfigEmpresasAsesor } from './componentes_empresa/configuracionEmpresaAsesor'
import { CompDatosEmpleadosAsesor } from './componentes_empresa/datosEmpleadosAsesor'
import { traerDatos } from '../funciones_utiles'

import { useTranslation } from "react-i18next";
export const CompEmpresa = ({sesion}) => {
  const [t] = useTranslation("global");
    const URI_SEDES_EMPRESA = process.env.REACT_APP_DOMINIO+process.env.REACT_APP_PUERTO+'/sedes_por_empresa?id='+sesion.empresaPrincipal+'&limit='+sesion.maxSedes
    const plan = sesion.datosPlanes.find(item => parseInt(item.id_empresa) === parseInt(sesion.empresaPrincipal));
    const [losItem, setLosItem] = useState();

    useEffect(() => {
      if(plan){  
        if (parseInt(plan.max_sedes) < 2) {
          (async () => {
            const data = await traerDatos(URI_SEDES_EMPRESA);
            if (data.length > 0) {
              setLosItem([
              {
                key: '1',
                label: t("empresa.empresa"),
                children: <CompDatosEmpresa id_empresa={sesion.empresaPrincipal} />,
              },
              {
                key: '2',
                label: t("empresa.sede"),
                children: <CompDatosSede id_empresa={sesion.empresaPrincipal} id_sede={data[0].id_sede} />,
              },
              {
                key: '3',
                label: t("empresa.misEmpresas"),
                children: <CompConfigEmpresasAsesor sesion={sesion}/>,
              }
            ]);
            } 
          })();
        } else if ((parseInt(plan.max_sedes) > 1 )&&((parseInt(plan.max_usuarios) > 1 ))) {
          setLosItem([
            {
              key: '1',
              label: t("empresa.empresa"),
              children: <CompDatosEmpresa id_empresa={sesion.empresaPrincipal} />,
            },
            {
              key: '2',
              label: t("empresa.sedes"),
              children: <CompDatosSedes sesion={sesion} id_empresa={sesion.empresaPrincipal} uri_sxe={URI_SEDES_EMPRESA} />,
            },
            {
              key: '3',
              label: t("empresa.misEmpresas"),
              children: <CompConfigEmpresasAsesor sesion={sesion}/>,
            },
            {
              key: '4',
              label: t("empresa.usuarios"),
              children: <CompDatosEmpleadosAsesor id_empresa={sesion.empresaPrincipal}/>,
            }
          ]);
        } else if((parseInt(plan.max_sedes) > 1 )&&((parseInt(plan.max_usuarios) < 2 ))) {
          setLosItem([
            {
              key: '1',
              label: t("empresa.empresa"),
              children: <CompDatosEmpresa id_empresa={sesion.empresaPrincipal} />,
            },
            {
              key: '2',
              label: t("empresa.sedes"),
              children: <CompDatosSedes sesion={sesion} id_empresa={sesion.empresaPrincipal} uri_sxe={URI_SEDES_EMPRESA} />,
            },
            {
              key: '3',
              label: t("empresa.misEmpresas"),
              children: <CompConfigEmpresasAsesor sesion={sesion}/>,
            },
          ]);
        } else if((parseInt(plan.max_sedes) === 1 )&&((parseInt(plan.max_usuarios) > 1 ))) {
          (async () => {
            const data = await traerDatos(URI_SEDES_EMPRESA);
            if (data.length > 0) {
              setLosItem([
              {
                key: '1',
                label: t("empresa.empresa"),
                children: <CompDatosEmpresa id_empresa={sesion.empresaPrincipal} />,
              },
              {
                key: '2',
                label: t("empresa.sede"),
                children: <CompDatosSede id_empresa={sesion.empresaPrincipal} id_sede={data[0].id_sede} />,
              },
              {
                key: '3',
                label: t("empresa.misEmpresas"),
                children: <CompConfigEmpresasAsesor sesion={sesion}/>,
              },
              {
                key: '4',
                label: t("empresa.usuarios"),
                children: <CompDatosEmpleadosAsesor id_empresa={sesion.empresaPrincipal}/>,
              }
            ]);
            } 
          })();
        }
      }
    }, [URI_SEDES_EMPRESA, plan, sesion]);
    const onChangeTab = (key) => {
    };


    return (
        <>
          <div className="panel-empresa row g-3 d-flex justify-content-center ms-3 me-3">
            <Tabs 
                defaultActiveKey="1" 
                tabPosition={'top'}
                items={losItem} 
                onChange={onChangeTab} 
                responsive={{ tabPane: 'scroll' }}
            />
          </div> 
        </>
      )
}