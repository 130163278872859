import { Checkbox, Avatar, List, Input, Tooltip } from "antd";
import moment from "moment";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
} from "../../funciones_utiles";
import { validarEmail } from "../../validaciones";
import { RiSeedlingLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

export const CompDatosEmpleados = ({ sesion, id_empresa, uri_sxe }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(id_empresa)
  );

  const [tempDatosEmpleado, setTempDatosEmpleado] = useState({});
  const [idEmpleado, setIdEmpleado] = useState("");
  const [infoSedes, setInfoSedes] = useState([]);
  const [infoEmpleados, setInfoEmpleados] = useState([]);
  const [infoInvitaciones, setInfoInvitaciones] = useState([]);

  const URI_GUARDAR_EMPLEADO =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/invitar_usuario?id=" +
    id_empresa;
  const URI_EMPLEADOS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_empleados?id=" +
    id_empresa;
  const URI_INVITACIONES =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_invitaciones?id=" +
    id_empresa;

  const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
  const [
    empleadoSedesSeleccionadasObjeto,
    setEmpleadoSedesSeleccionadasObjeto,
  ] = useState({});
  const [empleadoSedesSeleccionadas, setEmpleadoSedesSeleccionadas] = useState(
    []
  );
  const [tempDatosEmpleadosMod, setTempDatosEmpleadosMod] = useState();

  const [inputValue, setInputValue] = useState("");
  const [filteredList, setFilteredList] = useState();

  const [inputValueI, setInputValueI] = useState("");
  const [filteredListI, setFilteredListI] = useState();
  // console.log(URI_VEHICULOS_EMPRESA_UNICO)
  const [show, setShow] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setInfoSedes(await traerDatos(uri_sxe));
        setInfoEmpleados(await traerDatos(URI_EMPLEADOS));
        setInfoInvitaciones(await traerDatos(URI_INVITACIONES));

        if (show === "editar" || show === "nuevo") {
          let URI_EMPLEADO_UNICO;
          if (show === "nuevo") {
            setTempDatosEmpleado({});
          } else if (show === "editar" && idEmpleado !== "") {
            URI_EMPLEADO_UNICO =
              process.env.REACT_APP_DOMINIO +
              process.env.REACT_APP_PUERTO +
              "/sedes_empleado?id=" +
              id_empresa +
              "&ide=" +
              idEmpleado;
            const empleadoSedesSeleccionadas = await traerDatos(
              URI_EMPLEADO_UNICO
            );
            setEmpleadoSedesSeleccionadas(empleadoSedesSeleccionadas.sedes);
            setTempDatosEmpleadosMod(empleadoSedesSeleccionadas.email);
            console.log(empleadoSedesSeleccionadas);
          }
          setSedesSeleccionadas(
            infoSedes.length === 0 ? [] : [infoSedes[0].id_sede]
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [show, id_empresa, idEmpleado, uri_sxe, URI_EMPLEADOS]);
  var [tooltipVisible, setTooltipVisible] = useState({
    email: false,
  });
  const [errores, setErrores] = useState({
    email: "",
  });
  const campos = [{ name: "email", validator: validarEmail }];
  //Buscador
  useEffect(() => {
    // setFilteredList(infoVehiculos)
    if (inputValue) {
      setFilteredList(
        filteredList.filter(
          (item) =>
            item.email
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredList(infoEmpleados);
    }
  }, [inputValue, infoEmpleados, show]);
  useEffect(() => {
    // setFilteredList(infoVehiculos)
    if (inputValueI) {
      setFilteredListI(
        filteredListI.filter(
          (item) =>
            item.email
              .toString()
              .toLowerCase()
              .indexOf(inputValueI.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredListI(infoInvitaciones);
    }
  }, [inputValueI, infoInvitaciones, show]);
  const cambiarShow = async (e) => {
    e.stopPropagation();
    window.scrollTo(0, 0);
    const { id, value } = e.currentTarget; // Cambia aquí
    setShow("");
    setShow(value);

    let idSplit = id.split("-");

    if (value === "editar") {
      setIdEmpleado(idSplit[0]);
    }
  };
  const handleSedesChange = (sedesSeleccionadas) => {
    if (sedesSeleccionadas.length === 0) {
      setSedesSeleccionadas([infoSedes[0].id_sede]);
      console.log("cambio el change"); // Selecciona automáticamente la primera sede
    } else {
      setSedesSeleccionadas(sedesSeleccionadas);
    }
  };

  const handleSelectAll = () => {
    const todasLasSedes = infoSedes.map((sede) => sede.id_sede);

    setSedesSeleccionadas(todasLasSedes);
  };

  const handleDeselectAll = () => {
    if (sedesSeleccionadas.length >= 1) {
      setSedesSeleccionadas([infoSedes[0].id_sede]);
      return; // Evita deseleccionar la única sede seleccionada
    } else {
      setSedesSeleccionadas([]);
    }
  };

  //EDICION CHANGE de CADA CHECKBOX

  const handleSedesChangeDos = (sedesSeleccionadas) => {
    if (sedesSeleccionadas.length === 0) {
      setEmpleadoSedesSeleccionadas([infoSedes[0].id_sede]);
      console.log("cambio el change"); // Selecciona automáticamente la primera sede
    } else {
      setEmpleadoSedesSeleccionadas(sedesSeleccionadas);
    }
  };

  const handleSelectAllDos = () => {
    const todasLasSedes = infoSedes.map((sede) => sede.id_sede);

    setEmpleadoSedesSeleccionadas(todasLasSedes);
  };

  const handleDeselectAllDos = () => {
    if (empleadoSedesSeleccionadas.length >= 1) {
      setEmpleadoSedesSeleccionadas([infoSedes[0].id_sede]);
      return; // Evita deseleccionar la única sede seleccionada
    } else {
      setEmpleadoSedesSeleccionadas([]);
    }
  };
  function onSearch(e) {
    setInputValue(e.target.value);
  }

  function onSearchI(e) {
    setInputValueI(e.target.value);
  }
  const handleChange = async (e) => {
    const { id, value } = e.target;

    setTempDatosEmpleado({
      ...tempDatosEmpleado,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };

    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };
  const guardarEdicion = async (e) => {
    e.preventDefault();
    empleadoSedesSeleccionadasObjeto.array_de_sedes =
      empleadoSedesSeleccionadas;
    const URI_UPDATEAR_EMPLEADO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/update_sedes_empleado?id=" +
      id_empresa +
      "&idu=" +
      idEmpleado;

    const registro = await axios.post(
      URI_UPDATEAR_EMPLEADO,
      empleadoSedesSeleccionadasObjeto,
      HeadersAuth
    );

    if (registro.data.errors) {
      const errores = registro.data.errors;
      
      const msgError = errores.map((error) => {
        return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
      });
      MySwal.fire({
        html: `${msgError}`,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      if (registro.data.code === 409) {
        CheckExpiracion();
      } else {
        if (registro.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje7"),
            imageUrl: "img/img_activada.png",
            imageHeight: 65,
            confirmButtonText: "Ok",
          });
          setShow("");
        }
      }
    }
  };
  const guardar = async (e) => {
    e.preventDefault();
    let hayErrores = false;
    tempDatosEmpleado.array_de_sedes = sedesSeleccionadas;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosEmpleado[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (hayErrores) {
      console.log(hayErrores);
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosEmpleado.lang = currentLanguage;
      const registro = await axios.post(
        URI_GUARDAR_EMPLEADO,
        tempDatosEmpleado,
        HeadersAuth
      );

      if (registro.data.errors) {
        const errores = registro.data.errors;
        
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje7"),
              imageUrl: "img/guardado-exitoso.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setShow("");
          }
        }
      }
    }
  };
  const borrar = async (e, id) => {
    e.preventDefault();

    let validarBorrar = MySwal.fire({
      title: t("mensajes.mensaje44"),
      text: t("mensajes.mensaje45"),
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });
    if ((await validarBorrar).isConfirmed) {
      window.scrollTo(0, 0);
      // const { id } = e.target;
      console.log(id)
      const URI_BORRAR_SEDE =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/borrar_empleado?id=" +
        id_empresa +
        "&ide=" +
        id;

      
      const borrar = await axios.delete(URI_BORRAR_SEDE, HeadersAuth);
      if (borrar.data.code === 401 || borrar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje10"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            if (show === "") {
              console.log("Hello");

              setShow("borrado");
            } else {
              setShow("");
            }
          }
        }
      }
    }
  };
  const cancelarInvite = async (e) => {
    e.preventDefault();
    let validarBorrar = MySwal.fire({
      title: t("mensajes.mensaje46"),
      text: t("mensajes.mensaje47"),
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });
    if ((await validarBorrar).isConfirmed) {
      window.scrollTo(0, 0);
      const { id } = e.target;
      const URI_BORRAR_SEDE =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/borrar_invitacion?id=" +
        id_empresa +
        "&idi=" +
        id;
      const borrar = await axios.delete(URI_BORRAR_SEDE, HeadersAuth);
      if (borrar.data.code === 401 || borrar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje10"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            if (show === "") {
              setShow("borrado");
            } else {
              setShow("");
            }
          }
        }
      }
    }
  };
  const limiteUsuarios = (e) => {
    e.preventDefault();
    Swal.fire({
      title: t("mensajes.mensaje48"),
      text: t("mensajes.mensaje49"),
      imageUrl: "img/lechuza-triste.png",
      imageHeight: 50,
      confirmButtonText: "Ok",
    });
  };
  return (
    <>
      <div
        className="row justify-content-center"
        style={{ marginBottom: "2rem" }}
      >
        <div className="col-10">
          {show === "editar" ? (
            <>
              <div className="panel-empresa p-3" style={{ marginTop: "2rem" }}>
                <h3 className="subtitulo-empresa" style={{ color: "#6d6d6d " }}>
                  {t("empresa.datosUsuarios.editarUsuario")}{" "}
                  {tempDatosEmpleadosMod}{" "}
                </h3>
                <h3 className="subtitulo-empresa" style={{ color: "#6d6d6d " }}>
                  {t("empresa.datosUsuarios.sedeAsociada")}{" "}
                </h3>
              </div>
              <form onSubmit={guardarEdicion}>
                <div className="row g-3 justify-content-center ms-4 me-4">
                  <div className="col-input-calculadoras">
                    <div className="col-input-calculadoras">
                      <Checkbox
                        style={{ color: "#6d6d6d " }}
                        indeterminate={
                          empleadoSedesSeleccionadas.length > 0 &&
                          empleadoSedesSeleccionadas.length < infoSedes.length
                        }
                        checked={
                          empleadoSedesSeleccionadas.length === infoSedes.length
                        }
                        onChange={
                          empleadoSedesSeleccionadas.length === infoSedes.length
                            ? handleDeselectAllDos
                            : handleSelectAllDos
                        }
                      >
                        {t("empresa.datosUsuarios.seleccionar")}
                      </Checkbox>
                    </div>
                    <Checkbox.Group
                      options={infoSedes.map((sede) => ({
                        label: sede.nombre,
                        value: sede.id_sede,
                      }))}
                      value={empleadoSedesSeleccionadas}
                      onChange={handleSedesChangeDos}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="boton-agregar-empresa" type="submit">
                      <span className="label-boton">
                        {" "}
                        {t("botones.editarUsuario")}
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : show === "nuevo" ? (
            <>
              <div className="panel-empresa card-empresa p-3">
                <h3 className="subtitulo-empresas">
                  {t("empresa.datosUsuarios.ingresarMail")}{" "}
                </h3>
                <form onSubmit={guardar}>
                  <div className="row ">
                    <div className=" col-md-3 col-input-calculadoras mt-3">
                      <Tooltip
                        title={!!errores.email && <span>{errores.email}</span>}
                        open={tooltipVisible.email}
                        color="#1CA6AF"
                      >
                        <input
                          type="email"
                          placeholder="example@unmail.com"
                          id="email"
                          className="form-control"
                          onChange={handleChange}
                          value={tempDatosEmpleado.email || ""}
                          required
                        ></input>
                      </Tooltip>
                    </div>
                    <div>
                      <div className="panel-empresa ">
                        <h3 className="subtitulo-empresas mt-3">
                          {t("empresa.datosUsuarios.seleccionarSede")}{" "}
                        </h3>
                      </div>
                      <div>
                        <Checkbox
                          className="checkbox"
                          indeterminate={
                            sedesSeleccionadas.length > 0 &&
                            sedesSeleccionadas.length < infoSedes.length
                          }
                          checked={
                            sedesSeleccionadas.length === infoSedes.length
                          }
                          onChange={
                            sedesSeleccionadas.length === infoSedes.length
                              ? handleDeselectAll
                              : handleSelectAll
                          }
                        >
                          {t("empresa.datosUsuarios.seleccionar")}
                        </Checkbox>
                      </div>
                      <Checkbox.Group
                        options={infoSedes.map((sede) => ({
                          label: sede.nombre,
                          value: sede.id_sede,
                        }))}
                        value={sedesSeleccionadas}
                        onChange={handleSedesChange}
                      />
                    </div>
                    <div className="mt-3 row-usuarios">
                      <button className="btnprincipal p-1 " type="submit">
                        <span className="label-boton">
                          {t("botones.agregarUsuario")}
                        </span>
                      </button>
                    </div>
                  </div>
                </form>{" "}
              </div>
            </>
          ) : (
            ""
          )}
          <div className="row mt-5">
            <div className="col-md-6 col-input-calculadoras">
              <div className="row align-items-center justify-content-between">
                <div className="col">
                  <Input
                    className="dominio"
                    placeholder="Email"
                    onChange={(e) => onSearch(e)}
                    style={{
                      width: 150,
                      height: 38,
                    }}
                    suffix={<SearchOutlined />}
                  />
                </div>
                <div className="col text-end">
                  {infoEmpleados.length + infoInvitaciones.length <
                  parseInt(plan.max_usuarios) ? (
                    <button
                      className="boton-agregar-empresa "
                      value="nuevo"
                      onClick={cambiarShow}
                    >
                      {t("botones.nuevoUsuario")}
                    </button>
                  ) : (
                    <button
                      className="boton-agregar-empresa  "
                      value="nuevo"
                      onClick={limiteUsuarios}
                    >
                      {t("botones.nuevoUsuario")}
                    </button>
                  )}
                </div>
              </div>
              <div className="card p-2 mt-4 col-input-calculadoras ">
                {infoEmpleados.length === 0 ? (
                  <img
                    src="img/sin-datos.svg"
                    className="text-center img-sin-datos"
                    style={{ width: "30%" }}
                  />
                ) : (
                  <List
                    itemLayout="horizontal"
                    dataSource={filteredList}
                    pagination={{
                      size: "small",
                      pageSize: 3,
                    }}
                    size="small"
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <button
                            className="boton-vehiculos"
                            onClick={cambiarShow}
                            id={item.id + "-empleados"}
                            value={"editar"}
                          >
                            <EditOutlined />
                          </button>,
                          <button
                            className="boton-vehiculos justify-content-end"
                            onClick={(e) => borrar(e, item.id)}
                            id={item.id}
                          >
                            <DeleteOutlined />
                          </button>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src=".././img/user.png" />}
                          title={item.email}
                        />
                      </List.Item>
                    )}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6 col-input-calculadoras">
              <div className="row ">
                <div className="col-md-6  col-input-calculadoras">
                  <h3
                    className="subtitulo-empresas"
                    style={{ marginTop: "0.5em" }}
                  >
                    {t("empresa.datosUsuarios.invitacionesPendientes")}
                  </h3>
                </div>
                <div className="col-md-6 text-end  col-input-calculadoras row-usuarios">
                  <Input
                    className="dominio"
                    placeholder="Email"
                    onChange={(e) => onSearchI(e)}
                    style={{
                      width: 180,
                      height: 40,
                    }}
                    suffix={<SearchOutlined />}
                  />
                </div>
              </div>
              <div className="card p-2 mt-4  col-input-calculadoras">
                {infoInvitaciones.length === 0 ? (
                  <img
                    src="img/sin-datos.svg"
                    className="text-center img-sin-datos"
                    style={{ width: "30%" }}
                  />
                ) : (
                  <List
                    itemLayout="horizontal"
                    dataSource={filteredListI}
                    pagination={{
                      size: "small",
                      pageSize: 3,
                    }}
                    size="small"
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <button
                            className="boton-vehiculos justify-content-end"
                            onClick={cancelarInvite}
                            id={item.id}
                          >
                            <CloseOutlined />
                          </button>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={<Avatar src=".././img/user.png" />}
                          title={
                            item.email +
                            ", " +
                            (parseInt(item.estado) === 0
                              ? "Pendiente"
                              : "Rechazada") +
                            ", " +
                            moment(`${item.fecha}`, "DD-YYYY-MM").format(
                              "DD/MM/YYYY"
                            )
                          }
                        />
                      </List.Item>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
