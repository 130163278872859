import axios from "axios";
import { useState, useRef, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import { Tooltip, Button, Tabs, Menu, Drawer} from "antd";
import { MenuOutlined } from '@ant-design/icons';
import withReactContent from "sweetalert2-react-content";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CardPlanes } from "../navegacion/CardsPlanes";
import BackButton from "../navegacion/boton-atras";
import ReCAPTCHA from "react-google-recaptcha";
import {
  validarSoloTexto,
  validarEmail,
  validarPassword,
  validarTextoNumerico,
  validarSelector,
} from "../validaciones";
import { traerDatos } from "../funciones_utiles";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;
// import dotenv  from 'dotenv'
const KEY_CAPTCHA = process.env.REACT_APP_CLAVE_RECAPTCHA;
const URI_REGISTRO =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/pre_register";
const URI_RUBROS =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/rubros_afuera";
const URI_PAISES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/paises_afuera";
const URI_PLANES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/planes_afuera";

const MySwal = withReactContent(Swal);
export const CompRegistroMini = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('preferredLanguage', lng);
	  };
  const recaptchaRef = useRef(null);

  const [cargaScreen, setCargaScreen] = useState(false);

  const [tipoSubscripcion, setTipoSubscripcion] = useState("");
  const [idSubscripcion, setIdSubscripcion] = useState("");
  const [precioDolar, setPrecioDolar] = useState("");
  const [nombrePlan, setNombrePlan] = useState("");
  const [URLpago, setURLpago] = useState("");

  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesRubros, setOpcionesRubros] = useState([]);
  const [opcionesParticulares, setOpcionesParticulares] = useState([]);
  const [opcionesEmpresas, setOpcionesEmpresas] = useState([]);

  const [tempDatosPerfil, setTempDatosPerfil] = useState({
    rubro: "",
    razon_social: "",
    pais: "",
    nombre: "",
    apellido: "",
    email: "",
    pass: "",
    pass_coincidir: "",
  });
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  useMemo(() => {
    (async () => {
      setOpcionesPaises(await traerDatos(URI_PAISES));
      setOpcionesRubros(await traerDatos(URI_RUBROS));
      setOpcionesParticulares(await traerDatos(URI_PLANES + "/1"));
      setOpcionesEmpresas(await traerDatos(URI_PLANES + "/2"));
      // setOpcionesAsesor(await traerDatos(URI_PLANES + "/3"));
    })();
  }, []);

  useEffect(() => {
    const camposPorTipoSub = {
      1: [
        { name: "nombre", validator: validarSoloTexto },
        { name: "apellido", validator: validarSoloTexto },
        { name: "email", validator: validarEmail },
        { name: "pass", validator: validarPassword },
        { name: "pass_coincidir", validator: validarPassword },
        { name: "pais", validator: validarSelector },
      ],
      2: [
        { name: "razon_social", validator: validarTextoNumerico },
        { name: "rubro", validator: validarSelector },
        { name: "email", validator: validarEmail },
        { name: "pass", validator: validarPassword },
        { name: "pass_coincidir", validator: validarPassword },
        { name: "pais", validator: validarSelector },
      ],
      3: [
        { name: "razon_social", validator: validarTextoNumerico },
        { name: "email", validator: validarEmail },
        { name: "pass", validator: validarPassword },
        { name: "pass_coincidir", validator: validarPassword },
        { name: "pais", validator: validarSelector },
      ],
    };

    const camposAAplicar =
      camposPorTipoSub[tipoSubscripcion] ||
      camposPorTipoSub[Object.keys(camposPorTipoSub)[0]] ||
      [];

    setTooltipVisible({
      nombre: false,
      apellido: false,
      razon_social: false,
      rubro: false,
      pais: false,
      email: false,
      pass: false,
      pass_coincidir: false,
    });
    setErrores(
      camposAAplicar.reduce((obj, campo) => ({ ...obj, [campo.name]: "" }), {})
    );
    setCampos(camposAAplicar);
  }, [tipoSubscripcion]);

  var [tooltipVisible, setTooltipVisible] = useState({});
  const [errores, setErrores] = useState({});
  const [campos, setCampos] = useState([]);

  const elejir = async (valor, id, precio, nombre, e) => {
    e.preventDefault();
    setTipoSubscripcion(valor);
    setIdSubscripcion(id);
    setPrecioDolar(precio);
    setNombrePlan(nombre);
  };

  const cleanVars = async () => {
    setIdSubscripcion("");
    setTipoSubscripcion("");
    setPrecioDolar("");
    setNombrePlan("");
    setTempDatosPerfil({
      rubro: "",
      razon_social: "",
      pais: "",
      nombre: "",
      apellido: "",
      email: "",
      pass: "",
      pass_coincidir: "",
    });
  };
  const paisTexto = async (id_buscar) => {
    const paisEncontrado = opcionesPaises.find(
      (pais) => pais.id === parseInt(id_buscar)
    );
    return paisEncontrado.paisnombre;
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setTempDatosPerfil({
      ...tempDatosPerfil,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor,t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };

    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const guardar = async (e) => {
    e.preventDefault();
    tempDatosPerfil.token = await recaptchaRef.current.executeAsync();
    tempDatosPerfil.tipoSub = tipoSubscripcion;
    tempDatosPerfil.idSub = idSubscripcion;
    tempDatosPerfil.precioDolar = precioDolar;
    tempDatosPerfil.nombrePlan = nombrePlan;
    tempDatosPerfil.textoPais = await paisTexto(parseInt(tempDatosPerfil.pais));

    // setTempDatosPerfil({
    //     ...tempDatosPerfil,
    //     token: await recaptchaRef.current.executeAsync()
    // })
    recaptchaRef.current.reset();

    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosPerfil[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo,t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (tempDatosPerfil.pass !== tempDatosPerfil.pass_coincidir) {
      setTooltipVisible((prevState) => ({
        ...prevState,
        pass: true,
      }));
      setErrores((prevState) => ({
        ...prevState,
        pass: t("mensajes.mensaje62"),
      }));
    } else {
      if (hayErrores) {
        setErrores(erroresTemp);
        return;
      } else {
        const registro = await axios.post(URI_REGISTRO, tempDatosPerfil);
        //navigate('/')
        if (registro.data.errors) {
          const errores = registro.data.errors;
          
          const msgError = errores.map((error) => {
            return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
          });
          MySwal.fire({
            html: `${msgError}`,
            imageUrl: "img/lechuza-triste.png",
            imageHeight: 55,
            confirmButtonText: "Ok",
          });
        } else {
          if (registro.data.code === 200) {
            setURLpago(registro.data.url);
          } else if (registro.data.code === 406) {
            cleanVars();
            MySwal.fire({
              html: t("mensajes.mensaje58"),
              imageUrl: "img/lechuza-triste.png",
              imageHeight: 55,
              confirmButtonText: "Ok",
            });
          }
        }
      }
    }
  };
  const irApago = async (url, e) => {
    e.preventDefault();
    setCargaScreen(true);
    cleanVars();
    window.location.href = url;
  };

  /*Información de las cards Particular-Empresa-Asesor */
  // const informacionItemsEmpresaGratis = [
  // 	"Hasta 3 empresas",
  // 	"Calculadoras limitadas",
  // 	"Información 3",
  // ];

  // const informacionItemsEmpresaPremium = [
  // 	"Característica premium 1",
  // 	"Característica premium 2",
  // 	"Información adicional",
  // ];

  // const informacionItemsAsesorGratis = [
  // 	"Información asesor",
  // 	"Información asesor",
  // 	"Información",
  // ];

  // const informacionItemsAsesorPremium = [
  // 	"Característica premium 11",
  // 	"Característica premium 2",
  // 	"Información adicional",
  // ];

  return (
    <div className="regsim body_calculadora">
    <div className="row menu-wrapper justify-content-between align-items-center">
      <div className="col-md-4  col-6 text-left">
        <img
          src="./img/logo-menu.png"
          alt=""
          className="img_logo"
          style={{ width: '10em' }}
        />
      </div>

      <div className="col-md-4 text-center d-none d-md-block">
        <ul className="ul-nuevo mt-2">
          <li className="li-nuevo">
            <a href="https://owl-track.com/" rel="noreferrer" target="_blank">
              {t("botones.inicio")}
            </a>
          </li>
          <li className="li-nuevo">
            <a href="https://owl-track.com/blog/" rel="noreferrer" target="_blank">
              {t("botones.noticias")}
            </a>
          </li>
          <li className="li-nuevo">
            <a href="https://owl-track.com/contacto/" rel="noreferrer" target="_blank">
              {t("botones.contacto")}
            </a>
          </li>
          <li className="li-nuevo">
            <a href="/signup" rel="noreferrer" target="_blank">
              {t("botones.calculadora")}
            </a>
          </li>
        </ul>
      </div>

      <div className="col-md-4 text-right d-none d-md-block">
        <div className="contenendor-idiomas" style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
        <div
              className="bandera"
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
             
              }}
              onClick={() => changeLanguage("en")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/en.png"
                alt="English"
                className="bandera"
              />
              <span className="texto-bandera" style={{ fontWeight: "700" }}>EN</span>
            </div>
            <div
              className="bandera "
              style={{
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
             
              }}
              onClick={() => changeLanguage("es")}
            >
              <img
                style={{ marginRight: "0.5em" }}
                src="./img/esp.png"
                alt="Español"
                className="bandera"
              />
              <span className="texto-bandera" style={{ fontWeight: "700" }}>ES</span>
            </div>
        </div>
      </div>

      <div className="col-6 d-block d-md-none text-right ">
        <Button
        className="btn-burguer-menu"
          type="primary"
          onClick={showDrawer}
          icon={<MenuOutlined />}
        />
      </div>
    </div>

    <Drawer
      title="Owl Track"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
    >
      <Menu mode="vertical">
        <Menu.Item key="1">
          <a href="https://owl-track.com/" rel="noreferrer" target="_blank">
            {t("botones.inicio")}
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href="https://owl-track.com/blog/" rel="noreferrer" target="_blank">
            {t("botones.noticias")}
          </a>
        </Menu.Item>
        <Menu.Item key="3">
          <a href="https://owl-track.com/contacto/" rel="noreferrer" target="_blank">
            {t("botones.contacto")}
          </a>
        </Menu.Item>
        <Menu.Item key="4">
          <a href="/signup" rel="noreferrer" target="_blank">
            {t("botones.calculadora")}
          </a>
        </Menu.Item>
        <Menu.Item key="5">
          <div
            className="bandera"
            style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => changeLanguage('es')}
          >
            <img style={{ marginRight: '0.5em' }} src="./img/esp.png" alt="Español" className="bandera" />
            <span style={{ fontWeight: '500' }}>Español</span>
          </div>
        </Menu.Item>
        <Menu.Item key="6">
          <div
            className="bandera"
            style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => changeLanguage('en')}
          >
            <img style={{ marginRight: '0.5em' }} src="./img/eng.png" alt="English" className="bandera" />
            <span style={{ fontWeight: '500' }}>English</span>
          </div>
        </Menu.Item>
      </Menu>
    </Drawer>
      {cargaScreen ? (
        <>
          <div className="d-flex justify-content-center"></div>
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner"></div>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <p className="cargando-texto">
              <b> {t("registro.redirigiendo")}</b>
            </p>
          </div>
        </>
      ) : tipoSubscripcion === "" ? (
        <>
          <div className="row d-flex container-fluid justify-content-center align-items-center ">
		  <BackButton />
            <Tabs
              defaultActiveKey="1"
              className="custom-tabs align-items-center justify-content-center"
            >
              <TabPane tab={t("planes.texto1")} key="1">
              <div className="row justify-content-center">
                <CardPlanes 
                  nombreImagen={"usuario_particular.png"}
                  arrPlanes={opcionesParticulares}
                  funcion={elejir}
                />
              {/* </TabPane>
              <TabPane tab="Empresas" key="2"> */}
                <CardPlanes
                  nombreImagen={"usuario_empresa.png"}
                  arrPlanes={opcionesEmpresas}
                  funcion={elejir}
                />
                </div>
              </TabPane>
              {/* <TabPane tab="Asesores" key="3">
                <CardPlanes
                  nombreImagen={"usuario_asesor.png"}
                  arrPlanes={opcionesAsesor}
                  funcion={elejir}
                />
              </TabPane> */}
            </Tabs>
          </div>
        </>
      ) : (
        <>
          <div className="">
            <div className="container-login-nuevo text-center p-0">
            <BackButton />
              <div className="row p-0 m-0">
               
                <div className="col-md-6 col-login-form justify-content-center align-items-center">
                  <div className="content">
                    <h1 className="titulo-registro">{t("home.titulo")}</h1>
                    <h3 className="subtitulo-registro">
                      {" "}
                      {t("home.subtitulo")}
                    </h3>
                    <br></br>

                    <p className="text-login p-0">
                    {t("home.texto1")}
                    </p>
                    <p className="text-login">
                      {" "}
                      {t("home.texto2")}
                      <br></br>
                {t("home.texto3")}
                    </p>
                  </div>
                </div>
                {URLpago === "" ? (
                  <div className="col-md-6 col-login-form mt-3 mb-3 justify-content-center align-items-center">
                    <div className="form-container">
                      <div className="form-btn-login">
                        <span>{t("registro.registrarme")}</span>
                      </div>

                      <form
                        className="form-login"
                        id="RegForm"
                        onSubmit={guardar}
                      >
                        {tipoSubscripcion === 1 && (
                          <>
                            <Tooltip
                              title={
                                !!errores.nombre && (
                                  <span>{errores.nombre}</span>
                                )
                              }
                              open={tooltipVisible.nombre}
                              color="#1CA6AF"
                            >
                              <input
                                type="text"
                                placeholder={t("registro.nombre")}
                                id="nombre"
                                className="input-login"
                                onChange={handleChange}
                                value={tempDatosPerfil.nombre || ""}
                                required
                              ></input>
                            </Tooltip>
                            <Tooltip
                              title={
                                !!errores.apellido && (
                                  <span>{errores.apellido}</span>
                                )
                              }
                              open={tooltipVisible.apellido}
                              color="#1CA6AF"
                            >
                              <input
                                type="text"
                                placeholder={t("registro.apellido")}
                                id="apellido"
                                className="input-login"
                                onChange={handleChange}
                                value={tempDatosPerfil.apellido || ""}
                                required
                              ></input>
                            </Tooltip>
                          </>
                        )}
                        {(tipoSubscripcion === 2 || tipoSubscripcion === 2) && (
                          <>
                            <Tooltip
                              title={
                                !!errores.razon_social && (
                                  <span>{errores.razon_social}</span>
                                )
                              }
                              open={tooltipVisible.razon_social}
                              color="#1CA6AF"
                            >
                              <input
                                type="text"
                                placeholder={t("registro.razonSocial")}
                                id="razon_social"
                                className="input-login"
                                onChange={handleChange}
                                value={tempDatosPerfil.razon_social || ""}
                                required
                              ></input>
                            </Tooltip>
                            <Tooltip
                              title={
                                !!errores.rubro && <span>{errores.rubro}</span>
                              }
                              open={tooltipVisible.rubro}
                              color="#1CA6AF"
                            >
                              <select
                                className="input-login"
                                id="rubro"
                                onChange={handleChange}
                                value={tempDatosPerfil.rubro || ""}
                              >
                                <option value="">{t("registro.rubro")}</option>
                                {opcionesRubros.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.rubro}
                                  </option>
                                ))}
                              </select>
                            </Tooltip>
                          </>
                        )}
                        {(tipoSubscripcion === 3 || tipoSubscripcion === 3) && (
                          <>
                            <Tooltip
                              title={
                                !!errores.razon_social && (
                                  <span>{errores.razon_social}</span>
                                )
                              }
                              open={tooltipVisible.razon_social}
                              color="#1CA6AF"
                            >
                              <input
                                type="text"
                                placeholder={t("registro.razonSocial")}
                                id="razon_social"
                                className="input-login"
                                onChange={handleChange}
                                value={tempDatosPerfil.razon_social || ""}
                                required
                              ></input>
                            </Tooltip>
                          </>
                        )}
                        <Tooltip
                          title={!!errores.pais && <span>{errores.pais}</span>}
                          open={tooltipVisible.pais}
                          color="#1CA6AF"
                        >
                          <select
                            className="input-login"
                            id="pais"
                            onChange={handleChange}
                            value={tempDatosPerfil.pais || ""}
                          >
                            <option value="">{t("registro.pais")}</option>
                            {opcionesPaises.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.paisnombre}
                              </option>
                            ))}
                          </select>
                        </Tooltip>
                        <Tooltip
                          title={
                            !!errores.email && <span>{errores.email}</span>
                          }
                          open={tooltipVisible.email}
                          color="#1CA6AF"
                        >
                          <input
                            type="email"
                            placeholder={t("recuperar.placeholderEmail")}
                            id="email"
                            className="input-login"
                            onChange={handleChange}
                            value={tempDatosPerfil.email || ""}
                            required
                          ></input>
                        </Tooltip>
                        <Tooltip
                          title={!!errores.pass && <span>{errores.pass}</span>}
                          open={tooltipVisible.pass}
                          color="#1CA6AF"
                        >
                          <div className="password-input">
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder={t("home.contra")}
                              id="pass"
                              className="input-login-contraseña"
                              onChange={handleChange}
                              value={tempDatosPerfil.pass || ""}
                              required
                            ></input>
                            <span
                              className="password-toggle"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <FaEye onClick={togglePasswordVisibility} />
                              ) : (
                                <FaEyeSlash
                                  onClick={togglePasswordVisibility}
                                />
                              )}
                            </span>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            !!errores.pass_coincidir && (
                              <span>{errores.pass_coincidir}</span>
                            )
                          }
                          open={tooltipVisible.pass_coincidir}
                          color="#1CA6AF"
                        >
                          <div className="password-input">
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder={t("home.contra")}
                              id="pass_coincidir"
                              className="input-login-contraseña"
                              onChange={handleChange}
                              value={tempDatosPerfil.pass_coincidir || ""}
                              required
                            ></input>
                            <span
                              className="password-toggle"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <FaEye onClick={togglePasswordVisibility} />
                              ) : (
                                <FaEyeSlash
                                  onClick={togglePasswordVisibility}
                                />
                              )}
                            </span>
                          </div>
                        </Tooltip>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={KEY_CAPTCHA}
                          size="invisible"
                        />
                        <button
                          type="submit"
                          value="Registrarme"
                          className="btn-login"
                        >
                          {t("botones.registrarme")}
                        </button>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6 col-login-form mt-5 mb-5">
                    <div className="form-container-checkout"  style={{width:"20em"}}>
                      <div className="form-btn-login ">
                        <h1 className="titulo-checkout">{t("registro.checkout")}</h1>
                      </div>
                      <hr className="linea-separadora" /> <br></br>      
					  <div className="text-left align-items-left">                                                   
                        <span className="span-checkout-dato">
                            {tempDatosPerfil.nombre +
                              "" +
                              tempDatosPerfil.apellido ||
                              tempDatosPerfil.razon_social}
                          </span>{" "}
                          <br></br>                      
                          <span className="span-checkout-dato">{tempDatosPerfil.email}</span>     
						  <br></br>                         
                          <span className="span-checkout-dato">{tempDatosPerfil.textoPais}</span>     
						  <br></br>   						                 
                          <span className="span-checkout-dato">{tempDatosPerfil.nombrePlan}</span>   
                           
						  <br></br>                        					                      
                          <span className="span-checkout-dato">USD {tempDatosPerfil.precioDolar} {t("planes.año")}</span>                      
                     	  <br></br>
						   </div>
                      {/* RUBRO RUBRO RUBRO RUBRO RUBRO RUBRO */}
                      <Button className="btn-checkout" onClick={(e) => irApago(URLpago, e)}>
                        {" "}
                        {t("botones.pagar")}{" "}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

// export default CompRegistroMini;
