import { Checkbox, Avatar, List, Input, Tooltip } from "antd";
import { SearchOutlined,DeleteOutlined,EditOutlined} from "@ant-design/icons";
import axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
} from "../../funciones_utiles";
import { validarEmail } from "../../validaciones";
import { RiSeedlingLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

export const CompDatosEmpleadosAsesor = ({ id_empresa, uri_sxe }) => {
  const [t,i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [tempDatosEmpleado, setTempDatosEmpleado] = useState({});
  const [idEmpleado, setIdEmpleado] = useState("");
  const [infoSedes, setInfoSedes] = useState();
  const [infoEmpleados, setInfoEmpleados] = useState();
  const URI_GUARDAR_EMPLEADO =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/invitar_usuario_asesor?id=" +
    id_empresa;
  const URI_EMPLEADOS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_empleados_asesor?id=" +
    id_empresa;
  const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
  const [
    empleadoSedesSeleccionadasObjeto,
 
  ] = useState({});
  const [empleadoSedesSeleccionadas, setEmpleadoSedesSeleccionadas] = useState(
    []
  );
  const [tempDatosEmpleadosMod, setTempDatosEmpleadosMod] = useState();
  const URI_EMPRESAS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/empresas_por_usuario_asesor";
  const [inputValue, setInputValue] = useState("");
  const [filteredList, setFilteredList] = useState();
  // console.log(URI_VEHICULOS_EMPRESA_UNICO)
  const [show, setShow] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setInfoSedes(await traerDatos(URI_EMPRESAS));
        setInfoEmpleados(await traerDatos(URI_EMPLEADOS));

        if (show === "editar" || show === "nuevo") {
          let URI_EMPLEADO_UNICO;
          if (show === "nuevo") {
            setTempDatosEmpleado({});
          } else if (show === "editar" && idEmpleado !== "") {
            URI_EMPLEADO_UNICO =
              process.env.REACT_APP_DOMINIO +
              process.env.REACT_APP_PUERTO +
              "/empresas_empleado_asesor?id=" +
              id_empresa +
              "&ide=" +
              idEmpleado;
            const empleadoSedesSeleccionadas = await traerDatos(
              URI_EMPLEADO_UNICO
            );
            setEmpleadoSedesSeleccionadas(empleadoSedesSeleccionadas.empresas);
            setTempDatosEmpleadosMod(empleadoSedesSeleccionadas.email);
          }
          setSedesSeleccionadas([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [show, id_empresa, idEmpleado, URI_EMPRESAS, URI_EMPLEADOS]);
  var [tooltipVisible, setTooltipVisible] = useState({
    email: false,
  });
  const [errores, setErrores] = useState({
    email: "",
  });
  const campos = [{ name: "email", validator: validarEmail }];
  //Buscador
  useEffect(() => {
    // setFilteredList(infoVehiculos)
    if (inputValue) {
      setFilteredList(
        filteredList.filter(
          (item) =>
            item.nombre
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredList(infoEmpleados);
    }
  }, [inputValue, infoEmpleados]);

  const cambiarShow = async (e) => {
    e.stopPropagation();
    window.scrollTo(0, 0);
    const { id, value } = e.target;
    setShow("");
    setShow(value);

    let idSplit = id.split("-");

    if (value === "editar") {
      setIdEmpleado(idSplit[0]);
    }
  };

  const handleSedesChange = (sedesSeleccionadas) => {
    setSedesSeleccionadas(sedesSeleccionadas);
  };

  const handleSelectAll = () => {
    const todasLasSedes = infoSedes.map((sede) => sede.id_empresa);

    setSedesSeleccionadas(todasLasSedes);
  };

  const handleDeselectAll = () => {
    setSedesSeleccionadas([]);
  };

  //EDICION CHANGE de CADA CHECKBOX

  const handleSedesChangeDos = (sedesSeleccionadas) => {
    setEmpleadoSedesSeleccionadas(sedesSeleccionadas);
  };

  const handleSelectAllDos = () => {
    const todasLasSedes = infoSedes.map((sede) => sede.id_empresa);

    setEmpleadoSedesSeleccionadas(todasLasSedes);
  };

  const handleDeselectAllDos = () => {
    setEmpleadoSedesSeleccionadas([]);
  };
  function onSearch(e) {
    setInputValue(e.target.value);
  }

  const handleChange = async (e) => {
    const { id, value } = e.target;

    setTempDatosEmpleado({
      ...tempDatosEmpleado,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor,t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };

    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };
  const guardarEdicion = async (e) => {
    e.preventDefault();
    empleadoSedesSeleccionadasObjeto.array_de_empresas =
      empleadoSedesSeleccionadas;
    const URI_UPDATEAR_EMPLEADO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/update_empresas_empleado?id=" +
      id_empresa +
      "&idu=" +
      idEmpleado;

    const registro = await axios.post(
      URI_UPDATEAR_EMPLEADO,
      empleadoSedesSeleccionadasObjeto,
      HeadersAuth
    );

    if (registro.data.errors) {
      const errores = registro.data.errors;
      
      const msgError = errores.map((error) => {
        return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
      });
      MySwal.fire({
        html: `${msgError}`,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      if (registro.data.code === 409) {
        CheckExpiracion();
      } else {
        if (registro.data.code === 200) {
          MySwal.fire({
            text: "Guardado exitoso",
            imageUrl: "img/guardado-exitoso.svg",
            imageHeight: 65,
            confirmButtonText: "Ok",
          });
          setShow("");
        }
      }
    }
  };
  const guardar = async (e) => {
    e.preventDefault();
    let hayErrores = false;
    tempDatosEmpleado.array_de_empresas = sedesSeleccionadas;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosEmpleado[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo,t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosEmpleado.lang = currentLanguage;
      const registro = await axios.post(
        URI_GUARDAR_EMPLEADO,
        tempDatosEmpleado,
        HeadersAuth
      );

      if (registro.data.errors) {
        const errores = registro.data.errors;
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              text: "Guardado exitoso",
              imageUrl: "img/guardado-exitoso.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setShow("");
          }
        }
      }
    }
  };
  const borrar = async (e) => {
    e.preventDefault();

    let validarBorrar = MySwal.fire({
      title: "•	¿Esta seguro que desea borrar?",
      text: "Sera imposible revertir esto, se borrara el usuario y toda su información, ¿desea continuar?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    });
    if ((await validarBorrar).isConfirmed) {
      window.scrollTo(0, 0);
      const { id } = e.target;
      const URI_BORRAR_SEDE =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/borrar_empleado_asesor?id=" +
        id_empresa +
        "&ide=" +
        id;
      const borrar = await axios.delete(URI_BORRAR_SEDE, HeadersAuth);
      if (borrar.data.code === 401 || borrar.data.code === 402) {
        MySwal.fire({
          text: "Imposible realizar esa acción",
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              text: "Borrado exitoso",
              imageUrl: "img/icono-eliminado.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            if (show === "") {
              setShow("borrado");
            } else {
              setShow("");
            }
          }
        }
      }
    }
  };
  return (
    <>
      {show === "editar" ? (
        <>
          <div className="col-input-calculadoras">
            <div
              className="contenedor-sedes "
              style={{ marginTop: "1em", marginBottom: "1em" }}
            >
              <RiSeedlingLine
                style={{ float: "left", marginRight: "10px", color: "#1CA6AF" }}
              />
              <h3 className="subtitulo-empresas">
                {" "}
                Editar usuario:{tempDatosEmpleadosMod}
              </h3>
            </div>
          </div>
          <div className="col-input-calculadoras">
            <form onSubmit={guardarEdicion}>
              <div className="row g-3 justify-content-center ms-4 me-4">
                <div>
                  <div>
                    <Checkbox
                      indeterminate={
                        empleadoSedesSeleccionadas.length > 0 &&
                        empleadoSedesSeleccionadas.length < infoSedes.length
                      }
                      checked={
                        empleadoSedesSeleccionadas.length === infoSedes.length
                      }
                      onChange={
                        empleadoSedesSeleccionadas.length === infoSedes.length
                          ? handleDeselectAllDos
                          : handleSelectAllDos
                      }
                    >
                      Seleccionar todas
                    </Checkbox>
                  </div>
                  <Checkbox.Group
                    options={infoSedes.map((sede) => ({
                      label: sede.razon_social,
                      value: sede.id_empresa,
                    }))}
                    value={empleadoSedesSeleccionadas}
                    onChange={handleSedesChangeDos}
                  />
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "2em" }}
                >
                  <button className="boton-agregar-empresa" type="submit">
                    <span className="label-boton">Editar Usuario</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : show === "nuevo" ? (
        <>
          <div className="panel-empresa ">
            <RiSeedlingLine
              style={{ float: "left", marginRight: "10px", color: "#1CA6AF" }}
            />
            <h3 className="subtitulo-empresas">
              Ingresa el email del usuario que queres incoporar y le llegará una
              invitación{" "}
            </h3>
          </div>
          <form onSubmit={guardar}>
            <div className="row  justify-content-center ">
              <div className="col-10 col-md-5">
                <Tooltip
                  title={!!errores.email && <span>{errores.email}</span>}
                  open={tooltipVisible.email}
                  color="#1CA6AF"
                >
                  <input
                    type="email"
                    placeholder="example@unmail.com"
                    id="email"
                    className="form-control"
                    
                    onChange={handleChange}
                    value={tempDatosEmpleado.email || ""}
                    required
                  ></input>
                </Tooltip>
              </div>
              <div>
                <div
                  className="panel-empresa "
                  style={{  marginBottom: "2rem" }}
                >
                  <RiSeedlingLine
                    style={{
                      float: "left",
                      marginRight: "10px",
                      color: "#1CA6AF",
                    }}
                  />
                  <h3 className="subtitulo-empresas">
                    Selecciona a qué sede queres asociar a tu usuario{" "}
                  </h3>
                </div>
                <div>
                  <Checkbox
                    indeterminate={
                      sedesSeleccionadas.length > 0 &&
                      sedesSeleccionadas.length < infoSedes.length
                    }
                    checked={sedesSeleccionadas.length === infoSedes.length}
                    onChange={
                      sedesSeleccionadas.length === infoSedes.length
                        ? handleDeselectAll
                        : handleSelectAll
                    }
                  >
                    Seleccionar todas
                  </Checkbox>
                </div>
                <Checkbox.Group
                  options={infoSedes.map((sede) => ({
                    label: sede.razon_social,
                    value: sede.id_empresa,
                  }))}
                  value={sedesSeleccionadas}
                  onChange={handleSedesChange}
                />
              </div>
              <div
                className=""
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                <button className="boton-agregar-empresa " type="submit">
                  <span className="label-boton ">Agregar Usuario</span>
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        ""
      )}

      <div className="contenedor-sedes ">
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "0.8em" }}
        >
          <h3 className="subtitulo-empresas">
            {" "}
            <RiSeedlingLine
              style={{ float: "left", marginRight: "10px", color: "#1CA6AF" }}
              className="icono-hojita"
            />
            Tus usuarios:
          </h3>
          <Input
            className="dominio"
            placeholder="Nombre"
            onChange={(e) => onSearch(e)}
            style={{
              width: 200,
            }}
            suffix={<SearchOutlined />}
          />
          <button
            className="boton-agregar-empresa"
            value="nuevo"
            onClick={cambiarShow}
            style={{ marginTop: "0.3em" }}
          >
            <span className="label-boton">Nuevo Usuario</span>
          </button>
        </div>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={filteredList}
        pagination={{
          size: "small",
          pageSize: 5,
        }}
        size="small"
        renderItem={(item) => (
          <List.Item
            actions={[
              <button
                className="boton-vehiculos"
                onClick={cambiarShow}
                id={item.id + "-empleados"}
                value={"editar"}
              >
                <EditOutlined />
              </button>,
              <button
                className="boton-vehiculos justify-content-end"
                onClick={borrar}
                id={item.id}
              >
               <DeleteOutlined />
              </button>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src=".././img/user.png" />}
              title={item.email}
            />
          </List.Item>
        )}
      />
    </>
  );
};
