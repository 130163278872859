import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CompRegistroMini } from "../gestion_usuarios/registroSimple";
import { CompLoginMini } from "../gestion_usuarios/login";
import { CompRecuperar } from "../gestion_usuarios/recuperarContrasenia";
import { PagoCheck } from "../pagos/pagoCheck";
import { PagoCheckError } from "../pagos/pagoCheckError";
import { PagoCheckFinal } from "../pagos/pagoCheckFinal";
import { CompGeneral } from "./rutasSeguras";
import { SesionProvider } from "./sesionContexto";
import { Footer } from "../navegacion/Footer";
import { useTranslation } from "react-i18next";

export const CompRutas = () => {
	
	const ProtectedRoute = ({ children }) => {
		const token = localStorage.getItem("valid_session");
		if (!token) {
			return <Navigate to="/signin" replace />;
		} else {
			return children;
		}
	};
	const YaLogueado = ({ children }) => {
		const token = localStorage.getItem("valid_session");
		if (token) {
			return <Navigate to="/" replace />;
		} else {
			return children;
		}
	};
	return (
		<div className="body_calculadora">
			
			<BrowserRouter>
				<Routes>
					<Route
						path="/signup"
						element={
							<YaLogueado>
								<CompRegistroMini />
							</YaLogueado>
						}
					/>
					<Route
						path="/signin"
						element={
							<YaLogueado>
								<CompLoginMini />
							</YaLogueado>
						}
					/>
					<Route
						path="/checkout/:estado/:estado_dos/:estado_pago/:id_preregistro/data"
						element={
							<YaLogueado>
								<PagoCheck />
							</YaLogueado>
						}
					/>
					<Route
						path="/signup/exito"
						element={
							<YaLogueado>
								<PagoCheckFinal />
							</YaLogueado>
						}
					/>
					<Route
						path="/signup/error"
						element={
							<YaLogueado>
								<PagoCheckError />
							</YaLogueado>
						}
					/>

					<Route
						path="/recuperar"
						element={
							<YaLogueado>
								<CompRecuperar />
							</YaLogueado>
						}
					/>
					<Route
						path="/*"
						element={
							<ProtectedRoute>
								<SesionProvider>
									<CompGeneral />
								</SesionProvider>
							</ProtectedRoute>
						}
					/>
					{/* <Route path='/home' element={ <CompCalculadoras /> } /> */}
					{/* <Route path="*" element={<NotFound404 />} /> */}
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	);
};
