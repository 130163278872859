import { BtnAtras } from "../navegacion/btn_atras";
import { useParams } from "react-router-dom";
import { NotFound404 } from "../navegacion/404_not_found";
import { Tabs } from "antd";
import { CompDatosEmpresa } from "./componentes_empresa/datosEmpresa";
import { CompDatosSede } from "./componentes_empresa/datosSede";
import { CompDatosSedes } from "./componentes_empresa/datosSedes";
import { CompDatosEmpleados } from "./componentes_empresa/datosEmpleados";
import { CompDatosSubscripciones } from "./componentes_empresa/datosSubscripciones";
import { CompDatosPagos } from "./componentes_empresa/datosPagos";
import { traerDatos } from "../funciones_utiles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const CompPanelEmpresa = ({ sesion }) => {
  const [t] = useTranslation("global");
  let { idEmpresa, tabs } = useParams();
  // console.log(idEmpresa);
  let tabsNum;
  
  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(idEmpresa)
  );
  plan ?
  plan.max_usuarios > 1 ?
  tabsNum = {
    subscripciones:"4",
    usuarios:"3",
    empresa:"1",
    sede: "2",
    historial: "5"
  }
  :
  tabsNum = {
    subscripciones:"4",
    empresa:"1",
    sede: "2",
    historial: "5"
  }
  :
  tabsNum = {}
  
  const [activeTabKey, setActiveTabKey] = useState(tabsNum[tabs] || "1");
  const onChangeTab = (key) => {
    setActiveTabKey(key)
  };
  const URI_SEDES_EMPRESA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/sedes_por_empresa?id=" +
    idEmpresa +
    "&limit=" +
    sesion.maxSedes;

  const [losItem, setLosItem] = useState([]);

  useEffect(() => {
    if (plan) {
      if (parseInt(plan.max_sedes) < 2) {
        (async () => {
          const data = await traerDatos(URI_SEDES_EMPRESA);
          if (data.length > 0) {
            setLosItem([
              {
                key: tabsNum.empresa,
                label: (
                  <span className="ant-tabs-tab">
                    <h6 className="texto-tabs"> {t("empresa.empresa")}</h6>
                  </span>
                ),
                children: <CompDatosEmpresa id_empresa={idEmpresa} />,
              },
              {
                key: tabsNum.sede,
                label: (
                  <span className="ant-tabs-tab">
                    <h6 className="texto-tabs"> {t("empresa.sedes")}</h6>
                  </span>
                ),
                children: (
                  <CompDatosSede
                    id_empresa={idEmpresa}
                    id_sede={data[0].id_sede}
                  />
                ),
              },
              {
                key: tabsNum.subscripciones,
                label: (
                  <span className="ant-tabs-tab">
                    <h6 className="texto-tabs"> {t("empresa.suscripcion")}</h6>
                  </span>
                ),
                children: (
                  <CompDatosSubscripciones
                    sesion={sesion}
                    id_empresa={idEmpresa}
                  />
                ),
              },
              {
                key: tabsNum.historial,
                label: (
                  <span className="ant-tabs-tab">
                    <h6 className="texto-tabs">  {t("empresa.historial")}</h6>
                  </span>
                ),
                children: (
                  <CompDatosPagos
                    sesion={sesion}
                    id_empresa={idEmpresa}
                  />
                ),
              },
            ]);
          } else {
            setLosItem([
              {
                key: tabsNum.empresa,
                label: t("empresa.empresa"),

                children: <CompDatosEmpresa id_empresa={idEmpresa} />,
              },
              {
                key: tabsNum.sede,
                label: t("empresa.sedes"),
                children: null,
              },
              {
                key: tabsNum.subscripciones,
                label: t("empresa.suscripcion"),
                children: (
                  <CompDatosSubscripciones
                    sesion={sesion}
                    id_empresa={idEmpresa}
                  />
                ),
              },
              {
                key: tabsNum.historial,
                label: t("empresa.historial"),
                children: (
                  <CompDatosPagos
                    sesion={sesion}
                    id_empresa={idEmpresa}
                  />
                ),
              },
            ]);
          }
        })();
      } else if (
        parseInt(plan.max_sedes) > 1 &&
        parseInt(plan.max_usuarios) > 1
      ) {
        setLosItem([
          {
            key: tabsNum.empresa,
            label: t("empresa.empresa"),
            children: <CompDatosEmpresa id_empresa={idEmpresa} />,
          },
          {
            key: tabsNum.sede,
            label:  t("empresa.sedes"),
            children: (
              <CompDatosSedes
                sesion={sesion}
                id_empresa={idEmpresa}
                uri_sxe={URI_SEDES_EMPRESA}
              />
            ),
          },
          {
            key: tabsNum.usuarios,
            label:  t("empresa.usuarios"),
            children: (
              <CompDatosEmpleados
                sesion={sesion}
                id_empresa={idEmpresa}
                uri_sxe={URI_SEDES_EMPRESA}
              />
            ),
          },
          {
            key: tabsNum.subscripciones,
            label:  t("empresa.suscripcion"),
            children: (
              <CompDatosSubscripciones
                sesion={sesion}
                id_empresa={idEmpresa}
              />
            ),
          },
          {
            key: tabsNum.historial,
            label:  t("empresa.historial"),
            children: (
              <CompDatosPagos
                sesion={sesion}
                id_empresa={idEmpresa}
              />
            ),
          },
        ]);
      } else if (
        parseInt(plan.max_sedes) > 1 &&
        parseInt(plan.max_usuarios) < 2
      ) {
        setLosItem([
          {
            key: tabsNum.empresa,
            label: t("empresa.empresa"),
            children: <CompDatosEmpresa id_empresa={idEmpresa} />,
          },
          {
            key: tabsNum.sede,
            label: t("empresa.sedes"),
            children: (
              <CompDatosSedes
                sesion={sesion}
                id_empresa={idEmpresa}
                uri_sxe={URI_SEDES_EMPRESA}
              />
            ),
          },
          {
            key: tabsNum.subscripciones,
            label: ("empresa.suscripcion"),
            children: (
              <CompDatosSubscripciones
                sesion={sesion}
                id_empresa={idEmpresa}
              />
            ),
          },
          {
            key: tabsNum.historial,
            label: t("empresa.historial"),
            children: (
              <CompDatosPagos
                sesion={sesion}
                id_empresa={idEmpresa}
              />
            ),
          },
        ]);
      }
    }
  }, [URI_SEDES_EMPRESA, idEmpresa, plan, sesion]);

  return (
    <div className="fondoempresa" >
      <>
        {plan ? (
          <>
            <div className="panel-empresa container-fluid  justify-content-center align-items-center " >
          
              <Tabs
                defaultActiveKey="1"
                tabPosition={"top"}
                items={losItem}
                activeKey={activeTabKey}
                onChange={onChangeTab}

                className="tabs-panel-empresa "
              />
            </div>
          </>
        ) : (
          // <Navigate to="../" replace />
          <NotFound404 />
        )}
      </>
    </div>
  );
};
