import React from "react";
import { Card } from "antd";

export const CardHome = ({ funcion, nombre, nombreImagen, value }) => {
  return (
    <>
      <Card
        className="card-home"
        value={value}
        onClick={funcion}
        title={nombre}
        hoverable
        style={{
          width: "16em", 
          height: "20em", 
          border: "2px solid #89BEC2",
          backgroundImage: `url("./img/${nombreImagen}")`,
          backgroundSize: "cover",
          marginBottom: "1em", 
        }}
      ></Card>
    </>
  );
};
