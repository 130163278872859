
import { Link} from 'react-router-dom'
import { useTranslation } from "react-i18next";
export const NotFound404 = () => {
    const [t] = useTranslation("global");
    return (
        <div className="contenedor-error mt-5">
                <div className="d-flex justify-content-center">
                    <img className="img-error" src="/./img/error.png" alt=""/>
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <h1 className="titulo-error">{t("mensajes.mensaje64")}</h1>
                </div>
                <div className="d-flex justify-content-center">
                    <Link to="/" className="boton-resultado boton-select d-flex align-items-center justify-content-center">
                    {t("botones.irAHome")}</Link>
                </div>
        </div>
    )

}