import React from "react";
import { Line } from "react-chartjs-2";
import randomColor from "randomcolor";
import moment from "moment";
import { Chart, registerables } from "chart.js";
import { useTranslation } from "react-i18next";

Chart.register(...registerables);

export const ChartEmisionesMulti = ({ data, year }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Mapeo de nombres de meses en diferentes idiomas
  const monthNames = {
    es: [
      `Ene`, `Feb`, `Mar`, `Abr`, `May`, `Jun`, `Jul`, `Ago`, `Sep`, `Oct`, `Nov`, `Dic`
    ],
    en: [
      `Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`
    ]
  };


  const yearMonths = monthNames[currentLanguage].map((month, index) => ({
    label: `${month}-${year}`,
    month: (index + 1).toString().padStart(2, '0')
  }));

  const result = Object.values(
    data.reduce((acc, { fecha, kgcodose_emitido, combustible, combustible_en, id_combustible }) => {
      const labelObj = moment(fecha).format("MMM-YYYY");
      const key = labelObj + "" + (currentLanguage === 'es' ? combustible : combustible_en) + "" + id_combustible;
      acc[key] = acc[key] || {
        labelObj,
        fecha: new Date(fecha),
        kgcodose_emitido: 0,
        combustible: currentLanguage === 'es' ? combustible : combustible_en,
        id_combustible,
      };
      acc[key].kgcodose_emitido += kgcodose_emitido || 0;
      return acc;
    }, {})
  );

  const datasets = {};
  result.forEach((datum) => {
    const colorRamdomFondo = randomColor({
      hue: "#1CA6AF",
      luminosity: "bright",
      format: "hex",
    });
    const colorRamdomBorde = randomColor({
      hue: "#066792",
      luminosity: "light",
      format: "hex",
    });
    const datasetKey = `${datum.combustible}_${datum.id_combustible}`;
    datasets[datasetKey] = datasets[datasetKey] || {
      label: datum.combustible, // Usar el nombre del combustible traducido
      data: yearMonths.map(() => 0),
      key: datasetKey,
      fill: true,
      backgroundColor: colorRamdomFondo,
      borderColor: colorRamdomBorde,
      tension: 0.2,
    };
    const monthIndex = moment.utc(datum.fecha).month();
    datasets[datasetKey].data[monthIndex] += datum.kgcodose_emitido;
  });

  const chartData = {
    labels: yearMonths.map(({ label }) => label),
    datasets: Object.values(datasets),
  };

  const chartOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Gráfico de emisiones de CO2",
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxTicksLimit: 12,
          suggestedMin: 0,
          suggestedMax: 1000,
        },
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 50,
        },
      },
    },
    height: 500,
  };

  return (
    <Line style={{ height: "12rem" }} data={chartData} options={chartOptions} />
  );
};
