import axios from "axios";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_PAGO_FINAL =
	process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/pago_interno";
export const PagoInternoCheck = ({sesion}) => {
	const navigate = useNavigate();
	const [t] = useTranslation("global");
	// const [estadoFinal, setEstadoFinal] = useState("");
	const { estado, estado_pago } = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const payment_id = queryParams.get("payment_id") || "";
	const status = queryParams.get("status") || "";
	const merchant_order_id = queryParams.get("merchant_order_id") || "";

	const [solicitudEnviada, setSolicitudEnviada] = useState(false);

	useEffect(() => {
		// Verificar si ya se ha enviado la solicitud
		if (
			!solicitudEnviada &&
			estado &&
			estado_pago
		) {
			axios
				.post(URI_PAGO_FINAL+"?id="+sesion.empresaActiva, {
					payment_id: payment_id,
					status: status,
					merchant_order_id: merchant_order_id,
					datoEstado: estado,
					estado_pago: estado_pago,
				})
				.then((registro) => {
					console.log(registro.data.code);

					if (registro.data.code === 406) {
                        MySwal.fire({
							text: t("mensajes.mensaje71"),
                            imageUrl: "img/lechuza-triste.png",
                            imageHeight: 50,
                            confirmButtonText: "Ok",
                          });
						navigate("/empresa/"+sesion.empresaActiva+"/historial");
					} else if (registro.data.code === 409) {
                        MySwal.fire({
                            text: t("mensajes.mensaje72"),
                            imageUrl: "img/lechuza-triste.png",
                            imageHeight: 50,
                            confirmButtonText: "Ok",
                          });
						navigate("/empresa/"+sesion.empresaActiva+"/historial");
					} else if (registro.data.code === 200) {
                        MySwal.fire({
							text: t("mensajes.mensaje73"),
                            imageUrl: "img/registro-exitoso.svg",
                            imageHeight: 65,
                            confirmButtonText: "Ok",
                          });
						navigate("/empresa/"+sesion.empresaActiva+"/subscripciones");
					}
					setSolicitudEnviada(true);
				})
				.catch((error) => {
					console.error(error);
					// Manejar el error, según sea necesario.
				});
		}
	}, [estado, estado_pago, solicitudEnviada]);
	return (
		<>
			<div className="main regsim">
				<div className="contenedor-error mt-5 text-center position-relative">
					<div className="d-flex flex-column align-items-center justify-content-center">
						{/* Pseudo-elemento para el círculo */}
						<div className="circulo">
							<img
								src="./img/registro-exitoso.svg"
								alt="Descripción"
								className="imagen-circulo"
							/>
						</div>
						<h1 className="titulo-error">
						{t("registro.texto1")}
						</h1>
					</div>
				</div>
			</div>
		</>
	);
};
