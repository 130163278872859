import React from "react";

export const BtnPrincipal = ({ name, onClick }) => {

  return (
    <div className="d-flex  justify-content-end w-100">
      <button
        className="btnprincipal "
        type="submit"
        onClick={onClick}
        style={{ padding: "0.1em" }}
      >
        <span className="button-name">{name}</span>
      </button>
    </div>
  );
};
