import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";

export const ChartEmisionesCertificado = ({ data }) => {
  const { t, i18n } = useTranslation("global");

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      console.log("Datos recibidos:", data);

      const conCertificado = data.filter(
        (item) =>
          item.tipo_certificado &&
          item.tipo_certificado.trim() !== "" &&
          item.tipo_certificado !== null
      );
      const sinCertificado = data.filter(
        (item) =>
          !item.tipo_certificado ||
          item.tipo_certificado.trim() === "" ||
          item.tipo_certificado === null
      );

      console.log("Datos con certificado:", conCertificado);
      console.log("Datos sin certificado:", sinCertificado);

      const sumaConCertificado = conCertificado.reduce(
        (sum, item) => sum + (item.cantidad || 0),
        0
      );
      const sumaSinCertificado = sinCertificado.reduce(
        (sum, item) => sum + (item.cantidad || 0),
        0
      );

      console.log(
        "Suma de emisiones compensadas con certificado:",
        sumaConCertificado
      );
      console.log(
        "Suma de emisiones compensadas sin certificado:",
        sumaSinCertificado
      );

      const totalEmisiones = sumaConCertificado + sumaSinCertificado;
      const porcentajeConCertificado =
        totalEmisiones > 0 ? (sumaConCertificado / totalEmisiones) * 100 : 0;
      const porcentajeSinCertificado =
        totalEmisiones > 0 ? (sumaSinCertificado / totalEmisiones) * 100 : 0;

      setChartData({
        labels: [t("graficos.texto3"), t("graficos.texto4")],
        datasets: [
          {
            data: [porcentajeConCertificado, porcentajeSinCertificado],
            backgroundColor: ['#508991', '#D79824'],
            borderColor: ["#4CAF50", "#FFC107"],
            borderWidth: 1,
          },
        ],
      });
    } else {
      console.error("Data is not valid:", data);
    }
  }, [data, i18n.language]); // Agrega i18n.language como dependencia

  // Opciones del gráfico
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: t("graficos.titulo2"),
        font: {
          size: 16,
        },
      },
      legend: {
        display: true,
        labels: {
          font: {
            size: 12,
          },
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
  };

  return (
    <div
      style={{
        height: "auto",
        width: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Doughnut
        options={chartOptions}
        data={chartData}
        style={{
          height: "300px",
          width: "300px",
        }}
      />
    </div>
  );
};
