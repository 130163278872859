import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { Chart, registerables } from "chart.js";
import { useTranslation } from "react-i18next";

Chart.register(...registerables);

export const ChartEmisiones = ({ data, year }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  console.log(data)
  const monthNames = {
    es: [
      `Ene`, `Feb`, `Mar`, `Abr`, `May`, `Jun`, `Jul`, `Ago`, `Sep`, `Oct`, `Nov`, `Dic`
    ],
    en: [
      `Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`
    ]
  };

  // Usa el idioma actual para seleccionar los nombres de los meses
  const yearMonths = monthNames[currentLanguage].map((month, index) => ({
    label: `${month}-${year}`,
    month: (index + 1).toString().padStart(2, '0')
  }));

  const result = Object.values(
    data.reduce((acc, { fecha, kgcodose_emitido }) => {
      const month = fecha.slice(5, 7);
      const label = moment(fecha).format("MMM-YYYY");
      acc[month] = acc[month] || {
        label,
        fecha: moment(fecha, "YYYY-MM-DD").toDate(),
        kgcodose_emitido: 0,
      };
      acc[month].kgcodose_emitido += kgcodose_emitido || 0;
      return acc;
    }, {})
  );

  yearMonths.forEach(({ month, label }) => {
    const foundMonth = result.find(
      (item) => moment(item.fecha).format("MM") === month
    );
    if (!foundMonth) {
      result.push({
        label,
        fecha: new Date(`${year}-${month}-01T03:00:00.000Z`),
        kgcodose_emitido: 0,
      });
    }
  });

  result.sort((a, b) => a.fecha - b.fecha);

  const chartData = {
    labels: yearMonths.map(({ label }) => label),
    datasets: [
      {
        label: `tCO2eq`,
        data: result.map((datum) => {
          const monthIndex = moment(datum.fecha).month();
          return { x: monthIndex, y: datum.kgcodose_emitido };
        }),
        fill: true,
        borderColor: "#65A4D8",
        tension: 0.2,
        backgroundColor: "#35A6AF",
      },
    ],
  };


  const chartOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Gráfico de emisiones de CO2",
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxTicksLimit: 12,
          suggestedMin: 0,
          suggestedMax: 1000,
        },
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 50,
        },
      },
    },
    height: 500,
  };

  return (
    <Line style={{ height: "13rem", padding:"0.5em" }} data={chartData} options={chartOptions} />
  );
};
