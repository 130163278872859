import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useTranslation } from "react-i18next";

Chart.register(...registerables);

export const ChartBarras = ({
  ArrayLabels,
  ArrayData,
  ArrayBackground,
  ArrayBorder,
}) => {
	const [t] = useTranslation("global");

  // Preparamos los datos para la gráfica
  const chartData = {
    labels: ArrayLabels,
    datasets: [
      {
        label: t("reportes.seccionEstadisticas.emisiones"),
        data: ArrayData,
        fill: true,
        tension: 0.2,
        backgroundColor: ArrayBackground,
        borderColor: ArrayBorder,
      },
    ],
  };

  // Configuramos las opciones de la gráfica
  const chartOptions = {
    indexAxis: "y",
    maintainAspectRatio: false,
    title: {
      display: true,
      text:  t("reportes.seccionEstadisticas.tipoEmisiones"),
    },
    scales: {
      x: {
        min: 0,
        ticks: {
          // forces step size to be 50 units
          stepSize: 50,
        },
      },
    },
  };

  return (
    <Bar style={{ height: "16rem" }} data={chartData} options={chartOptions} />
  );
};
